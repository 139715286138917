import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class orderItemsApi {
    static getOrderItems(token) {
        return fetch(`${host}/orderItems/listall`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createOrderItem(orderItemsData,token) {
        return fetch(`${host}/orderItems/add`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(orderItemsData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updateOrderItem(orderItemsID, orderItemsData,token) {
        return fetch(`${host}/orderItems/update/${orderItemsID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(orderItemsData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deleteOrderItem(deleteId,token) {
        return fetch(`${host}/orderItems/delete/${deleteId}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getOrderItembyID(orderItemsID, token) {
        return fetch(`${host}/orderItems/getOrderItems/${orderItemsID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default orderItemsApi;