import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.supplier, action) {
    switch (action.type) {
        case types.GET_SUPPLIERS_INITIATED:
            return {
                ...state,
                getSuppliersPending: true,
                    error: null,
                    getSuppliersSuccess: false,
                    createSupplierSuccess: false,
                    deleteSupplierSuccess: false,
                    updateSupplierSuccess: false
            };
        case types.GET_SUPPLIERS_FAILED:
            return {
                ...state,
                getSuppliersPending: false,
                    error: action.error,
                    getSuppliersSuccess: false,
                    createSupplierSuccess: false
            };
        case types.GET_SUPPLIERS_SUCCESS:
            return {
                ...state,
                getSuppliersPending: false,
                    getSuppliersSuccess: true,
                    error: null,
                    data: action.suppliers,
                    createSupplierSuccess: false
            };
        case types.CREATE_SUPPLIERS_INITIATED:
            return {
                ...state,
                createSupplierPending: false,
                    error: null
            };
        case types.CREATE_SUPPLIERS_FAILED:
            return {
                ...state,
                createSupplierPending: false,
                    error: action.error
            };
        case types.CREATE_SUPPLIERS_SUCCESS:
            return {
                ...state,
                createSupplierPending: false,
                    createSupplierSuccess: true,
                    error: null
            };
        case types.UPDATE_SUPPLIERS_INITIATED:
            return {
                ...state,
                updateSupplierPending: true,
                    error: null
            };
        case types.UPDATE_SUPPLIERS_FAILED:
            return {
                ...state,
                updateSupplierPending: false,
                    error: action.error
            };
        case types.UPDATE_SUPPLIERS_SUCCESS:
            return {
                ...state,
                updateSupplierPending: false,
                    updateSupplierSuccess: true,
                    createSupplierSuccess: false,
                    getSuppliersPending: false,
                    error: null
            };
        case types.DELETE_SUPPLIERS_INITIATED:
            return {
                ...state,
                deleteSupplierPending: true,
                    error: null
            };
        case types.DELETE_SUPPLIERS_FAILED:
            return {
                ...state,
                deleteSupplierPending: false,
                    error: action.error
            };
        case types.DELETE_SUPPLIERS_SUCCESS:
            return {
                ...state,
                deleteSupplierPending: false,
                    deleteSupplierSuccess: true,
                    createSupplierSuccess: false,
                    error: null
            };
        case types.GET_SUPPLIERS_BY_ID_INITIATED:
            return {
                ...state,
                getSupplierbyIdPending: true,
                    getSupplierbyIDSuccess: false,
                    getSupplierbyIdError: null,
                    getSuppliersSuccess: false,
                    createSupplierSuccess: false,
                    deleteSupplierSuccess: false,
                    updateSupplierSuccess: false
            };
        case types.GET_SUPPLIERS_BY_ID_FAILED:
            return {
                ...state,
                getSuppliersPending: false,
                    getSupplierbyIdPending: false,
                    getSupplierbyIDSuccess: false,
                    getSupplierbyIdError: action.error,
                    getSuppliersSuccess: false,
                    createSupplierSuccess: false
            };
        case types.GET_SUPPLIERS_BY_ID_SUCCESS:
            return {
                ...state,
                getSuppliersPending: false,
                    getSuppliersSuccess: false,
                    getSupplierbyIdPending: true,
                    getSupplierbyIDSuccess: action.supplier,
                    getSupplierbyIdError: null,
                    createSupplierSuccess: false
            };
        default:
            return state;
    }
}
