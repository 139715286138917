import React, {Component} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import auth from '../modules/auth';
import Login from './login';
import Supplier from './supplier/supplier';
import Agent from './agent/agent';
import Party from './party/party';
import purchaseOrder from './purchaseOrder/purchaseOrder';
import manufacturingOrder from './manufacturingOrder/manufacturingOrder';
import processingOrder from './processingOrder/processingOrder';
import orderItem from './orderItem/orderItem';
import receipt from './receipt/receipt';
import checkingItem from './checkingItem/checkingItem';

class MRCFabrics extends Component{
    render() {
      const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
          auth.isLoggedIn() === true
            ? <Component {...props} />
            : <Redirect to='/login' />
        )} />
      )
      const PublicRoute =  ({ component: Component, ...rest }) => (
        <Route {...rest} render={(props) => (
          auth.isLoggedIn() === true
            ? 
              <Redirect to='/supplier' />
            :
            <Component {...props} />
        )} />
      )
      return(
        <div className="height-100">
          <BrowserRouter>
              <Switch>
                  <Route exact path="/">
                      <Redirect from='/' to='/login'/>
                  </Route>
                  <PublicRoute exact path="/login" component={Login} />
                  <PrivateRoute exact path="/supplier" component={Supplier} />
                  <PrivateRoute exact path="/agent" component={Agent} />
                  <PrivateRoute exact path="/party" component={Party} />
                  <PrivateRoute exact path="/purchaseOrder" component={purchaseOrder} />
                  <PrivateRoute exact path="/manufacturingOrder" component={manufacturingOrder} />
                  <PrivateRoute exact path="/processingOrder" component={processingOrder} />
                  <PrivateRoute exact path="/orderItem" component={orderItem} />
                  <PrivateRoute exact path="/receipt" component={receipt} />
                  <PrivateRoute exact path="/checkingItemOrder" component={checkingItem} />
              </Switch>
          </BrowserRouter>
    </div>
      );
    }
  }
  export default MRCFabrics;