import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class checkingItemsApi {
    static getCheckingItems(token) {
        return fetch(`${host}/checking/listall`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createCheckingItem(checkingItemsData,token) {
        return fetch(`${host}/checking/add`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'POST',
            body: JSON.stringify(checkingItemsData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updateCheckingItem(checkingItemsID, checkingItemsData,token) {
        return fetch(`${host}/checking/update/${checkingItemsID}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'PUT',
            body: JSON.stringify(checkingItemsData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deleteCheckingItem(deleteId,token) {
        return fetch(`${host}/checking/delete/${deleteId}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getCheckingItembyID(checkingItemsID, token) {
        return fetch(`${host}/checking/getCheckingItems/${checkingItemsID}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default checkingItemsApi;