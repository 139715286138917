import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class processingOrderApi {
    static getProcessingOrders(token) {
        return fetch(`${host}/processing/listall`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createProcessingOrder(processingOrderData,token) {
        return fetch(`${host}/processing/add`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(processingOrderData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updateProcessingOrder(processingOrderID, processingOrderData,token) {
        return fetch(`${host}/processing/update/${processingOrderID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(processingOrderData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deleteProcessingOrder(deleteId,token) {
        return fetch(`${host}/processing/delete/${deleteId}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getProcessingOrderbyID(processingOrderID, token) {
        return fetch(`${host}/processing/getProcessingOrder/${processingOrderID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default processingOrderApi;