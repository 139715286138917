import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.processingOrder, action) {
    switch (action.type) {
        case types.GET_PROCESSING_ORDERS_INITIATED:
            return {
                ...state,
                getProcessingOrdersPending: true,
                    error: null,
                    getProcessingOrdersSuccess: false,
                    createProcessingOrderSuccess: false,
                    deleteProcessingOrderSuccess: false,
                    updateProcessingOrderSuccess: false
            };
        case types.GET_PROCESSING_ORDERS_FAILED:
            return {
                ...state,
                getProcessingOrdersPending: false,
                    error: action.error,
                    getProcessingOrdersSuccess: false,
                    createProcessingOrderSuccess: false
            };
        case types.GET_PROCESSING_ORDERS_SUCCESS:
            return {
                ...state,
                getProcessingOrdersPending: false,
                    getProcessingOrdersSuccess: true,
                    error: null,
                    data: action.processingOrders,
                    createProcessingOrderSuccess: false
            };
        case types.CREATE_PROCESSING_ORDERS_INITIATED:
            return {
                ...state,
                createProcessingOrderPending: false,
                    error: null
            };
        case types.CREATE_PROCESSING_ORDERS_FAILED:
            return {
                ...state,
                createProcessingOrderPending: false,
                    error: action.error
            };
        case types.CREATE_PROCESSING_ORDERS_SUCCESS:
            return {
                ...state,
                createProcessingOrderPending: false,
                    createProcessingOrderSuccess: true,
                    error: null
            };
        case types.UPDATE_PROCESSING_ORDERS_INITIATED:
            return {
                ...state,
                updateProcessingOrderPending: true,
                    error: null
            };
        case types.UPDATE_PROCESSING_ORDERS_FAILED:
            return {
                ...state,
                updateProcessingOrderPending: false,
                    error: action.error
            };
        case types.UPDATE_PROCESSING_ORDERS_SUCCESS:
            return {
                ...state,
                updateProcessingOrderPending: false,
                    updateProcessingOrderSuccess: true,
                    createProcessingOrderSuccess: false,
                    getProcessingOrdersPending: false,
                    error: null
            };
        case types.DELETE_PROCESSING_ORDERS_INITIATED:
            return {
                ...state,
                deleteProcessingOrderPending: true,
                    error: null
            };
        case types.DELETE_PROCESSING_ORDERS_FAILED:
            return {
                ...state,
                deleteProcessingOrderPending: false,
                    error: action.error
            };
        case types.DELETE_PROCESSING_ORDERS_SUCCESS:
            return {
                ...state,
                deleteProcessingOrderPending: false,
                    deleteProcessingOrderSuccess: true,
                    createProcessingOrderSuccess: false,
                    error: null
            };
        case types.GET_PROCESSING_ORDERS_BY_ID_INITIATED:
            return {
                ...state,
                getProcessingOrderbyIdPending: true,
                    getProcessingOrderbyIDSuccess: false,
                    getProcessingOrderbyIdError: null,
                    getProcessingOrdersSuccess: false,
                    createProcessingOrderSuccess: false,
                    deleteProcessingOrderSuccess: false,
                    updateProcessingOrderSuccess: false
            };
        case types.GET_PROCESSING_ORDERS_BY_ID_FAILED:
            return {
                ...state,
                getProcessingOrdersPending: false,
                    getProcessingOrderbyIdPending: false,
                    getProcessingOrderbyIDSuccess: false,
                    getProcessingOrderbyIdError: action.error,
                    getProcessingOrdersSuccess: false,
                    createProcessingOrderSuccess: false
            };
        case types.GET_PROCESSING_ORDERS_BY_ID_SUCCESS:
            return {
                ...state,
                getProcessingOrdersPending: false,
                    getProcessingOrdersSuccess: false,
                    getProcessingOrderbyIdPending: true,
                    getProcessingOrderbyIDSuccess: action.processingOrder,
                    getProcessingOrderbyIdError: null,
                    createProcessingOrderSuccess: false
            };
        default:
            return state;
    }
}
