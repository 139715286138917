import supplier from './supplierReducer';
import agent from './agentReducer';
import party from './partyReducer';
import user from './loginReducer';
import purchaseOrder from './purchaseOrder';
import manufacturingOrder from './manufacturingOrder';
import processingOrder from './processingOrder';
import orderItem from './orderItem';
import receipt from './receiptReducer';
import checking from './checkingItem';
export default {
    supplier,
    user,
    agent,
    party,
    purchaseOrder,
    manufacturingOrder,
    processingOrder,
    orderItem,
    receipt,
    checking
}