import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import reduxStore from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import './assets/styles/custom.scss';
import MRCFabrics from './containers';
import * as serviceWorker from './serviceWorker';

Sentry.init({
    dsn: "https://ef4000a8be4b4bdbaa75fe1057cd5172@o103310.ingest.sentry.io/5468067",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });

ReactDOM.render(
    <Provider store={reduxStore.store}>
    <ConnectedRouter history={reduxStore.history}>
            <MRCFabrics />
    </ConnectedRouter>
</Provider>, document.getElementById('root'));

serviceWorker.register();
