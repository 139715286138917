import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userLoginReducer(state = initialState.user, action) {
    switch (action.type) {
        case types.LOGIN_REQUESTED:
            return {
                loginPending: true, 
                error: null
            };
        case types.LOGIN_INVALID_CREDS:
            return {
                loginPending: false, 
                error: action.user.message
            };
        case types.LOGIN_FAILED:
            return {
                loginPending:false, 
                error: action.error
            };
        case types.LOGIN_SUCCESS:
            return {
                loginPending: false, 
                error: null, 
                loginSuccess: true,
                response: action.user
            };
        case types.REQUEST_USERPROFILE_INITIATED:
            return {
                ...state,
                userRequest: true
            };
        case types.REQUEST_USERPROFILE_SUCCESS:
            return {
                ...state,
                userRequest: false,
                profile: action.user
            };
        case types.REQUEST_USERPROFILE_FAILED:
            return {
                ...state,
                userRequest: false,
                profileGetError: action.error
            };
        case types.UPDATE_USERPROFILE_INITIATED:
            return {
                ...state,
                updateRequest: true
            };
        case types.UPDATE_USERPROFILE_SUCCESS:
            return {
                ...state,
                updateRequest: false,
                updateCompleted: true
            };
        case types.UPDATE_USERPROFILE_FAILED:
            return {
                ...state,
                updateRequest: false,
                updateCompleted: false,
                profileUpdateError: action.error
            };
        case types.LOGOUT_INITIATED: 
            return {
                ...state,
                logoutRequest: true
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutRequest: false,
                loggedOut: true
            };
        case types.LOGOUT_FAILED:
            return {
                ...state,
                logoutRequest: false,
                loggedOut: false,
                logOutError: action.error
            };
        case types.RESET_PASSWORD_INITIATED: 
            return {
                resetRequested: true,
                resetSuccess: false,
                resetError: null
            };
        case types.RESET_PASSWORD_SUCCESS: 
            return {
                resetRequested: false,
                resetSuccess: true,
                resetData: action.user,
                resetError: null
            };
        case types.RESET_PASSWORD_FAILED: 
            return {
                resetRequested: false,
                resetSuccess: false,
                resetError: true,
                resetErrorData: action.error
            };
        case types.SET_PASSWORD_INITIATED: 
            return {
                setRequested: true,
                setSuccess: false,
                setError: null
            };
        case types.SET_PASSWORD_SUCCESS: 
            return {
                setRequested: false,
                setSuccess: true,
                setData: action.user,
                setError: null
            };
        case types.SET_PASSWORD_FAILED: 
            return {
                setRequested: false,
                setSuccess: false,
                setError: true,
                setErrorData: action.error
            };
        default:
            return state;
    }
}