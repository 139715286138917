import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Raven from 'raven-js';
import moment from 'moment';
import { alphanumericwithspace } from '../../modules/validator';
import { DatePicker } from 'antd';
import { Radio } from 'antd';
import reduxStore from '../../store';
import SideBar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import { Row, Col, Table, Divider, Icon, Drawer,Form, Input, Modal, message, Breadcrumb, Button, Tooltip, notification, Select } from 'antd';
import * as receiptActions from '../../actions/data/receipt';
import * as processingOrderActions from '../../actions/data/processingOrder';
import * as manufacturingOrderActions from '../../actions/data/manufacturingOrder';
import * as partyActions from '../../actions/data/party';
import './receipt.scss';

const history = reduxStore.history;
const confirm = Modal.confirm;
const FormItem = Form.Item;
const { TextArea } = Input;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};
class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfPossibleItems : 5,
      visible: false,
      receiptsData: [],
      processingOrdersData: [],
      manufacturingOrdersData: [],
      partysData: [],
      createReceiptVisible: false,
      currentRow: {},
      editedreciptId: '',
      receiptdEdited: false,
      dropdownFilter: '',
      editClicked: false,
      updated: false,
    };
    this.onChange = this.onChange.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.createReceiptShowDrawer = this.createReceiptShowDrawer.bind(this);
    this.onCreateReceiptClose = this.onCreateReceiptClose.bind(this);
    this.handleCreateReceipt = this.handleCreateReceipt.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.handleUpdateReceipt = this.handleUpdateReceipt.bind(this);
    this.handleDropDownSearch = this.handleDropDownSearch.bind(this);
    this.showViewDrawer = this.showViewDrawer.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
  }
  componentDidMount() {
    document.title = "MRC Fabrics | Receipts";
    const newMaxHeight = window.innerHeight - 330;
    const possibleRows = Math.ceil(newMaxHeight/54);
    if(possibleRows > this.state.numberOfPossibleItems) {
      this.setState({numberOfPossibleItems: possibleRows});
    }
    // if(this.props.user.loginSuccess) {
    //   message.success('Logged in successfully!', 3);
    // }
    let userToken = null;
    this.props.actions.getReceipts(userToken);
    this.props.actions.getProcessingOrders(userToken);
    this.props.actions.getManufacturingOrders(userToken);
    this.props.actions.getParty(userToken);
  }
  static getDerivedStateFromProps(nextProps, currentProps) {
    console.log( nextProps)
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    if(nextProps.processingOrders && nextProps.processingOrders.getProcessingOrdersSuccess) {
      nextProps.processingOrders.getProcessingOrdersSuccess = false;
      return {
        processingOrdersData: nextProps.processingOrders.data.listProcessingDTO.map(data => {data.key = data.processingId; return data})
      }
    }
    if(nextProps.manufacturingOrders && nextProps.manufacturingOrders.getManufacturingOrdersSuccess) {
      nextProps.manufacturingOrders.getManufacturingOrdersSuccess = false;
      return {
        manufacturingOrdersData: nextProps.manufacturingOrders.data.listManufacturingDTO.map(data => {data.key = data.manufacturingId; return data})
      }
    }
    if(nextProps.partys && nextProps.partys.getPartySuccess) {
      nextProps.partys.getPartySuccess = false;
      return {
        partysData: nextProps.partys.data.listPartyDTO.map(data => {data.key = data.partyId; return data})
      }
    }
    if(nextProps.receipts && nextProps.receipts.getReceiptsSuccess) {
      nextProps.receipts.getReceiptsSuccess = false;
      return {
        updated: true, 
        receiptsData: nextProps.receipts.data.listReciptDTO.map(data => {data.key = data.reciptId; return data})
      }
    }
    if(nextProps.receipts && nextProps.receipts.createReceiptSuccess && !nextProps.receipts.createReceiptPending) {
      nextProps.receipts.createReceiptSuccess = false;
      nextProps.actions.getReceipts(userToken);
      nextProps.form.resetFields();
      message.success('Receipt created successfully!', 2);
      return {
        updated: false,
        currentRow: {},
        createReceiptVisible: false
      };
    }
     if(nextProps.receipts && nextProps.receipts.updateReceiptSuccess && !nextProps.receipts.updateReceiptPending) {
      nextProps.receipts.updateReceiptSuccess  = false;
      nextProps.actions.getReceipts(userToken);
      nextProps.form.resetFields();
      message.success('Receipt edited successfully!', 2);
      return {
        visible: false,
        editClicked: false,
        currentRow: {},
        receiptdEdited: true,
        updated: false
      };
  }
  if(nextProps.receipts && nextProps.receipts.deleteReceiptSuccess && !nextProps.receipts.deleteReceiptPending) {
    nextProps.receipts.deleteReceiptSuccess = false;  
    nextProps.actions.getReceipts(userToken);
    message.success('Receipt deleted successfully!', 2);
    return {
      currentRow: '',
      updated: false
    };
    }
    if(nextProps.receipts && nextProps.receipts.error) {
      if(nextProps.receipts.error.message)
        message.error(nextProps.receipts.error.message,2);
        nextProps.receipts.error = {};
      //   Raven.setExtraContext({
      //       props: this.props
      //   });
      // Raven.captureException(nextProps.receipts.error.message, { extra: nextProps });
      if(nextProps.receipts.error.message === "Failed to fetch") {
        openNotificationWithIcon("error", "It looks like you've lost your internet connection", "But you probably already knew that! This must be frustrating, Don't leave us, we track these things and resume automatically! Until then just relax!! ");           
      }
    }
  return null;
    
   
    
}
  handleDropDownSearch(dropdownFilter) {
    if(!dropdownFilter)
      dropdownFilter='';
    this.setState({dropdownFilter});
  }
  onTableRowClick(currentRow) {
    this.setState({currentRow,receiptdEdited: false, editedreciptId: ''});
  }
  showConfirm() {
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    const deleteReceipt = () => {
      this.props.actions.deleteReceipt(this.state.currentRow.reciptId, userToken);
    } 
    const resetFields = () => {
      this.setState({currentRow: ''});
    }
    confirm({
      title: 'Are you sure delete this Receipt?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteReceipt();
      },
      onCancel() {
        resetFields();
        console.log('Cancel');
      },
    });
  }
  onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
  }  
  onViewClose (){
    this.setState({
      viewVisible: false,
      currentRow: ''
    });
  }
  showViewDrawer (){
    this.setState({
      viewVisible: true,
    });
  }
  createReceiptShowDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      createReceiptVisible: true,
    });
  };

  onCreateReceiptClose = () => {
    this.props.form.resetFields();
    this.setState({
      createReceiptVisible: false,
    });
  };
  handleCreateReceipt = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.reciptGeneratedDate = moment(values.reciptGeneratedDate).toISOString();
        values.processingId = parseInt(values.processingId,10);
        values.manufacturingId = parseInt(values.manufacturingId, 10);
        values.partyId = parseInt(values.partyId, 10);
        this.props.actions.createReceipt(values, userToken);
      }
    });
  }
  handleUpdateReceipt = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.reciptGeneratedDate = moment(values.reciptGeneratedDate).toISOString();
        values.processingId = parseInt(values.processingId,10);
        values.manufacturingId = parseInt(values.manufacturingId, 10);
        values.partyId = parseInt(values.partyId, 10);
        this.setState({editedreciptId: this.state.currentRow.reciptId});
        this.props.actions.updateReceipt(this.state.currentRow.reciptId, values, userToken);
      }
    });
  }
  showDrawer (){
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editClicked: true
    });
  }
  onClose (){
    this.setState({
      visible: false,
      editClicked: false,
      currentRow: {}
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: 'Receipt Id',
      dataIndex: 'reciptId',
      sorter: (a, b) => { return a.recipt.localeCompare(b.recipt)}
    }, {
      title: 'reciptGeneratedDate',
      dataIndex: 'reciptGeneratedDate',
      sorter: (a, b) => { return a.reciptGeneratedDate.localeCompare(b.reciptGeneratedDate)},
      render: (text, record) => {
        if(text && text.length > 50) {
          return text.substring(0,50)+'...';
        } else {
          return text;
        }
      }
    }, {
      title: 'JobDetails',
      dataIndex: 'jobDetails',
      sorter: (a, b) => { return a.jobDetails.localeCompare(b.jobDetails)}
    },
    {
      title: 'StockFrom',
      dataIndex: 'stockFrom',
      sorter: (a, b) => { return a.stockFrom.localeCompare(b.stockFrom)},
    },
    {
      title: 'Action',
      key: 'action',
      width: 240,
      render: (text, record) => (
        <span>
         <Tooltip title="View Receipt">
            <span onClick={this.showViewDrawer} className="cursor-pointer"><Icon type="eye" /> View</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Edit Receipt">
            <span onClick={this.showDrawer} className="cursor-pointer"><Icon type="edit" /> Edit</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Delete Receipt">
            <span  onClick={this.showConfirm} className="cursor-pointer"><Icon type="delete" /> Delete</span>
          </Tooltip>
          {record.idreceipt === this.state.editedreciptId && this.state.receiptdEdited ? <span className={"success"} style={{fontSize: 11,paddingLeft: 10,color: 'green'}}>updated!</span> : null}
        </span>
      )
    }];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return(
      <Row>
        <Col 
          xs={6} 
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className="height-100">
          <SideBar changePath={history.push} {...history}/>
        </Col>
        <Col
          xs={18} 
          sm={18}
          md={20}
          lg={20}
          xl={20} className="height-100-55 dashboard">
            <Row>
              <Header />
              <Row className="contents-main">
                    <div className="breadcrumb-div">
                    <Breadcrumb>
                      <Breadcrumb.Item>Home</Breadcrumb.Item>
                      <Breadcrumb.Item>Receipt</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="add-receipt-button">
                        <Button type="primary" className="login-form-button" style = {{
                          width: 150,
                          float: 'right',
                          marginTop: -30
                        }}onClick={this.createReceiptShowDrawer}>
                        <Icon type="plus" /> Add Receipt
                        </Button>
                    </div>
                    </div>
                    <div className="main-table-div">
                      <Table 
                        className="main-table" 
                        columns={columns} 
                        dataSource={this.state.receiptsData} 
                        pagination={{pageSize:this.state.numberOfPossibleItems}} 
                        loading={this.state.receiptsData.length === 0&& !this.state.updated}
                        locale={{ emptyText: 'No receipts added!' }}
                        onChange={this.onChange} onRow={(record, index) => ({
                          onClick: (event) => { this.onTableRowClick(record); } 
                      })}/>
                    </div>
                </Row>
                <Drawer
                  title="Add Receipt"
                  placement="right"
                  closable={false}
                  onClose={this.onCreateReceiptClose}
                  visible={this.state.createReceiptVisible}
                  width={450}
                >
                  <Form onSubmit={this.handleCreateReceipt} className="receipt-create" id="createReceipt">
                        <FormItem
                            {...formItemLayout}
                            label="Receipt Date"
                            >
                            {getFieldDecorator('reciptGeneratedDate', {
                                rules: [{ required: true, message: 'Please select recipt GeneratedDate!', whitespace: true, type: "object" }],
                            })(
                                <DatePicker />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="isOutward"
                            >
                            {getFieldDecorator('isOutward', {
                                rules: [{ required: true, message: 'Please select isOutward!', whitespace: true }]
                            })(
                              <RadioGroup name="radiogroup">
                                <Radio value={"Y"}>Yes</Radio>
                                <Radio value={"N"}>Normal</Radio>
                              </RadioGroup>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ProcessingId"
                            >
                            {getFieldDecorator('processingId', {
                                rules: [{ required: true, message: 'Please enter processingId!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.processingOrdersData.length)? 
                                  this.state.processingOrdersData.map(processing => 
                                  <Option value={processing.processingId.toString()} key={'processing'+processing.processingId}>{processing.processingId}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ManufacturingId"
                            >
                            {getFieldDecorator('manufacturingId', {
                                rules: [{ required: true, message: 'Please enter manufacturingId!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.manufacturingOrdersData.length)? 
                                  this.state.manufacturingOrdersData.map(manufacturing => 
                                  <Option value={manufacturing.manufacturingId.toString()} key={'manufacturing'+manufacturing.manufacturingId}>{manufacturing.manufacturingId}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="JobDetails"
                            >
                            {getFieldDecorator('jobDetails', {
                                rules: [{ required: true, message: 'Please enter receipt jobDetails!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Party"
                            >
                            {getFieldDecorator('partyId', {
                                rules: [{ required: true, message: 'Please select party!'}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.partysData.length)? 
                                  this.state.partysData.map(party => 
                                  <Option value={party.partyId.toString()} key={'party'+party.partyId}>{party.partyName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Stock From"
                            >
                            {getFieldDecorator('stockFrom', {
                                rules: [{ required: true, message: 'Please enter receipt stockFrom!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ItemsForProcessing"
                            >
                            {getFieldDecorator('itemsForProcessing', {
                                rules: [{ required: true, message: 'Please enter itemsForProcessing!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Create</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onCreateReceiptClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
                </Drawer>
            <Drawer
                title="Edit Receipt"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                width={450}
              >
              <Form onSubmit={this.handleUpdateReceipt} className="receipt-create" id="createReceipt">
                      <FormItem
                            {...formItemLayout}
                            label="Receipt Date"
                            >
                            {getFieldDecorator('reciptGeneratedDate', {
                                rules: [{ required: true, message: 'Please select recipt GeneratedDate!', whitespace: true, type: "object" }],
                                initialValue: this.state.editClicked ? moment(this.state.currentRow.reciptGeneratedDate) : null
                            })(
                                <DatePicker />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="isOutward"
                            >
                            {getFieldDecorator('isOutward', {
                                rules: [{ required: true, message: 'Please select isOutward!', whitespace: true }],
                                initialValue: this.state.editClicked ? this.state.currentRow.isOutward : null
                            })(
                              <RadioGroup name="radiogroup">
                                <Radio value={"Y"}>Yes</Radio>
                                <Radio value={"N"}>Normal</Radio>
                              </RadioGroup>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ProcessingId"
                            >
                            {getFieldDecorator('processingId', {
                                rules: [{ required: true, message: 'Please enter processingId!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.processingId : null
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.processingOrdersData.length)? 
                                  this.state.processingOrdersData.map(processing => 
                                  <Option value={processing.processingId.toString()} key={'processing'+processing.processingId}>{processing.processingId}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ManufacturingId"
                            >
                            {getFieldDecorator('manufacturingId', {
                                rules: [{ required: true, message: 'Please enter manufacturingId!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.manufacturingId : null
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.manufacturingOrdersData.length)? 
                                  this.state.manufacturingOrdersData.map(manufacturing => 
                                  <Option value={manufacturing.manufacturingId.toString()} key={'manufacturing'+manufacturing.manufacturingId}>{manufacturing.manufacturingId}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="JobDetails"
                            >
                            {getFieldDecorator('jobDetails', {
                                rules: [{ required: true, message: 'Please enter receipt jobDetails!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.jobDetails : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Party"
                            >
                            {getFieldDecorator('partyId', {
                                rules: [{ required: true, message: 'Please select party!'}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.partysData.length)? 
                                  this.state.partysData.map(party => 
                                  <Option value={party.partyId.toString()} key={'party'+party.partyId}>{party.partyName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Stock From"
                            >
                            {getFieldDecorator('stockFrom', {
                                rules: [{ required: true, message: 'Please enter stockFrom!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.stockFrom : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ItemsForProcessing"
                            >
                            {getFieldDecorator('itemsForProcessing', {
                                rules: [{ required: true, message: 'Please enter itemsForProcessing!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.itemsForProcessing : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                       <br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Update</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
              </Drawer>
              <Drawer
                title="View Receipt"
                placement="right"
                closable={false}
                onClose={this.onViewClose}
                visible={this.state.viewVisible}
                width={500}
              >
                    
                    <p>Receipt Date : {this.state.currentRow.reciptGeneratedDate ? <b>{this.state.currentRow.reciptGeneratedDate}</b> : 'Not Provided.'}</p>
                    <p>isOutward : {this.state.currentRow.isOutward ? <b>{this.state.currentRow.isOutward}</b> : 'Not Provided.'}</p>
                    <p>ProcessingId : {this.state.currentRow.processingId ? <b>{this.state.currentRow.processingId}</b> : 'Not Provided.'}</p>
                    <p>ManufacturingId : {this.state.currentRow.manufacturingId ? <b>{this.state.currentRow.manufacturingId}</b> : 'Not Provided.'}</p>
                    <p>JobDetails : {this.state.currentRow.jobDetails ? <b>{this.state.currentRow.jobDetails}</b> : 'Not Provided.'}</p>
                    <p>PartyId : {this.state.currentRow.partyId ? <b>{this.state.currentRow.partyId}</b> : 'Not Provided.'}</p>
                    <p>StockFrom : {this.state.currentRow.stockFrom ? <b>{this.state.currentRow.stockFrom}</b> : 'Not Provided.'}</p>
                    <p>ItemsForProcessing : {this.state.currentRow.itemsForProcessing ? <b>{this.state.currentRow.phone}</b> : 'Not Provided.'}</p>                  
              </Drawer>
            </Row>
        </Col>
      </Row>
    );
  }
}
const WrappedReceipt = Form.create()(Receipt);
function mapStateToProps(state) {
  return {
    user: state.user,
    receipts: state.receipt,
    processingOrders: state.processingOrder,
    manufacturingOrders: state.manufacturingOrder,
    partys: state.party
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(Object.assign({}, receiptActions, processingOrderActions, manufacturingOrderActions, partyActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedReceipt)