import supplierApi from '../../api/home/supplier'
import * as types from '../actionTypes'

export function getSuppliers(token) {
    return function(dispatch) {
        dispatch(getSupplierPending());
        return supplierApi.getSuppliers(token).then(suppliers => {
            if(suppliers.statusDTO.status === "SUCCESS") {
                dispatch(getSupplierSuccess(suppliers));
            } else {
                dispatch(getSupplierFailed(suppliers));
            }
        }).catch(error => {
           dispatch(getSupplierFailed(error));
        });
    };
}
export function getSupplierbyId(token) {
    return function(dispatch) {
        dispatch(getSupplierbyIdPending());
        return supplierApi.getSupplierbyID(token).then(suppliers => {
            if(suppliers.statusDTO.status === "SUCCESS") {
                dispatch(getSupplierbyIdSuccess(suppliers));
            } else {
                dispatch(getSupplierbyIdFailed(suppliers));
            }
        }).catch(error => {
           dispatch(getSupplierbyIdFailed(error));
        });
    };
}
export function createSupplier(supplierId, supplierData , token) {
    return function(dispatch) {
        dispatch(createSupplierPending());
        return supplierApi.createSupplier(supplierId, supplierData , token).then(suppliers => {
            if(suppliers.statusDTO.status === "SUCCESS") {
                dispatch(createSupplierSuccess(suppliers));
            } else {
                dispatch(createSupplierFailed(suppliers));
            }
        }).catch(error => {
            dispatch(createSupplierFailed(error));
        });
    };
}
export function updateSupplier(supplierData , token) {
    return function(dispatch) {
        dispatch(updateSupplierPending());
        return supplierApi.updateSupplier(supplierData , token).then(suppliers => {
            if(suppliers.statusDTO.status === "SUCCESS") {
                dispatch(updateSupplierSuccess(suppliers));
            } else {
                dispatch(updateSupplierFailed(suppliers));
            }
        }).catch(error => {
            dispatch(updateSupplierFailed(error));
        });
    };
}
export function deleteSupplier(supplierData , token) {
    return function(dispatch) {
        dispatch(deleteSupplierPending());
        return supplierApi.deleteSupplier(supplierData , token).then(suppliers => {
            if(suppliers.statusDTO.status === "SUCCESS") {
                dispatch(deleteSupplierSuccess(suppliers));
            } else {
                dispatch(deleteSupplierFailed(suppliers));
            }
        }).catch(error => {
            dispatch(deleteSupplierFailed(error));
        });
    };
}


export function getSupplierPending() {
    return {type: types.GET_SUPPLIERS_INITIATED};
}
export function getSupplierSuccess(suppliers) {
    return {type: types.GET_SUPPLIERS_SUCCESS, suppliers};
}
export function getSupplierFailed(error) {
    return {type: types.GET_SUPPLIERS_FAILED, error};
}
export function createSupplierPending() {
    return {type: types.CREATE_SUPPLIERS_INITIATED};
}
export function createSupplierSuccess(suppliers) {
    return {type: types.CREATE_SUPPLIERS_SUCCESS, suppliers};
}
export function createSupplierFailed(error) {
    return {type: types.CREATE_SUPPLIERS_FAILED, error};
}
export function updateSupplierPending() {
    return {type: types.UPDATE_SUPPLIERS_INITIATED};
}
export function updateSupplierSuccess(suppliers) {
    return {type: types.UPDATE_SUPPLIERS_SUCCESS, suppliers};
}
export function updateSupplierFailed(error) {
    return {type: types.UPDATE_SUPPLIERS_FAILED,error};
}
export function deleteSupplierPending() {
    return {type: types.DELETE_SUPPLIERS_INITIATED};
}
export function deleteSupplierSuccess(suppliers) {
    return {type: types.DELETE_SUPPLIERS_SUCCESS, suppliers};
}
export function deleteSupplierFailed(error) {
    return {type: types.DELETE_SUPPLIERS_FAILED, error};
}
export function getSupplierbyIdPending() {
    return {type: types.GET_SUPPLIERS_BY_ID_INITIATED};
}
export function getSupplierbyIdSuccess(supplier) {
    return {type: types.GET_SUPPLIERS_BY_ID_SUCCESS, supplier};
}
export function getSupplierbyIdFailed(error) {
    return {type: types.GET_SUPPLIERS_BY_ID_FAILED, error};
}
