import agentApi from '../../api/home/agent'
import * as types from '../actionTypes'

export function getAgents(token) {
    return function(dispatch) {
        dispatch(getAgentPending());
        return agentApi.getAgents(token).then(agents => {
            if(agents.statusDTO.status === "SUCCESS") {
                dispatch(getAgentSuccess(agents));
            } else {
                dispatch(getAgentFailed(agents));
            }
        }).catch(error => {
           dispatch(getAgentFailed(error));
        });
    };
}
export function getAgentbyId(token) {
    return function(dispatch) {
        dispatch(getAgentbyIdPending());
        return agentApi.getAgentbyID(token).then(agents => {
            if(agents.statusDTO.status === "SUCCESS") {
                dispatch(getAgentbyIdSuccess(agents));
            } else {
                dispatch(getAgentbyIdFailed(agents));
            }
        }).catch(error => {
           dispatch(getAgentbyIdFailed(error));
        });
    };
}
export function createAgent(agentId, agentData , token) {
    return function(dispatch) {
        dispatch(createAgentPending());
        return agentApi.createAgent(agentId, agentData , token).then(agents => {
            if(agents.statusDTO.status === "SUCCESS") {
                dispatch(createAgentSuccess(agents));
            } else {
                dispatch(createAgentFailed(agents));
            }
        }).catch(error => {
            dispatch(createAgentFailed(error));
        });
    };
}
export function updateAgent(agentData , token) {
    return function(dispatch) {
        dispatch(updateAgentPending());
        return agentApi.updateAgent(agentData , token).then(agents => {
            if(agents.statusDTO.status === "SUCCESS") {
                dispatch(updateAgentSuccess(agents));
            } else {
                dispatch(updateAgentFailed(agents));
            }
        }).catch(error => {
            dispatch(updateAgentFailed(error));
        });
    };
}
export function deleteAgent(agentData , token) {
    return function(dispatch) {
        dispatch(deleteAgentPending());
        return agentApi.deleteAgent(agentData , token).then(agents => {
            if(agents.statusDTO.status === "SUCCESS") {
                dispatch(deleteAgentSuccess(agents));
            } else {
                dispatch(deleteAgentFailed(agents));
            }
        }).catch(error => {
            dispatch(deleteAgentFailed(error));
        });
    };
}


export function getAgentPending() {
    return {type: types.GET_AGENTS_INITIATED};
}
export function getAgentSuccess(agents) {
    return {type: types.GET_AGENTS_SUCCESS, agents};
}
export function getAgentFailed(error) {
    return {type: types.GET_AGENTS_FAILED, error};
}
export function createAgentPending() {
    return {type: types.CREATE_AGENTS_INITIATED};
}
export function createAgentSuccess(agents) {
    return {type: types.CREATE_AGENTS_SUCCESS, agents};
}
export function createAgentFailed(error) {
    return {type: types.CREATE_AGENTS_FAILED, error};
}
export function updateAgentPending() {
    return {type: types.UPDATE_AGENTS_INITIATED};
}
export function updateAgentSuccess(agents) {
    return {type: types.UPDATE_AGENTS_SUCCESS, agents};
}
export function updateAgentFailed(error) {
    return {type: types.UPDATE_AGENTS_FAILED,error};
}
export function deleteAgentPending() {
    return {type: types.DELETE_AGENTS_INITIATED};
}
export function deleteAgentSuccess(agents) {
    return {type: types.DELETE_AGENTS_SUCCESS, agents};
}
export function deleteAgentFailed(error) {
    return {type: types.DELETE_AGENTS_FAILED, error};
}
export function getAgentbyIdPending() {
    return {type: types.GET_AGENTS_BY_ID_INITIATED};
}
export function getAgentbyIdSuccess(agent) {
    return {type: types.GET_AGENTS_BY_ID_SUCCESS, agent};
}
export function getAgentbyIdFailed(error) {
    return {type: types.GET_AGENTS_BY_ID_FAILED, error};
}
