import manufacturingOrderApi from '../../api/home/manufacturingOrder'
import * as types from '../actionTypes'

export function getManufacturingOrders(token) {
    return function(dispatch) {
        dispatch(getManufacturingOrderPending());
        return manufacturingOrderApi.getManufacturingOrders(token).then(manufacturingOrders => {
            if(manufacturingOrders.statusDTO.status === "SUCCESS") {
                dispatch(getManufacturingOrderSuccess(manufacturingOrders));
            } else {
                dispatch(getManufacturingOrderFailed(manufacturingOrders));
            }
        }).catch(error => {
           dispatch(getManufacturingOrderFailed(error));
        });
    };
}
export function getManufacturingOrderbyId(token) {
    return function(dispatch) {
        dispatch(getManufacturingOrderbyIdPending());
        return manufacturingOrderApi.getManufacturingOrderbyID(token).then(manufacturingOrders => {
            if(manufacturingOrders.statusDTO.status === "SUCCESS") {
                dispatch(getManufacturingOrderbyIdSuccess(manufacturingOrders));
            } else {
                dispatch(getManufacturingOrderbyIdFailed(manufacturingOrders));
            }
        }).catch(error => {
           dispatch(getManufacturingOrderbyIdFailed(error));
        });
    };
}
export function createManufacturingOrder(manufacturingOrderId, manufacturingOrderData , token) {
    return function(dispatch) {
        dispatch(createManufacturingOrderPending());
        return manufacturingOrderApi.createManufacturingOrder(manufacturingOrderId, manufacturingOrderData , token).then(manufacturingOrders => {
            if(manufacturingOrders.statusDTO.status === "SUCCESS") {
                dispatch(createManufacturingOrderSuccess(manufacturingOrders));
            } else {
                dispatch(createManufacturingOrderFailed(manufacturingOrders));
            }
        }).catch(error => {
            dispatch(createManufacturingOrderFailed(error));
        });
    };
}
export function updateManufacturingOrder(manufacturingOrderData , token) {
    return function(dispatch) {
        dispatch(updateManufacturingOrderPending());
        return manufacturingOrderApi.updateManufacturingOrder(manufacturingOrderData , token).then(manufacturingOrders => {
            if(manufacturingOrders.statusDTO.status === "SUCCESS") {
                dispatch(updateManufacturingOrderSuccess(manufacturingOrders));
            } else {
                dispatch(updateManufacturingOrderFailed(manufacturingOrders));
            }
        }).catch(error => {
            dispatch(updateManufacturingOrderFailed(error));
        });
    };
}
export function deleteManufacturingOrder(manufacturingOrderData , token) {
    return function(dispatch) {
        dispatch(deleteManufacturingOrderPending());
        return manufacturingOrderApi.deleteManufacturingOrder(manufacturingOrderData , token).then(manufacturingOrders => {
            if(manufacturingOrders.statusDTO.status === "SUCCESS") {
                dispatch(deleteManufacturingOrderSuccess(manufacturingOrders));
            } else {
                dispatch(deleteManufacturingOrderFailed(manufacturingOrders));
            }
        }).catch(error => {
            dispatch(deleteManufacturingOrderFailed(error));
        });
    };
}


export function getManufacturingOrderPending() {
    return {type: types.GET_MANUFACTURING_ORDERS_INITIATED};
}
export function getManufacturingOrderSuccess(manufacturingOrders) {
    return {type: types.GET_MANUFACTURING_ORDERS_SUCCESS, manufacturingOrders};
}
export function getManufacturingOrderFailed(error) {
    return {type: types.GET_MANUFACTURING_ORDERS_FAILED, error};
}
export function createManufacturingOrderPending() {
    return {type: types.CREATE_MANUFACTURING_ORDERS_INITIATED};
}
export function createManufacturingOrderSuccess(manufacturingOrders) {
    return {type: types.CREATE_MANUFACTURING_ORDERS_SUCCESS, manufacturingOrders};
}
export function createManufacturingOrderFailed(error) {
    return {type: types.CREATE_MANUFACTURING_ORDERS_FAILED, error};
}
export function updateManufacturingOrderPending() {
    return {type: types.UPDATE_MANUFACTURING_ORDERS_INITIATED};
}
export function updateManufacturingOrderSuccess(manufacturingOrders) {
    return {type: types.UPDATE_MANUFACTURING_ORDERS_SUCCESS, manufacturingOrders};
}
export function updateManufacturingOrderFailed(error) {
    return {type: types.UPDATE_MANUFACTURING_ORDERS_FAILED,error};
}
export function deleteManufacturingOrderPending() {
    return {type: types.DELETE_MANUFACTURING_ORDERS_INITIATED};
}
export function deleteManufacturingOrderSuccess(manufacturingOrders) {
    return {type: types.DELETE_MANUFACTURING_ORDERS_SUCCESS, manufacturingOrders};
}
export function deleteManufacturingOrderFailed(error) {
    return {type: types.DELETE_MANUFACTURING_ORDERS_FAILED, error};
}
export function getManufacturingOrderbyIdPending() {
    return {type: types.GET_MANUFACTURING_ORDERS_BY_ID_INITIATED};
}
export function getManufacturingOrderbyIdSuccess(manufacturingOrder) {
    return {type: types.GET_MANUFACTURING_ORDERS_BY_ID_SUCCESS, manufacturingOrder};
}
export function getManufacturingOrderbyIdFailed(error) {
    return {type: types.GET_MANUFACTURING_ORDERS_BY_ID_FAILED, error};
}
