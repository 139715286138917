import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;


const auth = (r) => r.json();
class authApi {
    static login(userParams) {
        return fetch(`${host}/user/validate`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(userParams)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getUser(token) {
        return fetch(`${host}/v1/auth/employee-profile`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            method: 'GET'
        })
            .then((response) => auth.verifyResponse(response))
            .then((json) => json)
            .catch((err) => err);
    }
    static setUser(profileParams,token) {
        return fetch(`${host}/v1/auth/update-employee-profile`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(profileParams)
        })
            .then((response) => auth.verifyResponse(response))
            .then((json) => json)
            .catch((err) => err);
    }
    static logout(token) {
        return fetch(`${host}/v1/auth/logout`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static resetPassword(narayana_id) {
        return fetch(`${host}/v1/auth/employee-forgot-password?narayana_employee_id=${narayana_id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => auth.verifyResponse(response))
            .then((json) => json)
            .catch((err) => err);
    }
    static setPassword(passwordParams,token) {
        return fetch(`${host}/v1/auth/update-password`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(passwordParams)
        })
            .then((response) => auth.verifyResponse(response))
            .then((json) => json)
            .catch((err) => err);
    }
}

export default authApi;