import reduxStore from '../store';
const history = reduxStore.history;
class auth{
    static isLoggedIn(){
        const userToken = localStorage.getItem('_token');
        if(!!userToken)
            return true;
        else
            return false;
    }
    static logOut(){
        localStorage.clear();
        history.push('/');
        return true;
    }
    static verifyResponse(response){
        if(response.status === 200) {
            return response.json();
        } else if(response.status === 401) {
            this.logOut();
            return response.json();
        } else {
            return response.json();
        }
    }
    static isSuperAdmin() {
        const userId = localStorage.getItem('_userId');
        const userRole = localStorage.getItem('_userRole');
        if(userId === "8" && userRole === 'ADMIN')
            return true;
        else
            return false;
    }
}
export default auth;