import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.purchaseOrder, action) {
    switch (action.type) {
        case types.GET_PURCHASE_ORDERS_INITIATED:
            return {
                ...state,
                getPurchaseOrdersPending: true,
                    error: null,
                    getPurchaseOrdersSuccess: false,
                    createPurchaseOrderSuccess: false,
                    deletePurchaseOrderSuccess: false,
                    updatePurchaseOrderSuccess: false
            };
        case types.GET_PURCHASE_ORDERS_FAILED:
            return {
                ...state,
                getPurchaseOrdersPending: false,
                    error: action.error,
                    getPurchaseOrdersSuccess: false,
                    createPurchaseOrderSuccess: false
            };
        case types.GET_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                getPurchaseOrdersPending: false,
                    getPurchaseOrdersSuccess: true,
                    error: null,
                    data: action.purchaseOrders,
                    createPurchaseOrderSuccess: false
            };
        case types.CREATE_PURCHASE_ORDERS_INITIATED:
            return {
                ...state,
                createPurchaseOrderPending: false,
                    error: null
            };
        case types.CREATE_PURCHASE_ORDERS_FAILED:
            return {
                ...state,
                createPurchaseOrderPending: false,
                    error: action.error
            };
        case types.CREATE_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                createPurchaseOrderPending: false,
                    createPurchaseOrderSuccess: true,
                    error: null
            };
        case types.UPDATE_PURCHASE_ORDERS_INITIATED:
            return {
                ...state,
                updatePurchaseOrderPending: true,
                    error: null
            };
        case types.UPDATE_PURCHASE_ORDERS_FAILED:
            return {
                ...state,
                updatePurchaseOrderPending: false,
                    error: action.error
            };
        case types.UPDATE_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                updatePurchaseOrderPending: false,
                    updatePurchaseOrderSuccess: true,
                    createPurchaseOrderSuccess: false,
                    getPurchaseOrdersPending: false,
                    error: null
            };
        case types.DELETE_PURCHASE_ORDERS_INITIATED:
            return {
                ...state,
                deletePurchaseOrderPending: true,
                    error: null
            };
        case types.DELETE_PURCHASE_ORDERS_FAILED:
            return {
                ...state,
                deletePurchaseOrderPending: false,
                    error: action.error
            };
        case types.DELETE_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                deletePurchaseOrderPending: false,
                    deletePurchaseOrderSuccess: true,
                    createPurchaseOrderSuccess: false,
                    error: null
            };
        case types.GET_PURCHASE_ORDERS_BY_ID_INITIATED:
            return {
                ...state,
                getPurchaseOrderbyIdPending: true,
                    getPurchaseOrderbyIDSuccess: false,
                    getPurchaseOrderbyIdError: null,
                    getPurchaseOrdersSuccess: false,
                    createPurchaseOrderSuccess: false,
                    deletePurchaseOrderSuccess: false,
                    updatePurchaseOrderSuccess: false
            };
        case types.GET_PURCHASE_ORDERS_BY_ID_FAILED:
            return {
                ...state,
                getPurchaseOrdersPending: false,
                    getPurchaseOrderbyIdPending: false,
                    getPurchaseOrderbyIDSuccess: false,
                    getPurchaseOrderbyIdError: action.error,
                    getPurchaseOrdersSuccess: false,
                    createPurchaseOrderSuccess: false
            };
        case types.GET_PURCHASE_ORDERS_BY_ID_SUCCESS:
            return {
                ...state,
                getPurchaseOrdersPending: false,
                    getPurchaseOrdersSuccess: false,
                    getPurchaseOrderbyIdPending: true,
                    getPurchaseOrderbyIDSuccess: action.purchaseOrder,
                    getPurchaseOrderbyIdError: null,
                    createPurchaseOrderSuccess: false
            };
        default:
            return state;
    }
}
