import orderItemApi from '../../api/home/orderItem'
import * as types from '../actionTypes'

export function getOrderItems(token) {
    return function(dispatch) {
        dispatch(getOrderItemPending());
        return orderItemApi.getOrderItems(token).then(orderItems => {
            if(orderItems.statusDTO.status === "SUCCESS") {
                dispatch(getOrderItemSuccess(orderItems));
            } else {
                dispatch(getOrderItemFailed(orderItems));
            }
        }).catch(error => {
           dispatch(getOrderItemFailed(error));
        });
    };
}
export function getOrderItembyId(token) {
    return function(dispatch) {
        dispatch(getOrderItembyIdPending());
        return orderItemApi.getOrderItembyID(token).then(orderItems => {
            if(orderItems.statusDTO.status === "SUCCESS") {
                dispatch(getOrderItembyIdSuccess(orderItems));
            } else {
                dispatch(getOrderItembyIdFailed(orderItems));
            }
        }).catch(error => {
           dispatch(getOrderItembyIdFailed(error));
        });
    };
}
export function createOrderItem(orderItemId, orderItemData , token) {
    return function(dispatch) {
        dispatch(createOrderItemPending());
        return orderItemApi.createOrderItem(orderItemId, orderItemData , token).then(orderItems => {
            if(orderItems.statusDTO.status === "SUCCESS") {
                dispatch(createOrderItemSuccess(orderItems));
            } else {
                dispatch(createOrderItemFailed(orderItems));
            }
        }).catch(error => {
            dispatch(createOrderItemFailed(error));
        });
    };
}
export function updateOrderItem(orderItemData , token) {
    return function(dispatch) {
        dispatch(updateOrderItemPending());
        return orderItemApi.updateOrderItem(orderItemData , token).then(orderItems => {
            if(orderItems.statusDTO.status === "SUCCESS") {
                dispatch(updateOrderItemSuccess(orderItems));
            } else {
                dispatch(updateOrderItemFailed(orderItems));
            }
        }).catch(error => {
            dispatch(updateOrderItemFailed(error));
        });
    };
}
export function deleteOrderItem(orderItemData , token) {
    return function(dispatch) {
        dispatch(deleteOrderItemPending());
        return orderItemApi.deleteOrderItem(orderItemData , token).then(orderItems => {
            if(orderItems.statusDTO.status === "SUCCESS") {
                dispatch(deleteOrderItemSuccess(orderItems));
            } else {
                dispatch(deleteOrderItemFailed(orderItems));
            }
        }).catch(error => {
            dispatch(deleteOrderItemFailed(error));
        });
    };
}


export function getOrderItemPending() {
    return {type: types.GET_ORDER_ITEMS_INITIATED};
}
export function getOrderItemSuccess(orderItems) {
    return {type: types.GET_ORDER_ITEMS_SUCCESS, orderItems};
}
export function getOrderItemFailed(error) {
    return {type: types.GET_ORDER_ITEMS_FAILED, error};
}
export function createOrderItemPending() {
    return {type: types.CREATE_ORDER_ITEMS_INITIATED};
}
export function createOrderItemSuccess(orderItems) {
    return {type: types.CREATE_ORDER_ITEMS_SUCCESS, orderItems};
}
export function createOrderItemFailed(error) {
    return {type: types.CREATE_ORDER_ITEMS_FAILED, error: error.statusDTO};
}
export function updateOrderItemPending() {
    return {type: types.UPDATE_ORDER_ITEMS_INITIATED};
}
export function updateOrderItemSuccess(orderItems) {
    return {type: types.UPDATE_ORDER_ITEMS_SUCCESS, orderItems};
}
export function updateOrderItemFailed(error) {
    return {type: types.UPDATE_ORDER_ITEMS_FAILED,error};
}
export function deleteOrderItemPending() {
    return {type: types.DELETE_ORDER_ITEMS_INITIATED};
}
export function deleteOrderItemSuccess(orderItems) {
    return {type: types.DELETE_ORDER_ITEMS_SUCCESS, orderItems};
}
export function deleteOrderItemFailed(error) {
    return {type: types.DELETE_ORDER_ITEMS_FAILED, error};
}
export function getOrderItembyIdPending() {
    return {type: types.GET_ORDER_ITEMS_BY_ID_INITIATED};
}
export function getOrderItembyIdSuccess(orderItem) {
    return {type: types.GET_ORDER_ITEMS_BY_ID_SUCCESS, orderItem};
}
export function getOrderItembyIdFailed(error) {
    return {type: types.GET_ORDER_ITEMS_BY_ID_FAILED, error};
}
