import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class manufacturingOrderApi {
    static getManufacturingOrders(token) {
        return fetch(`${host}/manufacturingOrder/listall`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createManufacturingOrder(manufacturingOrderData,token) {
        return fetch(`${host}/manufacturingOrder/add`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(manufacturingOrderData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updateManufacturingOrder(manufacturingOrderID, manufacturingOrderData,token) {
        return fetch(`${host}/manufacturingOrder/update/${manufacturingOrderID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(manufacturingOrderData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deleteManufacturingOrder(deleteId,token) {
        return fetch(`${host}/manufacturingOrder/delete/${deleteId}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getManufacturingOrderbyID(manufacturingOrderID, token) {
        return fetch(`${host}/manufacturingOrder/getManufacturingOrder/${manufacturingOrderID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default manufacturingOrderApi;