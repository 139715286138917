import checkingItemApi from '../../api/home/checkingItem'
import * as types from '../actionTypes'

export function getCheckingItems(token) {
    return function(dispatch) {
        dispatch(getCheckingItemPending());
        return checkingItemApi.getCheckingItems(token).then(checkingItems => {
            if(checkingItems.statusDTO.status === "SUCCESS") {
                dispatch(getCheckingItemSuccess(checkingItems));
            } else {
                dispatch(getCheckingItemFailed(checkingItems));
            }
        }).catch(error => {
           dispatch(getCheckingItemFailed(error));
        });
    };
}
export function getCheckingItembyId(token) {
    return function(dispatch) {
        dispatch(getCheckingItembyIdPending());
        return checkingItemApi.getCheckingItembyID(token).then(checkingItems => {
            if(checkingItems.statusDTO.status === "SUCCESS") {
                dispatch(getCheckingItembyIdSuccess(checkingItems));
            } else {
                dispatch(getCheckingItembyIdFailed(checkingItems));
            }
        }).catch(error => {
           dispatch(getCheckingItembyIdFailed(error));
        });
    };
}
export function createCheckingItem(checkingItemId, checkingItemData , token) {
    return function(dispatch) {
        dispatch(createCheckingItemPending());
        return checkingItemApi.createCheckingItem(checkingItemId, checkingItemData , token).then(checkingItems => {
            if(checkingItems.statusDTO.status === "SUCCESS") {
                dispatch(createCheckingItemSuccess(checkingItems));
            } else {
                dispatch(createCheckingItemFailed(checkingItems));
            }
        }).catch(error => {
            dispatch(createCheckingItemFailed(error));
        });
    };
}
export function updateCheckingItem(checkingItemData , token) {
    return function(dispatch) {
        dispatch(updateCheckingItemPending());
        return checkingItemApi.updateCheckingItem(checkingItemData , token).then(checkingItems => {
            if(checkingItems.statusDTO.status === "SUCCESS") {
                dispatch(updateCheckingItemSuccess(checkingItems));
            } else {
                dispatch(updateCheckingItemFailed(checkingItems));
            }
        }).catch(error => {
            dispatch(updateCheckingItemFailed(error));
        });
    };
}
export function deleteCheckingItem(checkingItemData , token) {
    return function(dispatch) {
        dispatch(deleteCheckingItemPending());
        return checkingItemApi.deleteCheckingItem(checkingItemData , token).then(checkingItems => {
            if(checkingItems.statusDTO.status === "SUCCESS") {
                dispatch(deleteCheckingItemSuccess(checkingItems));
            } else {
                dispatch(deleteCheckingItemFailed(checkingItems));
            }
        }).catch(error => {
            dispatch(deleteCheckingItemFailed(error));
        });
    };
}


export function getCheckingItemPending() {
    return {type: types.GET_CHECKING_INITIATED};
}
export function getCheckingItemSuccess(checkingItems) {
    return {type: types.GET_CHECKING_SUCCESS, checkingItems};
}
export function getCheckingItemFailed(error) {
    return {type: types.GET_CHECKING_FAILED, error};
}
export function createCheckingItemPending() {
    return {type: types.CREATE_CHECKING_INITIATED};
}
export function createCheckingItemSuccess(checkingItems) {
    return {type: types.CREATE_CHECKING_SUCCESS, checkingItems};
}
export function createCheckingItemFailed(error) {
    return {type: types.CREATE_CHECKING_FAILED, error};
}
export function updateCheckingItemPending() {
    return {type: types.UPDATE_CHECKING_INITIATED};
}
export function updateCheckingItemSuccess(checkingItems) {
    return {type: types.UPDATE_CHECKING_SUCCESS, checkingItems};
}
export function updateCheckingItemFailed(error) {
    return {type: types.UPDATE_CHECKING_FAILED,error};
}
export function deleteCheckingItemPending() {
    return {type: types.DELETE_CHECKING_INITIATED};
}
export function deleteCheckingItemSuccess(checkingItems) {
    return {type: types.DELETE_CHECKING_SUCCESS, checkingItems};
}
export function deleteCheckingItemFailed(error) {
    return {type: types.DELETE_CHECKING_FAILED, error};
}
export function getCheckingItembyIdPending() {
    return {type: types.GET_CHECKING_BY_ID_INITIATED};
}
export function getCheckingItembyIdSuccess(checkingItem) {
    return {type: types.GET_CHECKING_BY_ID_SUCCESS, checkingItem};
}
export function getCheckingItembyIdFailed(error) {
    return {type: types.GET_CHECKING_BY_ID_FAILED, error};
}
