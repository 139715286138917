import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Raven from 'raven-js';
import moment from 'moment';
import { alphanumericwithspace } from '../../modules/validator';
import { Timeline } from 'antd';
import { Radio } from 'antd';
import reduxStore from '../../store';
import SideBar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import { Row, Col, Table, Divider, Icon, Drawer,Form, Input, Modal, message, Breadcrumb, Button, Tooltip, notification, Select } from 'antd';
import * as purchaseOrderActions from '../../actions/data/purchaseOrder';
import * as supplierActions from '../../actions/data/supplier';
import * as agentActions from '../../actions/data/agent';
import './purchaseOrder.scss';

const history = reduxStore.history;
const confirm = Modal.confirm;
const FormItem = Form.Item;
const { TextArea } = Input;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};
class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfPossibleItems : 5,
      visible: false,
      suppliersData: [],
      agentsData: [],
      purchaseOrdersData: [],
      createPurchaseOrderVisible: false,
      currentRow: {},
      editedPurchaseOrderId: '',
      purchaseOrderdEdited: false,
      dropdownFilter: '',
      editClicked: false,
      updated: false,
    };
    this.onChange = this.onChange.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.createPurchaseOrderShowDrawer = this.createPurchaseOrderShowDrawer.bind(this);
    this.onCreatePurchaseOrderClose = this.onCreatePurchaseOrderClose.bind(this);
    this.handleCreatePurchaseOrder = this.handleCreatePurchaseOrder.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.handleUpdatePurchaseOrder = this.handleUpdatePurchaseOrder.bind(this);
    this.handleDropDownSearch = this.handleDropDownSearch.bind(this);
    this.showViewDrawer = this.showViewDrawer.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
  }
  componentDidMount() {
    document.title = "MRC Fabrics | PurchaseOrders";
    const newMaxHeight = window.innerHeight - 330;
    const possibleRows = Math.ceil(newMaxHeight/54);
    if(possibleRows > this.state.numberOfPossibleItems) {
      this.setState({numberOfPossibleItems: possibleRows});
    }
    // if(this.props.user.loginSuccess) {
    //   message.success('Logged in successfully!', 3);
    // }
    let userToken = null;
    this.props.actions.getPurchaseOrders(userToken);
    this.props.actions.getAgents(userToken);
    this.props.actions.getSuppliers(userToken);
  }
  static getDerivedStateFromProps(nextProps, currentProps) {
    console.log( nextProps)
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    if(nextProps.suppliers && nextProps.suppliers.getSuppliersSuccess) {
      nextProps.suppliers.getSuppliersSuccess = false;
      return {
        suppliersData: nextProps.suppliers.data.listSupplierDTO.map(data => {data.key = data.supplierId; return data})
      }
    }
    if(nextProps.agents && nextProps.agents.getAgentsSuccess) {
      nextProps.agents.getAgentsSuccess = false;
      return {
        agentsData: nextProps.agents.data.listAgentDTO.map(data => {data.key = data.agentId; return data})
      }
    }
    if(nextProps.purchaseOrders && nextProps.purchaseOrders.getPurchaseOrdersSuccess) {
      nextProps.purchaseOrders.getPurchaseOrdersSuccess = false;
      return {
        updated: true, 
        purchaseOrdersData: nextProps.purchaseOrders.data.listPurchaseOrderDTO.map(data => {data.key = data.purchaseOrderId; return data})
      }
    }
    if(nextProps.purchaseOrders && nextProps.purchaseOrders.createPurchaseOrderSuccess && !nextProps.purchaseOrders.createPurchaseOrderPending) {
      nextProps.purchaseOrders.createPurchaseOrderSuccess = false;
      nextProps.actions.getPurchaseOrders(userToken);
      nextProps.form.resetFields();
      message.success('PurchaseOrder created successfully!', 2);
      return {
        updated: false,
        currentRow: {},
        createPurchaseOrderVisible: false
      };
    }
     if(nextProps.purchaseOrders && nextProps.purchaseOrders.updatePurchaseOrderSuccess && !nextProps.purchaseOrders.updatePurchaseOrderPending) {
      nextProps.purchaseOrders.updatePurchaseOrderSuccess  = false;
      nextProps.actions.getPurchaseOrders(userToken);
      nextProps.form.resetFields();
      message.success('PurchaseOrder edited successfully!', 2);
      return {
        visible: false,
        editClicked: false,
        currentRow: {},
        purchaseOrderdEdited: true,
        updated: false
      };
  }
  if(nextProps.purchaseOrders && nextProps.purchaseOrders.deletePurchaseOrderSuccess && !nextProps.purchaseOrders.deletePurchaseOrderPending) {
    nextProps.purchaseOrders.deletePurchaseOrderSuccess = false;  
    nextProps.actions.getPurchaseOrders(userToken);
    message.success('PurchaseOrder deleted successfully!', 2);
    return {
      currentRow: '',
      updated: false
    };
    }
    if(nextProps.purchaseOrders && nextProps.purchaseOrders.error) {
      if(nextProps.purchaseOrders.error.message)
        message.error(nextProps.purchaseOrders.error.message,2);
        nextProps.purchaseOrders.error = {};
      //   Raven.setExtraContext({
      //       props: this.props
      //   });
      // Raven.captureException(nextProps.purchaseOrders.error.message, { extra: nextProps });
      if(nextProps.purchaseOrders.error.message === "Failed to fetch") {
        openNotificationWithIcon("error", "It looks like you've lost your internet connection", "But you probably already knew that! This must be frustrating, Don't leave us, we track these things and resume automatically! Until then just relax!! ");           
      }
    }
  return null;
    
   
    
}
  handleDropDownSearch(dropdownFilter) {
    if(!dropdownFilter)
      dropdownFilter='';
    this.setState({dropdownFilter});
  }
  onTableRowClick(currentRow) {
    this.setState({currentRow,purchaseOrderdEdited: false, editedPurchaseOrderId: ''});
  }
  showConfirm() {
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    const deletePurchaseOrder = () => {
      this.props.actions.deletePurchaseOrder(this.state.currentRow.purchaseOrderId, userToken);
    } 
    const resetFields = () => {
      this.setState({currentRow: ''});
    }
    confirm({
      title: 'Are you sure delete this PurchaseOrder?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletePurchaseOrder();
      },
      onCancel() {
        resetFields();
        console.log('Cancel');
      },
    });
  }
  onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
  }  
  onViewClose (){
    this.setState({
      viewVisible: false,
      currentRow: ''
    });
  }
  showViewDrawer (){
    this.setState({
      viewVisible: true,
    });
  }
  createPurchaseOrderShowDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      createPurchaseOrderVisible: true,
    });
  };

  onCreatePurchaseOrderClose = () => {
    this.props.form.resetFields();
    this.setState({
      createPurchaseOrderVisible: false,
    });
  };
  handleCreatePurchaseOrder = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.supplierId = parseInt(values.supplierId,10);
        values.purchaseAccountId = parseInt(values.purchaseAccountId, 10);
        values.agentId = parseInt(values.agentId,10);
        values.creditTypeId = parseInt(values.creditTypeId,10);
        values.creditAmount = parseInt(values.creditAmount,10);
        values.taxAccountId = parseInt(values.taxAccountId,10);
        values.commisionAmt = parseInt(values.commisionAmt,10);
        values.taxPercent = parseInt(values.taxPercent,10);
        values.grossAmount = parseInt(values.grossAmount,10);
        values.taxAmount = parseInt(values.taxAmount,10);
        values.discountAmt = parseInt(values.discountAmt,10);
        values.addOrLess = parseInt(values.addOrLess,10);
        values.netAmount = parseInt(values.netAmount,10);
        this.props.actions.createPurchaseOrder(values, userToken);
      }
    });
  }
  handleUpdatePurchaseOrder = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.supplierId = parseInt(values.supplierId,10);
        values.purchaseAccountId = parseInt(values.purchaseAccountId, 10);
        values.agentId = parseInt(values.agentId,10);
        values.creditTypeId = parseInt(values.creditTypeId,10);
        values.creditAmount = parseInt(values.creditAmount,10);
        values.taxAccountId = parseInt(values.taxAccountId,10);
        values.commisionAmt = parseInt(values.commisionAmt,10);
        values.taxPercent = parseInt(values.taxPercent,10);
        values.grossAmount = parseInt(values.grossAmount,10);
        values.taxAmount = parseInt(values.taxAmount,10);
        values.discountAmt = parseInt(values.discountAmt,10);
        values.addOrLess = parseInt(values.addOrLess,10);
        values.netAmount = parseInt(values.netAmount,10);
        this.setState({editedPurchaseOrderId: this.state.currentRow.purchaseOrderId});
        this.props.actions.updatePurchaseOrder(this.state.currentRow.purchaseOrderId, values, userToken);
      }
    });
  }
  showDrawer (){
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editClicked: true
    });
  }
  onClose (){
    this.setState({
      visible: false,
      editClicked: false,
      currentRow: {}
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: 'Invoice No',
      dataIndex: 'invoiceNum',
      sorter: (a, b) => { return a.invoiceNum.localeCompare(b.invoiceNum)}
    }, {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: (a, b) => { return a.remarks.localeCompare(b.remarks)},
      render: (text, record) => {
        if(text && text.length > 50) {
          return text.substring(0,50)+'...';
        } else {
          return text;
        }
      }
    }, {
      title: 'Gross Amount',
      dataIndex: 'grossAmount',
      sorter: (a, b) => { return a.grossAmount.localeCompare(b.grossAmount)}
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      sorter: (a, b) => { return a.netAmount.localeCompare(b.netAmount)},
    },
    {
      title: 'Action',
      key: 'action',
      width: 350,
      render: (text, record) => (
        <span>
          <Tooltip title="Manage OrderItems">
            <span onClick={() => {window.location='/orderItem?id='+record.purchaseOrderId}} className="cursor-pointer"><Icon type="calculator" /> Order Items</span>
          </Tooltip> 
          <Divider type="vertical" />
         <Tooltip title="View PurchaseOrder">
            <span onClick={this.showViewDrawer} className="cursor-pointer"><Icon type="eye" /> View</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Edit PurchaseOrder">
            <span onClick={this.showDrawer} className="cursor-pointer"><Icon type="edit" /> Edit</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Delete PurchaseOrder">
            <span  onClick={this.showConfirm} className="cursor-pointer"><Icon type="delete" /> Delete</span>
          </Tooltip>
          {record.idpurchaseOrder === this.state.editedPurchaseOrderId && this.state.purchaseOrderdEdited ? <span className={"success"} style={{fontSize: 11,paddingLeft: 10,color: 'green'}}>updated!</span> : null}
        </span>
      )
    }];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return(
      <Row>
        <Col 
          xs={6} 
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className="height-100">
          <SideBar changePath={history.push} {...history}/>
        </Col>
        <Col
          xs={18} 
          sm={18}
          md={20}
          lg={20}
          xl={20} className="height-100-55 dashboard">
            <Row>
              <Header />
              <Row className="contents-main">
                    <div className="breadcrumb-div">
                    <Breadcrumb>
                      <Breadcrumb.Item>Home</Breadcrumb.Item>
                      <Breadcrumb.Item>PurchaseOrder</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="add-purchaseOrder-button">
                        <Button type="primary" className="login-form-button" style = {{
                          width: 200,
                          float: 'right',
                          marginTop: -30
                        }}onClick={this.createPurchaseOrderShowDrawer}>
                        <Icon type="plus" /> Add PurchaseOrder
                        </Button>
                    </div>
                    </div>
                    <div className="main-table-div">
                      <Table 
                        className="main-table" 
                        columns={columns} 
                        dataSource={this.state.purchaseOrdersData} 
                        pagination={{pageSize:this.state.numberOfPossibleItems}} 
                        loading={this.state.purchaseOrdersData.length === 0&& !this.state.updated}
                        locale={{ emptyText: 'No purchaseOrders added!' }}
                        onChange={this.onChange} onRow={(record, index) => ({
                          onClick: (event) => { this.onTableRowClick(record); } 
                      })}/>
                    </div>
                </Row>
                <Drawer
                  title="Add PurchaseOrder"
                  placement="right"
                  closable={false}
                  onClose={this.onCreatePurchaseOrderClose}
                  visible={this.state.createPurchaseOrderVisible}
                  width={450}
                >
                  <Form onSubmit={this.handleCreatePurchaseOrder} className="purchaseOrder-create" id="createPurchaseOrder">
                        <FormItem
                            {...formItemLayout}
                            label="Supplier"
                            >
                            {getFieldDecorator('supplierId', {
                                rules: [{ required: true, message: 'Please select supplier!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.suppliersData.length)? 
                                  this.state.suppliersData.map(supplier => 
                                  <Option value={supplier.supplierId.toString()} key={'supplier'+supplier.supplierId}>{supplier.supplierName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Agent"
                            >
                            {getFieldDecorator('agentId', {
                                rules: [{ required: true, message: 'Please select agent!'}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.agentsData.length)? 
                                  this.state.agentsData.map(agent => 
                                  <Option value={agent.agentId.toString()} key={'supplier'+agent.agentId}>{agent.agentName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Purchase Account Id"
                            >
                            {getFieldDecorator('purchaseAccountId', {
                                rules: [{ required: true, message: 'Please enter purchase Account ID!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Invoice No"
                            >
                            {getFieldDecorator('invoiceNum', {
                                rules: [{ required: true, message: 'Please enter invoice number!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Credit Type"
                            >
                            {getFieldDecorator('creditTypeId', {
                                rules: [{ required: true, message: 'Please enter purchaseOrder credit Type Id!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Credit Amount"
                            >
                            {getFieldDecorator('creditAmount', {
                                rules: [{ required: true, message: 'Please enter creditAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Remarks"
                            >
                            {getFieldDecorator('remarks', {
                                rules: [{ required: true, message: 'Please enter remarks', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Tax Account"
                            >
                            {getFieldDecorator('taxAccountId', {
                                rules: [{ required: true, message: 'Please enter taxAccountId!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Commision Amt"
                            >
                            {getFieldDecorator('commisionAmt', {
                                rules: [{ required: true, message: 'Please enter commisionAmt!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Tax Percent"
                            >
                            {getFieldDecorator('taxPercent', {
                                rules: [{ required: true, message: 'Please enter taxPercent!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Gross Amount"
                            >
                            {getFieldDecorator('grossAmount', {
                                rules: [{ required: true, message: 'Please enter grossAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Tax Amount"
                            >
                            {getFieldDecorator('taxAmount', {
                                rules: [{ required: true, message: 'Please enter taxAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Discount Amt"
                            >
                            {getFieldDecorator('discountAmt', {
                                rules: [{ required: true, message: 'Please enter discountAmt!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Add Or Less"
                            >
                            {getFieldDecorator('addOrLess', {
                                rules: [{ required: true, message: 'Please enter addOrLess!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Net Amount"
                            >
                            {getFieldDecorator('netAmount', {
                                rules: [{ required: true, message: 'Please enter netAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>

                        <br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Create</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onCreatePurchaseOrderClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
                </Drawer>
            <Drawer
                title="Edit PurchaseOrder"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                width={450}
              >
              <Form onSubmit={this.handleUpdatePurchaseOrder} className="purchaseOrder-create" id="updatePurchaseOrder">
                        <FormItem
                            {...formItemLayout}
                            label="Supplier"
                            >
                            {getFieldDecorator('supplierId', {
                                rules: [{ required: true, message: 'Please select supplier!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.supplierId.toString() : null
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.suppliersData.length)? 
                                  this.state.suppliersData.map(supplier => 
                                  <Option value={supplier.supplierId.toString()} key={'supplier'+supplier.supplierId}>{supplier.supplierName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                              <FormItem
                            {...formItemLayout}
                            label="Agent"
                            >
                            {getFieldDecorator('agentId', {
                                rules: [{ required: true, message: 'Please select agent!'}],
                                initialValue: this.state.editClicked ? this.state.currentRow.agentId.toString() : null
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.agentsData.length)? 
                                  this.state.agentsData.map(agent => 
                                  <Option value={agent.agentId.toString()} key={'supplier'+agent.agentId}>{agent.agentName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Purchase Order"
                            >
                            {getFieldDecorator('purchaseAccountId', {
                                rules: [{ required: true, message: 'Please enter purchase Account Id', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.purchaseAccountId.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Invoice No"
                            >
                            {getFieldDecorator('invoiceNum', {
                                rules: [{ required: true, message: 'Please enter invoice number!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.invoiceNum.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Credit Type"
                            >
                            {getFieldDecorator('creditTypeId', {
                                rules: [{ required: true, message: 'Please enter creditTypeId!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.creditTypeId.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Credit Amount"
                            >
                            {getFieldDecorator('creditAmount', {
                                rules: [{ required: true, message: 'Please enter purchaseOrder creditAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.creditAmount.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Remarks"
                            >
                            {getFieldDecorator('remarks', {
                                rules: [{ required: true, message: 'Please enter remarks!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.remarks.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Tax Account"
                            >
                            {getFieldDecorator('taxAccountId', {
                                rules: [{ required: true, message: 'Please enter taxAccountId!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.taxAccountId.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Commision Amt"
                            >
                            {getFieldDecorator('commisionAmt', {
                                rules: [{ required: true, message: 'Please enter commisionAmt!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.commisionAmt.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Tax Percent"
                            >
                            {getFieldDecorator('taxPercent', {
                                rules: [{ required: true, message: 'Please enter purchaseOrder taxPercent!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.taxPercent.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Gross Amount"
                            >
                            {getFieldDecorator('grossAmount', {
                                rules: [{ required: true, message: 'Please enter grossAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.grossAmount.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Tax Amount"
                            >
                            {getFieldDecorator('taxAmount', {
                                rules: [{ required: true, message: 'Please enter taxAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.taxAmount.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Discount Amt"
                            >
                            {getFieldDecorator('discountAmt', {
                                rules: [{ required: true, message: 'Please enter discountAmt!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.discountAmt.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Add Or Less"
                            >
                            {getFieldDecorator('addOrLess', {
                                rules: [{ required: true, message: 'Please enter addOrLess!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.addOrLess.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Net Amount"
                            >
                            {getFieldDecorator('netAmount', {
                                rules: [{ required: true, message: 'Please enter netAmount!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.netAmount.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Update</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
              </Drawer>
              <Drawer
                title="View PurchaseOrder"
                placement="right"
                closable={false}
                onClose={this.onViewClose}
                visible={this.state.viewVisible}
                width={500}
              >
                    
                    <p>Supplier : {this.state.currentRow.supplierId ? <b>{this.state.suppliersData.find(supplier => supplier.supplierId.toString() === this.state.currentRow.supplierId.toString()).supplierName}</b> : 'Not Provided.'}</p>
                    <p>Agent : {this.state.currentRow.agentId ? <b>{this.state.agentsData.find(agent => agent.agentId.toString() === this.state.currentRow.agentId.toString()).agentName}</b> : 'Not Provided.'}</p>
                    <p>Purchase Account: {this.state.currentRow.purchaseAccountId ? <b>{this.state.currentRow.purchaseAccountId}</b> : 'Not Provided.'}</p>
                    <p>Invoice No : {this.state.currentRow.invoiceNum ? <b>{this.state.currentRow.invoiceNum}</b> : 'Not Provided.'}</p>
                    <p>Credit Type : {this.state.currentRow.creditTypeId ? <b>{this.state.currentRow.creditTypeId}</b> : 'Not Provided.'}</p>
                    <p>Credit Amount : {this.state.currentRow.creditAmount ? <b>{this.state.currentRow.creditAmount}</b> : 'Not Provided.'}</p>
                    <p>Tax Account: {this.state.currentRow.taxAccountId ? <b>{this.state.currentRow.taxAccountId}</b> : 'Not Provided.'}</p>
                    <p>Remarks : {this.state.currentRow.remarks ? <b>{this.state.currentRow.remarks}</b> : 'Not Provided.'}</p>
                    <p>Commision Amt : {this.state.currentRow.commisionAmt ? <b>{this.state.currentRow.commisionAmt}</b> : 'Not Provided.'}</p>
                    <p>Tax Percent : {this.state.currentRow.taxPercent ? <b>{this.state.currentRow.taxPercent}</b> : 'Not Provided.'}</p>
                    <p>Gross Amount : {this.state.currentRow.grossAmount ? <b>{this.state.currentRow.grossAmount}</b> : 'Not Provided.'}</p>
                    <p>Tax Amount : {this.state.currentRow.taxAmount ? <b>{this.state.currentRow.taxAmount}</b> : 'Not Provided.'}</p>
                    <p>Discount Amt : {this.state.currentRow.discountAmt ? <b>{this.state.currentRow.discountAmt}</b> : 'Not Provided.'}</p>
                    <p>AddOrLess : {this.state.currentRow.addOrLess ? <b>{this.state.currentRow.addOrLess}</b> : 'Not Provided.'}</p>
                    <p>Net Amount : {this.state.currentRow.netAmount ? <b>{this.state.currentRow.netAmount}</b> : 'Not Provided.'}</p>
                   
              </Drawer>
            </Row>
        </Col>
      </Row>
    );
  }
}
const WrappedPurchaseOrder = Form.create()(PurchaseOrder);
function mapStateToProps(state) {
  return {
    user: state.user,
    purchaseOrders: state.purchaseOrder,
    agents: state.agent,
    suppliers: state.supplier
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(Object.assign({}, purchaseOrderActions, supplierActions, agentActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedPurchaseOrder)