import purchaseOrderApi from '../../api/home/purchaseOrder'
import * as types from '../actionTypes'

export function getPurchaseOrders(token) {
    return function(dispatch) {
        dispatch(getPurchaseOrderPending());
        return purchaseOrderApi.getPurchaseOrders(token).then(purchaseOrders => {
            if(purchaseOrders.statusDTO.status === "SUCCESS") {
                dispatch(getPurchaseOrderSuccess(purchaseOrders));
            } else {
                dispatch(getPurchaseOrderFailed(purchaseOrders));
            }
        }).catch(error => {
           dispatch(getPurchaseOrderFailed(error));
        });
    };
}
export function getPurchaseOrderbyId(token) {
    return function(dispatch) {
        dispatch(getPurchaseOrderbyIdPending());
        return purchaseOrderApi.getPurchaseOrderbyID(token).then(purchaseOrders => {
            if(purchaseOrders.statusDTO.status === "SUCCESS") {
                dispatch(getPurchaseOrderbyIdSuccess(purchaseOrders));
            } else {
                dispatch(getPurchaseOrderbyIdFailed(purchaseOrders));
            }
        }).catch(error => {
           dispatch(getPurchaseOrderbyIdFailed(error));
        });
    };
}
export function createPurchaseOrder(purchaseOrderId, purchaseOrderData , token) {
    return function(dispatch) {
        dispatch(createPurchaseOrderPending());
        return purchaseOrderApi.createPurchaseOrder(purchaseOrderId, purchaseOrderData , token).then(purchaseOrders => {
            if(purchaseOrders.statusDTO.status === "SUCCESS") {
                dispatch(createPurchaseOrderSuccess(purchaseOrders));
            } else {
                dispatch(createPurchaseOrderFailed(purchaseOrders));
            }
        }).catch(error => {
            dispatch(createPurchaseOrderFailed(error));
        });
    };
}
export function updatePurchaseOrder(purchaseOrderData , token) {
    return function(dispatch) {
        dispatch(updatePurchaseOrderPending());
        return purchaseOrderApi.updatePurchaseOrder(purchaseOrderData , token).then(purchaseOrders => {
            if(purchaseOrders.statusDTO.status === "SUCCESS") {
                dispatch(updatePurchaseOrderSuccess(purchaseOrders));
            } else {
                dispatch(updatePurchaseOrderFailed(purchaseOrders));
            }
        }).catch(error => {
            dispatch(updatePurchaseOrderFailed(error));
        });
    };
}
export function deletePurchaseOrder(purchaseOrderData , token) {
    return function(dispatch) {
        dispatch(deletePurchaseOrderPending());
        return purchaseOrderApi.deletePurchaseOrder(purchaseOrderData , token).then(purchaseOrders => {
            if(purchaseOrders.statusDTO.status === "SUCCESS") {
                dispatch(deletePurchaseOrderSuccess(purchaseOrders));
            } else {
                dispatch(deletePurchaseOrderFailed(purchaseOrders));
            }
        }).catch(error => {
            dispatch(deletePurchaseOrderFailed(error));
        });
    };
}


export function getPurchaseOrderPending() {
    return {type: types.GET_PURCHASE_ORDERS_INITIATED};
}
export function getPurchaseOrderSuccess(purchaseOrders) {
    return {type: types.GET_PURCHASE_ORDERS_SUCCESS, purchaseOrders};
}
export function getPurchaseOrderFailed(error) {
    return {type: types.GET_PURCHASE_ORDERS_FAILED, error};
}
export function createPurchaseOrderPending() {
    return {type: types.CREATE_PURCHASE_ORDERS_INITIATED};
}
export function createPurchaseOrderSuccess(purchaseOrders) {
    return {type: types.CREATE_PURCHASE_ORDERS_SUCCESS, purchaseOrders};
}
export function createPurchaseOrderFailed(error) {
    return {type: types.CREATE_PURCHASE_ORDERS_FAILED, error};
}
export function updatePurchaseOrderPending() {
    return {type: types.UPDATE_PURCHASE_ORDERS_INITIATED};
}
export function updatePurchaseOrderSuccess(purchaseOrders) {
    return {type: types.UPDATE_PURCHASE_ORDERS_SUCCESS, purchaseOrders};
}
export function updatePurchaseOrderFailed(error) {
    return {type: types.UPDATE_PURCHASE_ORDERS_FAILED,error};
}
export function deletePurchaseOrderPending() {
    return {type: types.DELETE_PURCHASE_ORDERS_INITIATED};
}
export function deletePurchaseOrderSuccess(purchaseOrders) {
    return {type: types.DELETE_PURCHASE_ORDERS_SUCCESS, purchaseOrders};
}
export function deletePurchaseOrderFailed(error) {
    return {type: types.DELETE_PURCHASE_ORDERS_FAILED, error};
}
export function getPurchaseOrderbyIdPending() {
    return {type: types.GET_PURCHASE_ORDERS_BY_ID_INITIATED};
}
export function getPurchaseOrderbyIdSuccess(purchaseOrder) {
    return {type: types.GET_PURCHASE_ORDERS_BY_ID_SUCCESS, purchaseOrder};
}
export function getPurchaseOrderbyIdFailed(error) {
    return {type: types.GET_PURCHASE_ORDERS_BY_ID_FAILED, error};
}
