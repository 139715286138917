import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.checking, action) {
    switch (action.type) {
        case types.GET_CHECKING_INITIATED:
            return {
                ...state,
                getCheckingItemPending: true,
                    error: null,
                    getCheckingItemSuccess: false,
                    createCheckingItemSuccess: false,
                    deleteCheckingItemSuccess: false,
                    updateCheckingItemSuccess: false
            };
        case types.GET_CHECKING_FAILED:
            return {
                ...state,
                getCheckingItemPending: false,
                    error: action.error,
                    getCheckingItemSuccess: false,
                    createCheckingItemSuccess: false
            };
        case types.GET_CHECKING_SUCCESS:
            return {
                ...state,
                getCheckingItemPending: false,
                    getCheckingItemSuccess: true,
                    error: null,
                    data: action.checkingItems,
                    createCheckingItemSuccess: false
            };
        case types.CREATE_CHECKING_INITIATED:
            return {
                ...state,
                createCheckingItemPending: false,
                    error: null
            };
        case types.CREATE_CHECKING_FAILED:
            return {
                ...state,
                createCheckingItemPending: false,
                    error: action.error
            };
        case types.CREATE_CHECKING_SUCCESS:
            return {
                ...state,
                createCheckingItemPending: false,
                    createCheckingItemSuccess: true,
                    error: null
            };
        case types.UPDATE_CHECKING_INITIATED:
            return {
                ...state,
                updateCheckingItemPending: true,
                    error: null
            };
        case types.UPDATE_CHECKING_FAILED:
            return {
                ...state,
                updateCheckingItemPending: false,
                    error: action.error
            };
        case types.UPDATE_CHECKING_SUCCESS:
            return {
                ...state,
                updateCheckingItemPending: false,
                    updateCheckingItemSuccess: true,
                    createCheckingItemSuccess: false,
                    getCheckingItemPending: false,
                    error: null
            };
        case types.DELETE_CHECKING_INITIATED:
            return {
                ...state,
                deleteCheckingItemPending: true,
                    error: null
            };
        case types.DELETE_CHECKING_FAILED:
            return {
                ...state,
                deleteCheckingItemPending: false,
                    error: action.error
            };
        case types.DELETE_CHECKING_SUCCESS:
            return {
                ...state,
                deleteCheckingItemPending: false,
                    deleteCheckingItemSuccess: true,
                    createCheckingItemSuccess: false,
                    error: null
            };
        case types.GET_CHECKING_BY_ID_INITIATED:
            return {
                ...state,
                getCheckingItembyIdPending: true,
                    getCheckingItembyIDSuccess: false,
                    getCheckingItembyIdError: null,
                    getCheckingItemSuccess: false,
                    createCheckingItemSuccess: false,
                    deleteCheckingItemSuccess: false,
                    updateCheckingItemSuccess: false
            };
        case types.GET_CHECKING_BY_ID_FAILED:
            return {
                ...state,
                getCheckingItemPending: false,
                    getCheckingItembyIdPending: false,
                    getCheckingItembyIDSuccess: false,
                    getCheckingItembyIdError: action.error,
                    getCheckingItemSuccess: false,
                    createCheckingItemSuccess: false
            };
        case types.GET_CHECKING_BY_ID_SUCCESS:
            return {
                ...state,
                getCheckingItemPending: false,
                    getCheckingItemSuccess: false,
                    getCheckingItembyIdPending: true,
                    getCheckingItembyIDSuccess: action.checkingItem,
                    getCheckingItembyIdError: null,
                    createCheckingItemSuccess: false
            };
        default:
            return state;
    }
}
