import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Raven from 'raven-js';
import moment from 'moment';
import { alphanumericwithspace } from '../../modules/validator';
import { Timeline } from 'antd';
import { Radio } from 'antd';
import reduxStore from '../../store';
import SideBar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import { Row, Col, Table, Divider, Icon, Drawer,Form, Input, Modal, message, Breadcrumb, Button, Tooltip, notification, Select } from 'antd';
import * as manufacturingOrderActions from '../../actions/data/manufacturingOrder';
import * as partyActions from '../../actions/data/party';
import * as receiptActions from '../../actions/data/receipt';
import './manufacturingOrder.scss';

const history = reduxStore.history;
const confirm = Modal.confirm;
const FormItem = Form.Item;
const { TextArea } = Input;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};
class ManufacturingOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfPossibleItems : 5,
      visible: false,
      partysData: [],
      receiptsData: [],
      manufacturingOrdersData: [],
      createManufacturingOrderVisible: false,
      currentRow: {},
      editedmanufacturingId: '',
      manufacturingOrderdEdited: false,
      dropdownFilter: '',
      editClicked: false,
      updated: false,
    };
    this.onChange = this.onChange.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.createManufacturingOrderShowDrawer = this.createManufacturingOrderShowDrawer.bind(this);
    this.onCreateManufacturingOrderClose = this.onCreateManufacturingOrderClose.bind(this);
    this.handleCreateManufacturingOrder = this.handleCreateManufacturingOrder.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.handleUpdateManufacturingOrder = this.handleUpdateManufacturingOrder.bind(this);
    this.handleDropDownSearch = this.handleDropDownSearch.bind(this);
    this.showViewDrawer = this.showViewDrawer.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
  }
  componentDidMount() {
    document.title = "MRC Fabrics | ManufacturingOrders";
    const newMaxHeight = window.innerHeight - 330;
    const possibleRows = Math.ceil(newMaxHeight/54);
    if(possibleRows > this.state.numberOfPossibleItems) {
      this.setState({numberOfPossibleItems: possibleRows});
    }
    // if(this.props.user.loginSuccess) {
    //   message.success('Logged in successfully!', 3);
    // }
    let userToken = null;
    this.props.actions.getManufacturingOrders(userToken);
    this.props.actions.getParty(userToken);
    this.props.actions.getReceipts(userToken);
  }
  static getDerivedStateFromProps(nextProps, currentProps) {
    console.log( nextProps)
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    if(nextProps.partys && nextProps.partys.getPartySuccess) {
      nextProps.partys.getPartySuccess = false;
      return {
        partysData: nextProps.partys.data.listPartyDTO.map(data => {data.key = data.partyId; return data})
      }
    }
    if(nextProps.receipts && nextProps.receipts.getReceiptsSuccess) {
      nextProps.receipts.getReceiptsSuccess = false;
      return {
        receiptsData: nextProps.receipts.data.listReciptDTO.map(data => {data.key = data.receiptId; return data})
      }
    }
    if(nextProps.manufacturingOrders && nextProps.manufacturingOrders.getManufacturingOrdersSuccess) {
      nextProps.manufacturingOrders.getManufacturingOrdersSuccess = false;
      return {
        updated: true, 
        manufacturingOrdersData: nextProps.manufacturingOrders.data.listManufacturingDTO.map(data => {data.key = data.manufacturingId; return data})
      }
    }
    if(nextProps.manufacturingOrders && nextProps.manufacturingOrders.createManufacturingOrderSuccess && !nextProps.manufacturingOrders.createManufacturingOrderPending) {
      nextProps.manufacturingOrders.createManufacturingOrderSuccess = false;
      nextProps.actions.getManufacturingOrders(userToken);
      nextProps.form.resetFields();
      message.success('ManufacturingOrder created successfully!', 2);
      return {
        updated: false,
        currentRow: {},
        createManufacturingOrderVisible: false
      };
    }
     if(nextProps.manufacturingOrders && nextProps.manufacturingOrders.updateManufacturingOrderSuccess && !nextProps.manufacturingOrders.updateManufacturingOrderPending) {
      nextProps.manufacturingOrders.updateManufacturingOrderSuccess  = false;
      nextProps.actions.getManufacturingOrders(userToken);
      nextProps.form.resetFields();
      message.success('ManufacturingOrder edited successfully!', 2);
      return {
        visible: false,
        editClicked: false,
        currentRow: {},
        manufacturingOrderdEdited: true,
        updated: false
      };
  }
  if(nextProps.manufacturingOrders && nextProps.manufacturingOrders.deleteManufacturingOrderSuccess && !nextProps.manufacturingOrders.deleteManufacturingOrderPending) {
    nextProps.manufacturingOrders.deleteManufacturingOrderSuccess = false;  
    nextProps.actions.getManufacturingOrders(userToken);
    message.success('ManufacturingOrder deleted successfully!', 2);
    return {
      currentRow: '',
      updated: false
    };
    }
    if(nextProps.manufacturingOrders && nextProps.manufacturingOrders.error) {
      if(nextProps.manufacturingOrders.error.message)
        message.error(nextProps.manufacturingOrders.error.message,2);
        nextProps.manufacturingOrders.error = {};
      //   Raven.setExtraContext({
      //       props: this.props
      //   });
      // Raven.captureException(nextProps.manufacturingOrders.error.message, { extra: nextProps });
      if(nextProps.manufacturingOrders.error.message === "Failed to fetch") {
        openNotificationWithIcon("error", "It looks like you've lost your internet connection", "But you probably already knew that! This must be frustrating, Don't leave us, we track these things and resume automatically! Until then just relax!! ");           
      }
    }
  return null;
    
   
    
}
  handleDropDownSearch(dropdownFilter) {
    if(!dropdownFilter)
      dropdownFilter='';
    this.setState({dropdownFilter});
  }
  onTableRowClick(currentRow) {
    this.setState({currentRow,manufacturingOrderdEdited: false, editedmanufacturingId: ''});
  }
  showConfirm() {
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    const deleteManufacturingOrder = () => {
      this.props.actions.deleteManufacturingOrder(this.state.currentRow.manufacturingId, userToken);
    } 
    const resetFields = () => {
      this.setState({currentRow: ''});
    }
    confirm({
      title: 'Are you sure delete this ManufacturingOrder?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteManufacturingOrder();
      },
      onCancel() {
        resetFields();
        console.log('Cancel');
      },
    });
  }
  onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
  }  
  onViewClose (){
    this.setState({
      viewVisible: false,
      currentRow: ''
    });
  }
  showViewDrawer (){
    this.setState({
      viewVisible: true,
    });
  }
  createManufacturingOrderShowDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      createManufacturingOrderVisible: true,
    });
  };

  onCreateManufacturingOrderClose = () => {
    this.props.form.resetFields();
    this.setState({
      createManufacturingOrderVisible: false,
    });
  };
  handleCreateManufacturingOrder = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.partyId = parseInt(values.partyId,10);
        values.reciptId = parseInt(values.reciptId, 10);
        values.totalKgs = parseInt(values.totalKgs,10);
        values.totalBundles = parseFloat(values.totalBundles,10);
        this.props.actions.createManufacturingOrder(values, userToken);
      }
    });
  }
  handleUpdateManufacturingOrder = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.partyId = parseInt(values.partyId,10);
        values.reciptId = parseInt(values.reciptId, 10);
        values.totalKgs = parseInt(values.totalKgs,10);
        values.totalBundles = parseFloat(values.totalBundles,10);
        this.setState({editedmanufacturingId: this.state.currentRow.manufacturingId});
        this.props.actions.updateManufacturingOrder(this.state.currentRow.manufacturingId, values, userToken);
      }
    });
  }
  showDrawer (){
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editClicked: true
    });
  }
  onClose (){
    this.setState({
      visible: false,
      editClicked: false,
      currentRow: {}
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: 'Party',
      dataIndex: 'partyId',
      sorter: (a, b) => { return a.partyId.localeCompare(b.partyId)}
    }, {
      title: 'StockFrom',
      dataIndex: 'stockFrom',
      sorter: (a, b) => { return a.stockFrom.localeCompare(b.stockFrom)},
      render: (text, record) => {
        if(text && text.length > 50) {
          return text.substring(0,50)+'...';
        } else {
          return text;
        }
      }
    }, {
      title: 'JobDetails',
      dataIndex: 'jobDetails',
      sorter: (a, b) => { return a.jobDetails.localeCompare(b.jobDetails)}
    },
    {
      title: 'TotalKgs',
      dataIndex: 'totalKgs',
      sorter: (a, b) => { return a.totalKgs.localeCompare(b.totalKgs)},
    },
    {
      title: 'Action',
      key: 'action',
      width: 240,
      render: (text, record) => (
        <span>
         <Tooltip title="View ManufacturingOrder">
            <span onClick={this.showViewDrawer} className="cursor-pointer"><Icon type="eye" /> View</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Edit ManufacturingOrder">
            <span onClick={this.showDrawer} className="cursor-pointer"><Icon type="edit" /> Edit</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Delete ManufacturingOrder">
            <span  onClick={this.showConfirm} className="cursor-pointer"><Icon type="delete" /> Delete</span>
          </Tooltip>
          {record.idmanufacturingOrder === this.state.editedmanufacturingId && this.state.manufacturingOrderdEdited ? <span className={"success"} style={{fontSize: 11,paddingLeft: 10,color: 'green'}}>updated!</span> : null}
        </span>
      )
    }];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return(
      <Row>
        <Col 
          xs={6} 
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className="height-100">
          <SideBar changePath={history.push} {...history}/>
        </Col>
        <Col
          xs={18} 
          sm={18}
          md={20}
          lg={20}
          xl={20} className="height-100-55 dashboard">
            <Row>
              <Header />
              <Row className="contents-main">
                    <div className="breadcrumb-div">
                    <Breadcrumb>
                      <Breadcrumb.Item>Home</Breadcrumb.Item>
                      <Breadcrumb.Item>ManufacturingOrder</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="add-manufacturingOrder-button">
                        <Button type="primary" className="login-form-button" style = {{
                          width: 220,
                          float: 'right',
                          marginTop: -30
                        }}onClick={this.createManufacturingOrderShowDrawer}>
                        <Icon type="plus" /> Add Manufacturing Order
                        </Button>
                    </div>
                    </div>
                    <div className="main-table-div">
                      <Table 
                        className="main-table" 
                        columns={columns} 
                        dataSource={this.state.manufacturingOrdersData} 
                        pagination={{pageSize:this.state.numberOfPossibleItems}} 
                        loading={this.state.manufacturingOrdersData.length === 0&& !this.state.updated}
                        locale={{ emptyText: 'No manufacturingOrders added!' }}
                        onChange={this.onChange} onRow={(record, index) => ({
                          onClick: (event) => { this.onTableRowClick(record); } 
                      })}/>
                    </div>
                </Row>
                <Drawer
                  title="Add ManufacturingOrder"
                  placement="right"
                  closable={false}
                  onClose={this.onCreateManufacturingOrderClose}
                  visible={this.state.createManufacturingOrderVisible}
                  width={450}
                >
                  <Form onSubmit={this.handleCreateManufacturingOrder} className="manufacturingOrder-create" id="createManufacturingOrder">
                        <FormItem
                            {...formItemLayout}
                            label="Party"
                            >
                            {getFieldDecorator('partyId', {
                                rules: [{ required: true, message: 'Please select party!'}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.partysData.length)? 
                                  this.state.partysData.map(party => 
                                  <Option value={party.partyId.toString()} key={'party'+party.partyId}>{party.partyName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Receipt"
                            >
                            {getFieldDecorator('receiptId', {
                                rules: [{ required: true, message: 'Please select receipt!'}],
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.receiptsData.length)? 
                                  this.state.receiptsData.map(receipt => 
                                  <Option value={receipt.reciptId.toString()} key={'receipt'+receipt.reciptId}>{receipt.reciptId}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Stock From"
                            >
                            {getFieldDecorator('stockFrom', {
                                rules: [{ required: true, message: 'Please enter Stock From!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Job Details"
                            >
                            {getFieldDecorator('jobDetails', {
                                rules: [{ required: true, message: 'Please enter jobDetails!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Items"
                            >
                            {getFieldDecorator('itemsForManufacturing', {
                                rules: [{ required: true, message: 'Please enter itemsForManufacturing!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="TotalKgs"
                            >
                            {getFieldDecorator('totalKgs', {
                                rules: [{ required: true, message: 'Please enter totalKgs', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Total Bundles"
                            >
                            {getFieldDecorator('totalBundles', {
                                rules: [{ required: true, message: 'Please enter totalBundles!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Manufacturing Status"
                            >
                            {getFieldDecorator('ManufacturingStatus', {
                                rules: [{ required: true, message: 'Please enter ManufacturingStatus!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Create</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onCreateManufacturingOrderClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
                </Drawer>
            <Drawer
                title="Edit ManufacturingOrder"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                width={450}
              >
              <Form onSubmit={this.handleUpdateManufacturingOrder} className="manufacturingOrder-create" id="updateManufacturingOrder">
                        <FormItem
                            {...formItemLayout}
                            label="Supplier"
                            >
                            {getFieldDecorator('partyId', {
                                rules: [{ required: true, message: 'Please select supplier!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.partyId : null
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.partysData.length)? 
                                  this.state.partysData.map(party => 
                                  <Option value={party.partyId.toString()} key={'party'+party.partyId}>{party.partyName}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                              <FormItem
                            {...formItemLayout}
                            label="Receipt"
                            >
                            {getFieldDecorator('receiptId', {
                                rules: [{ required: true, message: 'Please select receipt ID!'}],
                                initialValue: this.state.editClicked ? this.state.currentRow.reciptId : null
                            })(
                              <Select 
                                style={{ width: "100%" }}
                              >
                                {(this.state.receiptsData.length)? 
                                  this.state.receiptsData.map(receipt => 
                                  <Option value={receipt.reciptId.toString()} key={'receipt'+receipt.reciptId}>{receipt.reciptId}</Option>
                                  ): ''}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Stock From"
                            >
                            {getFieldDecorator('stockFrom', {
                                rules: [{ required: true, message: 'Please enter stockFrom', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.stockFrom : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Job Details"
                            >
                            {getFieldDecorator('jobDetails', {
                                rules: [{ required: true, message: 'Please enter jobDetails!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.jobDetails : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Items"
                            >
                            {getFieldDecorator('itemsForManufacturing', {
                                rules: [{ required: true, message: 'Please enter manufacturingOrder itemsForManufacturing!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.itemsForManufacturing : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="TotalKgs"
                            >
                            {getFieldDecorator('totalKgs', {
                                rules: [{ required: true, message: 'Please enter totalKgs!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.totalKgs : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Total Bundles"
                            >
                            {getFieldDecorator('totalBundles', {
                                rules: [{ required: true, message: 'Please enter totalBundles!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.totalBundles : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Manufacturing Status"
                            >
                            {getFieldDecorator('ManufacturingStatus', {
                                rules: [{ required: true, message: 'Please enter ManufacturingStatus!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.ManufacturingStatus : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Update</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
              </Drawer>
              <Drawer
                title="View ManufacturingOrder"
                placement="right"
                closable={false}
                onClose={this.onViewClose}
                visible={this.state.viewVisible}
                width={500}
              >
                    
                    <p>Party : {this.state.currentRow.partyId ? <b>{this.state.currentRow.partyId}</b> : 'Not Provided.'}</p>
                    <p>Stock From : {this.state.currentRow.stockFrom ? <b>{this.state.currentRow.stockFrom}</b> : 'Not Provided.'}</p>
                    <p>Recipt: {this.state.currentRow.reciptId ? <b>{this.state.currentRow.reciptId}</b> : 'Not Provided.'}</p>
                    <p>Job Details : {this.state.currentRow.jobDetails ? <b>{this.state.currentRow.jobDetails}</b> : 'Not Provided.'}</p>
                    <p>Items : {this.state.currentRow.itemsForManufacturing ? <b>{this.state.currentRow.itemsForManufacturing}</b> : 'Not Provided.'}</p>
                    <p>Total Kgs : {this.state.currentRow.totalKgs ? <b>{this.state.currentRow.totalKgs}</b> : 'Not Provided.'}</p>
                    <p>Total Bundles: {this.state.currentRow.totalBundles ? <b>{this.state.currentRow.totalBundles}</b> : 'Not Provided.'}</p>
                    <p>Manufacturing Status : {this.state.currentRow.ManufacturingStatus ? <b>{this.state.currentRow.ManufacturingStatus}</b> : 'Not Provided.'}</p>
                    
              </Drawer>
            </Row>
        </Col>
      </Row>
    );
  }
}
const WrappedManufacturingOrder = Form.create()(ManufacturingOrder);
function mapStateToProps(state) {
  return {
    user: state.user,
    manufacturingOrders: state.manufacturingOrder,
    partys: state.party,
    receipts: state.receipt
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(Object.assign({}, manufacturingOrderActions, partyActions, receiptActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedManufacturingOrder)