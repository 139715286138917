export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_INVALID_CREDS = 'LOGIN_INVALID_CREDS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const REQUEST_USERPROFILE_INITIATED = 'REQUEST_USERPROFILE_INITIATED';
export const REQUEST_USERPROFILE_SUCCESS = 'REQUEST_USERPROFILE_SUCCESS';
export const REQUEST_USERPROFILE_FAILED = 'REQUEST_USERPROFILE_FAILED';
export const UPDATE_USERPROFILE_INITIATED = 'UPDATE_USERPROFILE_INITIATED';
export const UPDATE_USERPROFILE_SUCCESS = 'UPDATE_USERPROFILE_SUCCESS';
export const UPDATE_USERPROFILE_FAILED = 'UPDATE_USERPROFILE_FAILED';
export const LOGOUT_INITIATED = 'LOGOUT_INITIATED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED= 'LOGOUT_FAILED';
export const RESET_PASSWORD_INITIATED = 'RESET_PASSWORD_INITIATED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const SET_PASSWORD_INITIATED = 'SET_PASSWORD_INITIATED';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILED = 'SET_PASSWORD_FAILED';
export const GET_SUPPLIERS_INITIATED = 'GET_SUPPLIERS_INITIATED';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_FAILED = 'GET_SUPPLIERS_FAILED';
export const GET_SUPPLIERS_BY_ID_INITIATED = 'GET_SUPPLIERS_BY_ID_INITIATED';
export const GET_SUPPLIERS_BY_ID_SUCCESS = 'GET_SUPPLIERS_BY_ID_SUCCESS';
export const GET_SUPPLIERS_BY_ID_FAILED = 'GET_SUPPLIERS_BY_ID_FAILED';
export const CREATE_SUPPLIERS_INITIATED = 'CREATE_SUPPLIERS_INITIATED';
export const CREATE_SUPPLIERS_SUCCESS = 'CREATE_SUPPLIERS_SUCCESS';
export const CREATE_SUPPLIERS_FAILED = 'CREATE_SUPPLIERS_FAILED';
export const UPDATE_SUPPLIERS_INITIATED = 'UPDATE_SUPPLIERS_INITIATED';
export const UPDATE_SUPPLIERS_SUCCESS = 'UPDATE_SUPPLIERS_SUCCESS';
export const UPDATE_SUPPLIERS_FAILED = 'UPDATE_SUPPLIERS_FAILED';
export const DELETE_SUPPLIERS_INITIATED = 'DELETE_SUPPLIERS_INITIATED';
export const DELETE_SUPPLIERS_SUCCESS = 'DELETE_SUPPLIERS_SUCCESS';
export const DELETE_SUPPLIERS_FAILED = 'DELETE_SUPPLIERS_FAILED';
export const GET_AGENTS_INITIATED = 'GET_AGENTS_INITIATED';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_AGENTS_FAILED = 'GET_AGENTS_FAILED';
export const GET_AGENTS_BY_ID_INITIATED = 'GET_AGENTS_BY_ID_INITIATED';
export const GET_AGENTS_BY_ID_SUCCESS = 'GET_AGENTS_BY_ID_SUCCESS';
export const GET_AGENTS_BY_ID_FAILED = 'GET_AGENTS_BY_ID_FAILED';
export const CREATE_AGENTS_INITIATED = 'CREATE_AGENTS_INITIATED';
export const CREATE_AGENTS_SUCCESS = 'CREATE_AGENTS_SUCCESS';
export const CREATE_AGENTS_FAILED = 'CREATE_AGENTS_FAILED';
export const UPDATE_AGENTS_INITIATED = 'UPDATE_AGENTS_INITIATED';
export const UPDATE_AGENTS_SUCCESS = 'UPDATE_AGENTS_SUCCESS';
export const UPDATE_AGENTS_FAILED = 'UPDATE_AGENTS_FAILED';
export const DELETE_AGENTS_INITIATED = 'DELETE_AGENTS_INITIATED';
export const DELETE_AGENTS_SUCCESS = 'DELETE_AGENTS_SUCCESS';
export const DELETE_AGENTS_FAILED = 'DELETE_AGENTS_FAILED';
export const GET_PARTY_INITIATED = 'GET_PARTY_INITIATED';
export const GET_PARTY_SUCCESS = 'GET_PARTY_SUCCESS';
export const GET_PARTY_FAILED = 'GET_PARTY_FAILED';
export const GET_PARTY_BY_ID_INITIATED = 'GET_PARTY_BY_ID_INITIATED';
export const GET_PARTY_BY_ID_SUCCESS = 'GET_PARTY_BY_ID_SUCCESS';
export const GET_PARTY_BY_ID_FAILED = 'GET_PARTY_BY_ID_FAILED';
export const CREATE_PARTY_INITIATED = 'CREATE_PARTY_INITIATED';
export const CREATE_PARTY_SUCCESS = 'CREATE_PARTY_SUCCESS';
export const CREATE_PARTY_FAILED = 'CREATE_PARTY_FAILED';
export const UPDATE_PARTY_INITIATED = 'UPDATE_PARTY_INITIATED';
export const UPDATE_PARTY_SUCCESS = 'UPDATE_PARTY_SUCCESS';
export const UPDATE_PARTY_FAILED = 'UPDATE_PARTY_FAILED';
export const DELETE_PARTY_INITIATED = 'DELETE_PARTY_INITIATED';
export const DELETE_PARTY_SUCCESS = 'DELETE_PARTY_SUCCESS';
export const DELETE_PARTY_FAILED = 'DELETE_PARTY_FAILED';
export const GET_PURCHASE_ORDERS_INITIATED = 'GET_PURCHASE_ORDERS_INITIATED';
export const GET_PURCHASE_ORDERS_SUCCESS = 'GET_PURCHASE_ORDERS_SUCCESS';
export const GET_PURCHASE_ORDERS_FAILED = 'GET_PURCHASE_ORDERS_FAILED';
export const GET_PURCHASE_ORDERS_BY_ID_INITIATED = 'GET_PURCHASE_ORDERS_BY_ID_INITIATED';
export const GET_PURCHASE_ORDERS_BY_ID_SUCCESS = 'GET_PURCHASE_ORDERS_BY_ID_SUCCESS';
export const GET_PURCHASE_ORDERS_BY_ID_FAILED = 'GET_PURCHASE_ORDERS_BY_ID_FAILED';
export const CREATE_PURCHASE_ORDERS_INITIATED = 'CREATE_PURCHASE_ORDERS_INITIATED';
export const CREATE_PURCHASE_ORDERS_SUCCESS = 'CREATE_PURCHASE_ORDERS_SUCCESS';
export const CREATE_PURCHASE_ORDERS_FAILED = 'CREATE_PURCHASE_ORDERS_FAILED';
export const UPDATE_PURCHASE_ORDERS_INITIATED = 'UPDATE_PURCHASE_ORDERS_INITIATED';
export const UPDATE_PURCHASE_ORDERS_SUCCESS = 'UPDATE_PURCHASE_ORDERS_SUCCESS';
export const UPDATE_PURCHASE_ORDERS_FAILED = 'UPDATE_PURCHASE_ORDERS_FAILED';
export const DELETE_PURCHASE_ORDERS_INITIATED = 'DELETE_PURCHASE_ORDERS_INITIATED';
export const DELETE_PURCHASE_ORDERS_SUCCESS = 'DELETE_PURCHASE_ORDERS_SUCCESS';
export const DELETE_PURCHASE_ORDERS_FAILED = 'DELETE_PURCHASE_ORDERS_FAILED';
export const GET_MANUFACTURING_ORDERS_INITIATED = 'GET_MANUFACTURING_ORDERS_INITIATED';
export const GET_MANUFACTURING_ORDERS_SUCCESS = 'GET_MANUFACTURING_ORDERS_SUCCESS';
export const GET_MANUFACTURING_ORDERS_FAILED = 'GET_MANUFACTURING_ORDERS_FAILED';
export const GET_MANUFACTURING_ORDERS_BY_ID_INITIATED = 'GET_MANUFACTURING_ORDERS_BY_ID_INITIATED';
export const GET_MANUFACTURING_ORDERS_BY_ID_SUCCESS = 'GET_MANUFACTURING_ORDERS_BY_ID_SUCCESS';
export const GET_MANUFACTURING_ORDERS_BY_ID_FAILED = 'GET_MANUFACTURING_ORDERS_BY_ID_FAILED';
export const CREATE_MANUFACTURING_ORDERS_INITIATED = 'CREATE_MANUFACTURING_ORDERS_INITIATED';
export const CREATE_MANUFACTURING_ORDERS_SUCCESS = 'CREATE_MANUFACTURING_ORDERS_SUCCESS';
export const CREATE_MANUFACTURING_ORDERS_FAILED = 'CREATE_MANUFACTURING_ORDERS_FAILED';
export const UPDATE_MANUFACTURING_ORDERS_INITIATED = 'UPDATE_MANUFACTURING_ORDERS_INITIATED';
export const UPDATE_MANUFACTURING_ORDERS_SUCCESS = 'UPDATE_MANUFACTURING_ORDERS_SUCCESS';
export const UPDATE_MANUFACTURING_ORDERS_FAILED = 'UPDATE_MANUFACTURING_ORDERS_FAILED';
export const DELETE_MANUFACTURING_ORDERS_INITIATED = 'DELETE_MANUFACTURING_ORDERS_INITIATED';
export const DELETE_MANUFACTURING_ORDERS_SUCCESS = 'DELETE_MANUFACTURING_ORDERS_SUCCESS';
export const DELETE_MANUFACTURING_ORDERS_FAILED = 'DELETE_MANUFACTURING_ORDERS_FAILED';
export const GET_PROCESSING_ORDERS_INITIATED = 'GET_PROCESSING_ORDERS_INITIATED';
export const GET_PROCESSING_ORDERS_SUCCESS = 'GET_PROCESSING_ORDERS_SUCCESS';
export const GET_PROCESSING_ORDERS_FAILED = 'GET_PROCESSING_ORDERS_FAILED';
export const GET_PROCESSING_ORDERS_BY_ID_INITIATED = 'GET_PROCESSING_ORDERS_BY_ID_INITIATED';
export const GET_PROCESSING_ORDERS_BY_ID_SUCCESS = 'GET_PROCESSING_ORDERS_BY_ID_SUCCESS';
export const GET_PROCESSING_ORDERS_BY_ID_FAILED = 'GET_PROCESSING_ORDERS_BY_ID_FAILED';
export const CREATE_PROCESSING_ORDERS_INITIATED = 'CREATE_PROCESSING_ORDERS_INITIATED';
export const CREATE_PROCESSING_ORDERS_SUCCESS = 'CREATE_PROCESSING_ORDERS_SUCCESS';
export const CREATE_PROCESSING_ORDERS_FAILED = 'CREATE_PROCESSING_ORDERS_FAILED';
export const UPDATE_PROCESSING_ORDERS_INITIATED = 'UPDATE_PROCESSING_ORDERS_INITIATED';
export const UPDATE_PROCESSING_ORDERS_SUCCESS = 'UPDATE_PROCESSING_ORDERS_SUCCESS';
export const UPDATE_PROCESSING_ORDERS_FAILED = 'UPDATE_PROCESSING_ORDERS_FAILED';
export const DELETE_PROCESSING_ORDERS_INITIATED = 'DELETE_PROCESSING_ORDERS_INITIATED';
export const DELETE_PROCESSING_ORDERS_SUCCESS = 'DELETE_PROCESSING_ORDERS_SUCCESS';
export const DELETE_PROCESSING_ORDERS_FAILED = 'DELETE_PROCESSING_ORDERS_FAILED';
export const GET_ORDER_ITEMS_INITIATED = 'GET_ORDER_ITEMS_INITIATED';
export const GET_ORDER_ITEMS_SUCCESS = 'GET_ORDER_ITEMS_SUCCESS';
export const GET_ORDER_ITEMS_FAILED = 'GET_ORDER_ITEMS_FAILED';
export const GET_ORDER_ITEMS_BY_ID_INITIATED = 'GET_ORDER_ITEMS_BY_ID_INITIATED';
export const GET_ORDER_ITEMS_BY_ID_SUCCESS = 'GET_ORDER_ITEMS_BY_ID_SUCCESS';
export const GET_ORDER_ITEMS_BY_ID_FAILED = 'GET_ORDER_ITEMS_BY_ID_FAILED';
export const CREATE_ORDER_ITEMS_INITIATED = 'CREATE_ORDER_ITEMS_INITIATED';
export const CREATE_ORDER_ITEMS_SUCCESS = 'CREATE_ORDER_ITEMS_SUCCESS';
export const CREATE_ORDER_ITEMS_FAILED = 'CREATE_ORDER_ITEMS_FAILED';
export const UPDATE_ORDER_ITEMS_INITIATED = 'UPDATE_ORDER_ITEMS_INITIATED';
export const UPDATE_ORDER_ITEMS_SUCCESS = 'UPDATE_ORDER_ITEMS_SUCCESS';
export const UPDATE_ORDER_ITEMS_FAILED = 'UPDATE_ORDER_ITEMS_FAILED';
export const DELETE_ORDER_ITEMS_INITIATED = 'DELETE_ORDER_ITEMS_INITIATED';
export const DELETE_ORDER_ITEMS_SUCCESS = 'DELETE_ORDER_ITEMS_SUCCESS';
export const DELETE_ORDER_ITEMS_FAILED = 'DELETE_ORDER_ITEMS_FAILED';
export const GET_RECEIPT_INITIATED = 'GET_RECEIPT_INITIATED';
export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS';
export const GET_RECEIPT_FAILED = 'GET_RECEIPT_FAILED';
export const GET_RECEIPT_BY_ID_INITIATED = 'GET_RECEIPT_BY_ID_INITIATED';
export const GET_RECEIPT_BY_ID_SUCCESS = 'GET_RECEIPT_BY_ID_SUCCESS';
export const GET_RECEIPT_BY_ID_FAILED = 'GET_RECEIPT_BY_ID_FAILED';
export const CREATE_RECEIPT_INITIATED = 'CREATE_RECEIPT_INITIATED';
export const CREATE_RECEIPT_SUCCESS = 'CREATE_RECEIPT_SUCCESS';
export const CREATE_RECEIPT_FAILED = 'CREATE_RECEIPT_FAILED';
export const UPDATE_RECEIPT_INITIATED = 'UPDATE_RECEIPT_INITIATED';
export const UPDATE_RECEIPT_SUCCESS = 'UPDATE_RECEIPT_SUCCESS';
export const UPDATE_RECEIPT_FAILED = 'UPDATE_RECEIPT_FAILED';
export const DELETE_RECEIPT_INITIATED = 'DELETE_RECEIPT_INITIATED';
export const DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS';
export const DELETE_RECEIPT_FAILED = 'DELETE_RECEIPT_FAILED';
export const GET_CHECKING_INITIATED = 'GET_CHECKING_INITIATED';
export const GET_CHECKING_SUCCESS = 'GET_CHECKING_SUCCESS';
export const GET_CHECKING_FAILED = 'GET_CHECKING_FAILED';
export const GET_CHECKING_BY_ID_INITIATED = 'GET_CHECKING_BY_ID_INITIATED';
export const GET_CHECKING_BY_ID_SUCCESS = 'GET_CHECKING_BY_ID_SUCCESS';
export const GET_CHECKING_BY_ID_FAILED = 'GET_CHECKING_BY_ID_FAILED';
export const CREATE_CHECKING_INITIATED = 'CREATE_CHECKING_INITIATED';
export const CREATE_CHECKING_SUCCESS = 'CREATE_CHECKING_SUCCESS';
export const CREATE_CHECKING_FAILED = 'CREATE_CHECKING_FAILED';
export const UPDATE_CHECKING_INITIATED = 'UPDATE_CHECKING_INITIATED';
export const UPDATE_CHECKING_SUCCESS = 'UPDATE_CHECKING_SUCCESS';
export const UPDATE_CHECKING_FAILED = 'UPDATE_CHECKING_FAILED';
export const DELETE_CHECKING_INITIATED = 'DELETE_CHECKING_INITIATED';
export const DELETE_CHECKING_SUCCESS = 'DELETE_CHECKING_SUCCESS';
export const DELETE_CHECKING_FAILED = 'DELETE_CHECKING_FAILED';