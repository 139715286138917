import receiptApi from '../../api/home/receipt'
import * as types from '../actionTypes'

export function getReceipts(token) {
    return function(dispatch) {
        dispatch(getReceiptPending());
        return receiptApi.getReceipts(token).then(receipts => {
            if(receipts.statusDTO.status === "SUCCESS") {
                dispatch(getReceiptSuccess(receipts));
            } else {
                dispatch(getReceiptFailed(receipts));
            }
        }).catch(error => {
           dispatch(getReceiptFailed(error));
        });
    };
}
export function getReceiptbyId(token) {
    return function(dispatch) {
        dispatch(getReceiptbyIdPending());
        return receiptApi.getReceiptbyID(token).then(receipts => {
            if(receipts.statusDTO.status === "SUCCESS") {
                dispatch(getReceiptbyIdSuccess(receipts));
            } else {
                dispatch(getReceiptbyIdFailed(receipts));
            }
        }).catch(error => {
           dispatch(getReceiptbyIdFailed(error));
        });
    };
}
export function createReceipt(receiptId, receiptData , token) {
    return function(dispatch) {
        dispatch(createReceiptPending());
        return receiptApi.createReceipt(receiptId, receiptData , token).then(receipts => {
            if(receipts.statusDTO.status === "SUCCESS") {
                dispatch(createReceiptSuccess(receipts));
            } else {
                dispatch(createReceiptFailed(receipts));
            }
        }).catch(error => {
            dispatch(createReceiptFailed(error));
        });
    };
}
export function updateReceipt(receiptData , token) {
    return function(dispatch) {
        dispatch(updateReceiptPending());
        return receiptApi.updateReceipt(receiptData , token).then(receipts => {
            if(receipts.statusDTO.status === "SUCCESS") {
                dispatch(updateReceiptSuccess(receipts));
            } else {
                dispatch(updateReceiptFailed(receipts));
            }
        }).catch(error => {
            dispatch(updateReceiptFailed(error));
        });
    };
}
export function deleteReceipt(receiptData , token) {
    return function(dispatch) {
        dispatch(deleteReceiptPending());
        return receiptApi.deleteReceipt(receiptData , token).then(receipts => {
            if(receipts.statusDTO.status === "SUCCESS") {
                dispatch(deleteReceiptSuccess(receipts));
            } else {
                dispatch(deleteReceiptFailed(receipts));
            }
        }).catch(error => {
            dispatch(deleteReceiptFailed(error));
        });
    };
}


export function getReceiptPending() {
    return {type: types.GET_RECEIPT_INITIATED};
}
export function getReceiptSuccess(receipts) {
    return {type: types.GET_RECEIPT_SUCCESS, receipts};
}
export function getReceiptFailed(error) {
    return {type: types.GET_RECEIPT_FAILED, error};
}
export function createReceiptPending() {
    return {type: types.CREATE_RECEIPT_INITIATED};
}
export function createReceiptSuccess(receipts) {
    return {type: types.CREATE_RECEIPT_SUCCESS, receipts};
}
export function createReceiptFailed(error) {
    return {type: types.CREATE_RECEIPT_FAILED, error};
}
export function updateReceiptPending() {
    return {type: types.UPDATE_RECEIPT_INITIATED};
}
export function updateReceiptSuccess(receipts) {
    return {type: types.UPDATE_RECEIPT_SUCCESS, receipts};
}
export function updateReceiptFailed(error) {
    return {type: types.UPDATE_RECEIPT_FAILED,error};
}
export function deleteReceiptPending() {
    return {type: types.DELETE_RECEIPT_INITIATED};
}
export function deleteReceiptSuccess(receipts) {
    return {type: types.DELETE_RECEIPT_SUCCESS, receipts};
}
export function deleteReceiptFailed(error) {
    return {type: types.DELETE_RECEIPT_FAILED, error};
}
export function getReceiptbyIdPending() {
    return {type: types.GET_RECEIPT_BY_ID_INITIATED};
}
export function getReceiptbyIdSuccess(receipt) {
    return {type: types.GET_RECEIPT_BY_ID_SUCCESS, receipt};
}
export function getReceiptbyIdFailed(error) {
    return {type: types.GET_RECEIPT_BY_ID_FAILED, error};
}
