import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import Raven from 'raven-js';
// import moment from 'moment';
import { alphanumericwithspace, mobilenumber, website, pincode, emailaddress } from '../../modules/validator';
// import { Timeline } from 'antd';
import { Radio } from 'antd';
import reduxStore from '../../store';
import SideBar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import { Row, Col, Table, Divider, Icon, Drawer,Form, Input, Modal, message, Breadcrumb, Button, Tooltip, notification, Select } from 'antd';
import * as agentActions from '../../actions/data/agent';
import './agent.scss';
import states from '../../config/states.json';

const history = reduxStore.history;
const confirm = Modal.confirm;
const FormItem = Form.Item;
// const { TextArea } = Input;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};
class Agent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfPossibleItems : 5,
      visible: false,
      agentsData: [],
      createAgentVisible: false,
      currentRow: {},
      editedAgentId: '',
      agentdEdited: false,
      dropdownFilter: '',
      editClicked: false,
      updated: false,
    };
    this.onChange = this.onChange.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.createAgentShowDrawer = this.createAgentShowDrawer.bind(this);
    this.onCreateAgentClose = this.onCreateAgentClose.bind(this);
    this.handleCreateAgent = this.handleCreateAgent.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.handleUpdateAgent = this.handleUpdateAgent.bind(this);
    this.handleDropDownSearch = this.handleDropDownSearch.bind(this);
    this.showViewDrawer = this.showViewDrawer.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
  }
  componentDidMount() {
    document.title = "MRC Fabrics | Agents";
    const newMaxHeight = window.innerHeight - 330;
    const possibleRows = Math.ceil(newMaxHeight/54);
    if(possibleRows > this.state.numberOfPossibleItems) {
      this.setState({numberOfPossibleItems: possibleRows});
    }
    // if(this.props.user.loginSuccess) {
    //   message.success('Logged in successfully!', 3);
    // }
    let userToken = null;
    this.props.actions.getAgents(userToken);
  }
  static getDerivedStateFromProps(nextProps) {
    console.log( nextProps)
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    if(nextProps.agents && nextProps.agents.getAgentsSuccess) {
      nextProps.agents.getAgentsSuccess = false;
      return {
        updated: true, 
        agentsData: nextProps.agents.data.listAgentDTO.map(data => {data.key = data.agentId; return data})
      }
    }
    if(nextProps.agents && nextProps.agents.createAgentSuccess && !nextProps.agents.createAgentPending) {
      nextProps.agents.createAgentSuccess = false;
      nextProps.actions.getAgents(userToken);
      nextProps.form.resetFields();
      message.success('Agent created successfully!', 2);
      return {
        updated: false,
        currentRow: {},
        createAgentVisible: false
      };
    }
     if(nextProps.agents && nextProps.agents.updateAgentSuccess && !nextProps.agents.updateAgentPending) {
      nextProps.agents.updateAgentSuccess  = false;
      nextProps.actions.getAgents(userToken);
      nextProps.form.resetFields();
      message.success('Agent edited successfully!', 2);
      return {
        visible: false,
        editClicked: false,
        currentRow: {},
        agentdEdited: true,
        updated: false
      };
  }
  if(nextProps.agents && nextProps.agents.deleteAgentSuccess && !nextProps.agents.deleteAgentPending) {
    nextProps.agents.deleteAgentSuccess = false;  
    nextProps.actions.getAgents(userToken);
    message.success('Agent deleted successfully!', 2);
    return {
      currentRow: '',
      updated: false
    };
    }
    if(nextProps.agents && nextProps.agents.error) {
      if(nextProps.agents.error.message)
        message.error(nextProps.agents.error.message,2);
        nextProps.agents.error = {};
      //   Raven.setExtraContext({
      //       props: this.props
      //   });
      // Raven.captureException(nextProps.agents.error.message, { extra: nextProps });
      if(nextProps.agents.error.message === "Failed to fetch") {
        openNotificationWithIcon("error", "It looks like you've lost your internet connection", "But you probably already knew that! This must be frustrating, Don't leave us, we track these things and resume automatically! Until then just relax!! ");           
      }
    }
  return null;
    
   
    
}
  handleDropDownSearch(dropdownFilter) {
    if(!dropdownFilter)
      dropdownFilter='';
    this.setState({dropdownFilter});
  }
  onTableRowClick(currentRow) {
    this.setState({currentRow,agentdEdited: false, editedAgentId: ''});
  }
  showConfirm() {
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    const deleteAgent = () => {
      this.props.actions.deleteAgent(this.state.currentRow.agentId, userToken);
    } 
    const resetFields = () => {
      this.setState({currentRow: ''});
    }
    confirm({
      title: 'Are you sure delete this Agent?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAgent();
      },
      onCancel() {
        resetFields();
        console.log('Cancel');
      },
    });
  }
  onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
  }  
  onViewClose (){
    this.setState({
      viewVisible: false,
      currentRow: ''
    });
  }
  showViewDrawer (){
    this.setState({
      viewVisible: true,
    });
  }
  createAgentShowDrawer = () => {
    this.props.form.resetFields();
    this.setState({
      createAgentVisible: true,
    });
  };

  onCreateAgentClose = () => {
    this.props.form.resetFields();
    this.setState({
      createAgentVisible: false,
    });
  };
  handleCreateAgent = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.premium = values.premium === "true";
        this.props.actions.createAgent(values, userToken);
      }
    });
  }
  handleUpdateAgent = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        values.premium = values.premium === "true";
        this.setState({editedAgentId: this.state.currentRow.agentId});
        this.props.actions.updateAgent(this.state.currentRow.agentId, values, userToken);
      }
    });
  }
  showDrawer (){
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editClicked: true
    });
  }
  onClose (){
    this.setState({
      visible: false,
      editClicked: false,
      currentRow: {}
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: 'Name',
      dataIndex: 'agentName',
      sorter: (a, b) => { return a.agentName.localeCompare(b.agentName)}
    }, {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => { return a.email.localeCompare(b.email)},
      render: (text) => {
        if(text && text.length > 50) {
          return text.substring(0,50)+'...';
        } else {
          return text;
        }
      }
    }, {
      title: 'Mobile',
      dataIndex: 'mobile',
      sorter: (a, b) => { return a.mobile.localeCompare(b.mobile)}
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: (a, b) => { return a.phone.localeCompare(b.phone)},
    },
    {
      title: 'Action',
      key: 'action',
      width: 240,
      render: (text, record) => (
        <span>
         <Tooltip title="View Agent">
            <span onClick={this.showViewDrawer} className="cursor-pointer"><Icon type="eye" /> View</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Edit Agent">
            <span onClick={this.showDrawer} className="cursor-pointer"><Icon type="edit" /> Edit</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Delete Agent">
            <span  onClick={this.showConfirm} className="cursor-pointer"><Icon type="delete" /> Delete</span>
          </Tooltip>
          {record.idagent === this.state.editedAgentId && this.state.agentdEdited ? <span className={"success"} style={{fontSize: 11,paddingLeft: 10,color: 'green'}}>updated!</span> : null}
        </span>
      )
    }];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return(
      <Row>
        <Col 
          xs={6} 
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className="height-100">
          <SideBar changePath={history.push} {...history}/>
        </Col>
        <Col
          xs={18} 
          sm={18}
          md={20}
          lg={20}
          xl={20} className="height-100-55 dashboard">
            <Row>
              <Header />
              <Row className="contents-main">
                    <div className="breadcrumb-div">
                    <Breadcrumb>
                      <Breadcrumb.Item>Home</Breadcrumb.Item>
                      <Breadcrumb.Item>Agent</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="add-agent-button">
                        <Button type="primary" className="login-form-button" style = {{
                          width: 150,
                          float: 'right',
                          marginTop: -30
                        }}onClick={this.createAgentShowDrawer}>
                        <Icon type="plus" /> Add Agent
                        </Button>
                    </div>
                    </div>
                    <div className="main-table-div">
                      <Table 
                        className="main-table" 
                        columns={columns} 
                        dataSource={this.state.agentsData} 
                        pagination={{pageSize:this.state.numberOfPossibleItems}} 
                        loading={this.state.agentsData.length === 0&& !this.state.updated}
                        locale={{ emptyText: 'No agents added!' }}
                        onChange={this.onChange} onRow={(record) => ({
                          onClick: () => { this.onTableRowClick(record); } 
                      })}/>
                    </div>
                </Row>
                <Drawer
                  title="Add Agent"
                  placement="right"
                  closable={false}
                  onClose={this.onCreateAgentClose}
                  visible={this.state.createAgentVisible}
                  width={450}
                >
                  <Form onSubmit={this.handleCreateAgent} className="agent-create" id="createAgent">
                        <FormItem
                            {...formItemLayout}
                            label="Agent Name"
                            >
                            {getFieldDecorator('agentName', {
                                rules: [{ required: true, message: 'Please enter agent name!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address 1"
                            >
                            {getFieldDecorator('agentAddr1', {
                                rules: [{ required: true, message: 'Please enter agent Address 1!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address 2"
                            >
                            {getFieldDecorator('agentAddr2', {
                                rules: [{ required: false, message: 'Please enter agent Address 2!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address 3"
                            >
                            {getFieldDecorator('agentAddr3', {
                                rules: [{ required: false, message: 'Please enter agent Address 3!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="City"
                            >
                            {getFieldDecorator('city', {
                                rules: [{ required: true, message: 'Please enter agent City!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="State"
                            >
                            {getFieldDecorator('state', {
                                rules: [{ required: true, message: 'Please enter agent State!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                              <Select style={{ width: '100%' }}>
                                  {
                                    states.map(st => {
                                      return <Option key={st.code} value={st.name}>{st.name}</Option>
                                    })
                                  }
                              </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Phone"
                            >
                            {getFieldDecorator('phone', {
                                rules: [{ required: false, message: 'Please enter agent phone!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Pin Code"
                            >
                            {getFieldDecorator('pinCode', {
                                rules: [{ required: true, message: 'Please enter agent Pin Code!', whitespace: true }, {validator: pincode}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Mobile"
                            >
                            {getFieldDecorator('mobile', {
                                rules: [{ required: true, message: 'Please enter agent mobile!', whitespace: true }, {validator: mobilenumber}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Alternate Mobile"
                            >
                            {getFieldDecorator('mobile2', {
                                rules: [{ required: true, message: 'Please enter agent alternate mobile!', whitespace: true }, {validator: mobilenumber}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Website"
                            >
                            {getFieldDecorator('website', {
                                rules: [{ required: true, message: 'Please enter agent website!', whitespace: true }, {validator: website}],
                            })(
                                <Input placeholder={"without http or https"} />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Email"
                            >
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please enter agent email!', whitespace: true }, {validator: emailaddress}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Alternate Email"
                            >
                            {getFieldDecorator('email2', {
                                rules: [{ required: true, message: 'Please enter agent alternate email!', whitespace: true }, {validator: emailaddress}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Type"
                            >
                            {getFieldDecorator('premium', {
                                rules: [{ required: true, message: 'Please select type!', whitespace: true }]
                            })(
                              <RadioGroup name="radiogroup">
                                <Radio value={"true"}>Premium</Radio>
                                <Radio value={"false"}>Normal</Radio>
                              </RadioGroup>
                            )}
                        </FormItem><br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Create</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onCreateAgentClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
                </Drawer>
            <Drawer
                title="Edit Agent"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                width={450}
              >
              <Form onSubmit={this.handleUpdateAgent} className="agent-create" id="createAgent">
                        <FormItem
                            {...formItemLayout}
                            label="Agent Name"
                            >
                            {getFieldDecorator('agentName', {
                                rules: [{ required: true, message: 'Please enter agent name!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.agentName : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address 1"
                            >
                            {getFieldDecorator('agentAddr1', {
                                rules: [{ required: true, message: 'Please enter agent Address 1!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.agentAddr1 : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address 2"
                            >
                            {getFieldDecorator('agentAddr2', {
                                rules: [{ required: false, message: 'Please enter agent Address 2!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.agentAddr2 : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address 3"
                            >
                            {getFieldDecorator('agentAddr3', {
                                rules: [{ required: false, message: 'Please enter agent Address 3!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.agentAddr3 : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="City"
                            >
                            {getFieldDecorator('city', {
                                rules: [{ required: true, message: 'Please enter agent City!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.city : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="State"
                            >
                            {getFieldDecorator('state', {
                                rules: [{ required: true, message: 'Please enter agent State!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.state : null
                            })(
                              <Select style={{ width: '100%' }}>
                                  {
                                    states.map(st => {
                                      return <Option key={st.code} value={st.name}>{st.name}</Option>
                                    })
                                  }
                              </Select>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Phone"
                            >
                            {getFieldDecorator('phone', {
                                rules: [{ required: false, message: 'Please enter agent phone!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.phone : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Pin Code"
                            >
                            {getFieldDecorator('pinCode', {
                                rules: [{ required: true, message: 'Please enter agent Pin Code!', whitespace: true }, {validator: pincode}],
                                initialValue: this.state.editClicked ? this.state.currentRow.pinCode : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Mobile"
                            >
                            {getFieldDecorator('mobile', {
                                rules: [{ required: true, message: 'Please enter agent mobile!', whitespace: true }, {validator: mobilenumber}],
                                initialValue: this.state.editClicked ? this.state.currentRow.mobile : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Alternate Mobile"
                            >
                            {getFieldDecorator('mobile2', {
                                rules: [{ required: true, message: 'Please enter agent alternate mobile!', whitespace: true }, {validator: mobilenumber}],
                                initialValue: this.state.editClicked ? this.state.currentRow.mobile2 : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Website"
                            >
                            {getFieldDecorator('website', {
                                rules: [{ required: true, message: 'Please enter agent website!', whitespace: true }, {validator: website}],
                                initialValue: this.state.editClicked ? this.state.currentRow.website : null
                            })(
                                <Input placeholder={"without http or https"} />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Email"
                            >
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please enter agent email!', whitespace: true }, {validator: emailaddress}],
                                initialValue: this.state.editClicked ? this.state.currentRow.email : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Alternate Email"
                            >
                            {getFieldDecorator('email2', {
                                rules: [{ required: true, message: 'Please enter agent alternate email!', whitespace: true }, {validator: emailaddress}],
                                initialValue: this.state.editClicked ? this.state.currentRow.email2 : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Type"
                            >
                            {getFieldDecorator('premium', {
                                rules: [{ required: true, message: 'Please select type!', whitespace: true }],
                                initialValue: this.state.editClicked ? this.state.currentRow.premium === true ? "true" : "false" : null
                            })(
                              <RadioGroup name="radiogroup">
                                <Radio value={"true"}>Premium</Radio>
                                <Radio value={"false"}>Normal</Radio>
                              </RadioGroup>
                            )}
                        </FormItem><br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Update</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
              </Drawer>
              <Drawer
                title="View Agent"
                placement="right"
                closable={false}
                onClose={this.onViewClose}
                visible={this.state.viewVisible}
                width={500}
              >
                    
                    <p>Agent Name : {this.state.currentRow.agentName ? <b>{this.state.currentRow.agentName}</b> : 'Not Provided.'}</p>
                    <p>Address 1 : {this.state.currentRow.agentAddr1 ? <b>{this.state.currentRow.agentAddr1}</b> : 'Not Provided.'}</p>
                    <p>Address 2 : {this.state.currentRow.agentAddr2 ? <b>{this.state.currentRow.agentAddr2}</b> : 'Not Provided.'}</p>
                    <p>Address 3 : {this.state.currentRow.agentAddr3 ? <b>{this.state.currentRow.agentAddr3}</b> : 'Not Provided.'}</p>
                    <p>Agent City : {this.state.currentRow.city ? <b>{this.state.currentRow.city}</b> : 'Not Provided.'}</p>
                    <p>Agent State : {this.state.currentRow.state ? <b>{this.state.currentRow.state}</b> : 'Not Provided.'}</p>
                    <p>Pin Code : {this.state.currentRow.pinCode ? <b>{this.state.currentRow.pinCode}</b> : 'Not Provided.'}</p>
                    <p>Agent Phone : {this.state.currentRow.phone ? <b>{this.state.currentRow.phone}</b> : 'Not Provided.'}</p>
                    <p>Mobile : {this.state.currentRow.mobile ? <b>{this.state.currentRow.mobile}</b> : 'Not Provided.'}</p>
                    <p>Alternate Mobile : {this.state.currentRow.mobile2 ? <b>{this.state.currentRow.mobile2}</b> : 'Not Provided.'}</p>
                    <p>Email : {this.state.currentRow.email ? <b>{this.state.currentRow.email}</b> : 'Not Provided.'}</p>
                    <p>Alternate Email : {this.state.currentRow.email2 ? <b>{this.state.currentRow.phone}</b> : 'Not Provided.'}</p>
                    <p>Premium : {this.state.currentRow.premium ? <b>Yes</b> : 'No'}</p>
                   
              </Drawer>
            </Row>
        </Col>
      </Row>
    );
  }
}
const WrappedAgent = Form.create()(Agent);
function mapStateToProps(state) {
  return {
    user: state.user,
    agents: state.agent
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(Object.assign({}, agentActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedAgent)