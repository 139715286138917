import * as types from '../actionTypes';
import authApi from '../../api/login/login';

export function login(userParams) {
    return function(dispatch) {
        dispatch(userLoginPending());
        return authApi.login(userParams).then(userResponse => {
            if(userResponse.statusDTO.status === "SUCCESS") {
                dispatch(userLoginSuccess(userResponse.userDTO));
            }
            else
                dispatch(userLoginError(userResponse));
        }).catch(error => {
            userLoginFailed(error);
        });
    };
}

export function getUser(token) {
    return function(dispatch) {
        dispatch(getUserPending());
        return authApi.getUser(token).then(userResponse => {
            if(userResponse.status === "success")
                dispatch(getUserSuccess(userResponse));
            else
                dispatch(getUserError(userResponse));
        }).catch(error => {
            getUserError(error);
        });
    };
}

export function updateUser(userParams,token) {
    return function(dispatch) {
        dispatch(updateUserPending());
        return authApi.setUser(userParams,token).then(userResponse => {
            if(userResponse.status === "success")
                dispatch(updateUserSuccess());
            else
                dispatch(updateUserError(userResponse));
        }).catch(error => {
            updateUserError(error);
        });
    };
}

export function logout(token) {
    return function(dispatch) {
        dispatch(userLogoutPending());
        return authApi.logout(token).then(userResponse => {
            if(userResponse.status === "success")
                dispatch(userLogoutSuccess(userResponse));
            else
                dispatch(userLogoutError());
        }).catch(error => {
            userLogoutError(error);
        });
    };
}

export function resetPassword(narayana_id) {
    return function(dispatch) {
        dispatch(resetPasswordPending());
        return authApi.resetPassword(narayana_id).then(userResponse => {
            if(userResponse.status === "success")
                dispatch(resetPasswordSuccess(userResponse));
            else
                dispatch(resetPasswordError(userResponse));
        }).catch(error => {
            resetPasswordError(error);
        });
    };
}

export function setPassword(passwordParams , token) {
    return function(dispatch) {
        dispatch(setPasswordPending());
        return authApi.setPassword(passwordParams, token).then(userResponse => {
            if(userResponse.status === "success")
                dispatch(setPasswordSuccess(userResponse));
            else
                dispatch(setPasswordError(userResponse));
        }).catch(error => {
            setPasswordError(error);
        });
    };
}

export function userLoginPending() {
    return {type: types.LOGIN_REQUESTED};
}
export function userLoginSuccess(user) {
    return {type: types.LOGIN_SUCCESS, user};
}
export function userLoginError(user) {
    return {type: types.LOGIN_INVALID_CREDS, user}
}
export function userLoginFailed(error) {
    return {type: types.LOGIN_FAILED, error};
}
export function getUserPending() {
    return {type: types.REQUEST_USERPROFILE_INITIATED};
}
export function getUserSuccess(user) {
    return {type: types.REQUEST_USERPROFILE_SUCCESS, user};
}
export function getUserError(error) {
    return {type: types.REQUEST_USERPROFILE_FAILED, error}
}
export function updateUserPending() {
    return {type: types.UPDATE_USERPROFILE_INITIATED};
}
export function updateUserSuccess() {
    return {type: types.UPDATE_USERPROFILE_SUCCESS};
}
export function updateUserError(error) {
    return {type: types.UPDATE_USERPROFILE_FAILED, error}
}
export function userLogoutPending() {
    return {type: types.LOGOUT_INITIATED};
}
export function userLogoutSuccess() {
    return {type: types.LOGOUT_SUCCESS};
}
export function userLogoutError(error) {
    return {type: types.LOGOUT_FAILED, error}
}
export function resetPasswordPending() {
    return {type: types.RESET_PASSWORD_INITIATED};
}
export function resetPasswordSuccess(user) {
    return {type: types.RESET_PASSWORD_SUCCESS, user};
}
export function resetPasswordError(error) {
    return {type: types.RESET_PASSWORD_FAILED, error}
}
export function setPasswordPending() {
    return {type: types.SET_PASSWORD_INITIATED};
}
export function setPasswordSuccess(user) {
    return {type: types.SET_PASSWORD_SUCCESS, user};
}
export function setPasswordError(error) {
    return {type: types.SET_PASSWORD_FAILED, error}
}