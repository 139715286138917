import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.receipt, action) {
    switch (action.type) {
        case types.GET_RECEIPT_INITIATED:
            return {
                ...state,
                getReceiptsPending: true,
                    error: null,
                    getReceiptsSuccess: false,
                    createReceiptSuccess: false,
                    deleteReceiptSuccess: false,
                    updateReceiptSuccess: false
            };
        case types.GET_RECEIPT_FAILED:
            return {
                ...state,
                getReceiptsPending: false,
                    error: action.error,
                    getReceiptsSuccess: false,
                    createReceiptSuccess: false
            };
        case types.GET_RECEIPT_SUCCESS:
            return {
                ...state,
                getReceiptsPending: false,
                    getReceiptsSuccess: true,
                    error: null,
                    data: action.receipts,
                    createReceiptSuccess: false
            };
        case types.CREATE_RECEIPT_INITIATED:
            return {
                ...state,
                createReceiptPending: false,
                    error: null
            };
        case types.CREATE_RECEIPT_FAILED:
            return {
                ...state,
                createReceiptPending: false,
                    error: action.error
            };
        case types.CREATE_RECEIPT_SUCCESS:
            return {
                ...state,
                createReceiptPending: false,
                    createReceiptSuccess: true,
                    error: null
            };
        case types.UPDATE_RECEIPT_INITIATED:
            return {
                ...state,
                updateReceiptPending: true,
                    error: null
            };
        case types.UPDATE_RECEIPT_FAILED:
            return {
                ...state,
                updateReceiptPending: false,
                    error: action.error
            };
        case types.UPDATE_RECEIPT_SUCCESS:
            return {
                ...state,
                updateReceiptPending: false,
                    updateReceiptSuccess: true,
                    createReceiptSuccess: false,
                    getReceiptsPending: false,
                    error: null
            };
        case types.DELETE_RECEIPT_INITIATED:
            return {
                ...state,
                deleteReceiptPending: true,
                    error: null
            };
        case types.DELETE_RECEIPT_FAILED:
            return {
                ...state,
                deleteReceiptPending: false,
                    error: action.error
            };
        case types.DELETE_RECEIPT_SUCCESS:
            return {
                ...state,
                deleteReceiptPending: false,
                    deleteReceiptSuccess: true,
                    createReceiptSuccess: false,
                    error: null
            };
        case types.GET_RECEIPT_BY_ID_INITIATED:
            return {
                ...state,
                getReceiptbyIdPending: true,
                    getReceiptbyIDSuccess: false,
                    getReceiptbyIdError: null,
                    getReceiptsSuccess: false,
                    createReceiptSuccess: false,
                    deleteReceiptSuccess: false,
                    updateReceiptSuccess: false
            };
        case types.GET_RECEIPT_BY_ID_FAILED:
            return {
                ...state,
                getReceiptsPending: false,
                    getReceiptbyIdPending: false,
                    getReceiptbyIDSuccess: false,
                    getReceiptbyIdError: action.error,
                    getReceiptsSuccess: false,
                    createReceiptSuccess: false
            };
        case types.GET_RECEIPT_BY_ID_SUCCESS:
            return {
                ...state,
                getReceiptsPending: false,
                    getReceiptsSuccess: false,
                    getReceiptbyIdPending: true,
                    getReceiptbyIDSuccess: action.receipt,
                    getReceiptbyIdError: null,
                    createReceiptSuccess: false
            };
        default:
            return state;
    }
}
