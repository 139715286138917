import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Icon, Input, Button, Checkbox, message } from 'antd';
import * as loginActions from '../../actions/login/login';
import reduxStore from '../../store';
import './login.scss';
const history = reduxStore.history;
const FormItem = Form.Item;
message.config({
  maxCount: 1
});
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        error: {
          status: '',
          msg: ''
        },
        loginPending: false,
        loginError: '',
        userData: '',
        remember: true,
        validation: false,
        errormsg :'', 
        errorshown: false,
        username: ''
    };
  }
  componentDidMount() {
      document.title = "Login | MRC Fabrics";
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
          this.setState({username: values.username,errorshown: false,errormsg: '', error: {status: '', msg: ''}, loginPending: true, remember: values.remember, validation: true, loginError: ''});
          // window.location = '/supplier';
          this.props.actions.login({"userName": values.username, "userPassword": values.password});
      }
    });
  }
  static getDerivedStateFromProps(nextState) {
    // this.setState({loginPending: nextState.user.loginPending, loginError: nextState.user.error, userData: nextState.user.response});
    if(nextState.user.loginSuccess && nextState.user.response) {
      nextState.user.loginSuccess = false;
      localStorage.setItem('_token',nextState.user.response.userToken);
      localStorage.setItem('_userId', btoa(nextState.user.response.userId));
      localStorage.setItem('_userName', btoa(nextState.user.response.userName));
      localStorage.setItem('_userType', btoa(nextState.user.response.userType));
      localStorage.setItem('_roleName', btoa(nextState.user.response.roleName));
      localStorage.setItem('_loggedin', btoa('_'));
      window.location = '/supplier';
    }
    // if(!nextState.user.error && !nextState.user.loginPending && this.state.validation) {
    //   this.setState({loginError: nextState.user.error});
    //   if(this.state.remember) {
    //     //TO-DO handle in express
    //   }
    //   localStorage.setItem("_token", nextState.user.response.data.token);
    //   localStorage.setItem("_userId", nextState.user.response.data.id_role);
    //   localStorage.setItem("_userRole", nextState.user.response.data.id_user);
    //   if(nextState.user.response.data.id_role === 3) {
    //     localStorage.setItem("_schoolId", nextState.user.response.data.id_school);
    //   }
    //   console.log(nextState.user.response.data)
    //   if(!nextState.user.profile && nextState.user.userRequest === undefined){
    //     this.props.actions.getUser(nextState.user.response.data.token);
    //   }
    //   if(nextState.user.response && nextState.user.response.status === "success" && nextState.user.profile && nextState.user.profile.status === "success") {
    //     this.setState({loginPending: nextState.user.loginPending})
    //     history.push('/');
    //   }
    // } else if(nextState.user.error && !nextState.user.loginPending && this.state.validation) {
    //   this.setState({loginPending: nextState.user.loginPending, loginError: '', errormsg:nextState.user.error});
    // } else {
    //   this.setState({loginPending: nextState.user.loginPending, loginError: ''});
    // }
    // if(nextState.user && nextState.user.loggedOut) {
    //   message.success('Successfully logged out.', 3);
    //   nextState.user.loggedOut = false;
    // }
  }
  componentDidUpdate() {
    if(this.state.errormsg.length && !this.state.errorshown) {
      message.error(this.state.errormsg, 2);
      this.props.form.resetFields();
      this.setState({errormsg: '', errorshown: true});
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return(
        <Row>
          <Col
           xs={{ span: 20, offset: 2 }} 
           sm={{ span: 16, offset: 4 }}
           md={{ span: 6, offset: 9 }}
           lg={{ span: 6, offset: 9 }}
           xl={{ span: 6, offset: 9 }}>
            <div className="login-container">
              <div className="login-top">
                  <div className="login-header">
                    <a href="/">
                      <span className="title">
                        MRC Fabrics
                      </span>
                    </a>
                  </div>
                  <div className="login-desc">
                    <font>
                        Admin Portal
                      </font>
                  </div>
              </div>
              <div className="login-main">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                      <FormItem
                      validateStatus={this.state.error.status.length ? this.state.error.status : this.props.form.getFieldError('username')&&this.props.form.getFieldError('username')[0] ? 'error': '' }
                      help={this.state.error.msg.length ? this.state.error.msg : this.props.form.getFieldError('username') ? this.props.form.getFieldError('username')[0] : ''}
                      >
                        {getFieldDecorator('username', {
                          rules: [{ required: true, message: 'Please enter your user id!' }],
                          initialValue: this.state.username
                        })(
                          <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="User ID" />
                        )}
                      </FormItem>
                      <FormItem
                      validateStatus={this.state.loginError&&this.state.loginError.length ? 'error' : this.props.form.getFieldError('password')&&this.props.form.getFieldError('password')[0] ? 'error': '' }
                      help={this.state.loginError&&this.state.loginError.length ? this.state.loginError : this.props.form.getFieldError('password') ? this.props.form.getFieldError('password')[0] : ''}
                      >
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: 'Please enter your password!' }]
                        })(
                          <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator('remember_status', {
                          valuePropName: 'checked',
                          initialValue: true,
                        })(
                          <Checkbox>Remember me</Checkbox>
                        )}
                        <Link className="login-form-forgot" to="/forgot">Forgot password</Link>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loginPending}>
                          Log in
                        </Button>
                      </FormItem>
                    </Form>
              </div>
              <div className="login-footer">
                &copy; 2019 - {new Date().getFullYear()} MRC Fabrics
              </div>
            </div>
          </Col>
        </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(Object.assign({}, loginActions), dispatch)
  };
}

const WrappedLoginForm = Form.create()(Login);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedLoginForm);