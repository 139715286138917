import partyApi from '../../api/home/party'
import * as types from '../actionTypes'

export function getParty(token) {
    return function(dispatch) {
        dispatch(getPartyPending());
        return partyApi.getParty(token).then(partys => {
            if(partys.statusDTO.status === "SUCCESS") {
                dispatch(getPartySuccess(partys));
            } else {
                dispatch(getPartyFailed(partys));
            }
        }).catch(error => {
           dispatch(getPartyFailed(error));
        });
    };
}
export function getPartybyId(token) {
    return function(dispatch) {
        dispatch(getPartybyIdPending());
        return partyApi.getPartybyID(token).then(partys => {
            if(partys.statusDTO.status === "SUCCESS") {
                dispatch(getPartybyIdSuccess(partys));
            } else {
                dispatch(getPartybyIdFailed(partys));
            }
        }).catch(error => {
           dispatch(getPartybyIdFailed(error));
        });
    };
}
export function createParty(partyId, partyData , token) {
    return function(dispatch) {
        dispatch(createPartyPending());
        return partyApi.createParty(partyId, partyData , token).then(partys => {
            if(partys.statusDTO.status === "SUCCESS") {
                dispatch(createPartySuccess(partys));
            } else {
                dispatch(createPartyFailed(partys));
            }
        }).catch(error => {
            dispatch(createPartyFailed(error));
        });
    };
}
export function updateParty(partyData , token) {
    return function(dispatch) {
        dispatch(updatePartyPending());
        return partyApi.updateParty(partyData , token).then(partys => {
            if(partys.statusDTO.status === "SUCCESS") {
                dispatch(updatePartySuccess(partys));
            } else {
                dispatch(updatePartyFailed(partys));
            }
        }).catch(error => {
            dispatch(updatePartyFailed(error));
        });
    };
}
export function deleteParty(partyData , token) {
    return function(dispatch) {
        dispatch(deletePartyPending());
        return partyApi.deleteParty(partyData , token).then(partys => {
            if(partys.statusDTO.status === "SUCCESS") {
                dispatch(deletePartySuccess(partys));
            } else {
                dispatch(deletePartyFailed(partys));
            }
        }).catch(error => {
            dispatch(deletePartyFailed(error));
        });
    };
}


export function getPartyPending() {
    return {type: types.GET_PARTY_INITIATED};
}
export function getPartySuccess(partys) {
    return {type: types.GET_PARTY_SUCCESS, partys};
}
export function getPartyFailed(error) {
    return {type: types.GET_PARTY_FAILED, error};
}
export function createPartyPending() {
    return {type: types.CREATE_PARTY_INITIATED};
}
export function createPartySuccess(partys) {
    return {type: types.CREATE_PARTY_SUCCESS, partys};
}
export function createPartyFailed(error) {
    return {type: types.CREATE_PARTY_FAILED, error};
}
export function updatePartyPending() {
    return {type: types.UPDATE_PARTY_INITIATED};
}
export function updatePartySuccess(partys) {
    return {type: types.UPDATE_PARTY_SUCCESS, partys};
}
export function updatePartyFailed(error) {
    return {type: types.UPDATE_PARTY_FAILED,error};
}
export function deletePartyPending() {
    return {type: types.DELETE_PARTY_INITIATED};
}
export function deletePartySuccess(partys) {
    return {type: types.DELETE_PARTY_SUCCESS, partys};
}
export function deletePartyFailed(error) {
    return {type: types.DELETE_PARTY_FAILED, error};
}
export function getPartybyIdPending() {
    return {type: types.GET_PARTY_BY_ID_INITIATED};
}
export function getPartybyIdSuccess(party) {
    return {type: types.GET_PARTY_BY_ID_SUCCESS, party};
}
export function getPartybyIdFailed(error) {
    return {type: types.GET_PARTY_BY_ID_FAILED, error};
}
