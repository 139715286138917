import processingOrderApi from '../../api/home/processingOrder'
import * as types from '../actionTypes'

export function getProcessingOrders(token) {
    return function(dispatch) {
        dispatch(getProcessingOrderPending());
        return processingOrderApi.getProcessingOrders(token).then(processingOrders => {
            if(processingOrders.statusDTO.status === "SUCCESS") {
                dispatch(getProcessingOrderSuccess(processingOrders));
            } else {
                dispatch(getProcessingOrderFailed(processingOrders));
            }
        }).catch(error => {
           dispatch(getProcessingOrderFailed(error));
        });
    };
}
export function getProcessingOrderbyId(token) {
    return function(dispatch) {
        dispatch(getProcessingOrderbyIdPending());
        return processingOrderApi.getProcessingOrderbyID(token).then(processingOrders => {
            if(processingOrders.statusDTO.status === "SUCCESS") {
                dispatch(getProcessingOrderbyIdSuccess(processingOrders));
            } else {
                dispatch(getProcessingOrderbyIdFailed(processingOrders));
            }
        }).catch(error => {
           dispatch(getProcessingOrderbyIdFailed(error));
        });
    };
}
export function createProcessingOrder(processingOrderId, processingOrderData , token) {
    return function(dispatch) {
        dispatch(createProcessingOrderPending());
        return processingOrderApi.createProcessingOrder(processingOrderId, processingOrderData , token).then(processingOrders => {
            if(processingOrders.statusDTO.status === "SUCCESS") {
                dispatch(createProcessingOrderSuccess(processingOrders));
            } else {
                dispatch(createProcessingOrderFailed(processingOrders));
            }
        }).catch(error => {
            dispatch(createProcessingOrderFailed(error));
        });
    };
}
export function updateProcessingOrder(processingOrderData , token) {
    return function(dispatch) {
        dispatch(updateProcessingOrderPending());
        return processingOrderApi.updateProcessingOrder(processingOrderData , token).then(processingOrders => {
            if(processingOrders.statusDTO.status === "SUCCESS") {
                dispatch(updateProcessingOrderSuccess(processingOrders));
            } else {
                dispatch(updateProcessingOrderFailed(processingOrders));
            }
        }).catch(error => {
            dispatch(updateProcessingOrderFailed(error));
        });
    };
}
export function deleteProcessingOrder(processingOrderData , token) {
    return function(dispatch) {
        dispatch(deleteProcessingOrderPending());
        return processingOrderApi.deleteProcessingOrder(processingOrderData , token).then(processingOrders => {
            if(processingOrders.statusDTO.status === "SUCCESS") {
                dispatch(deleteProcessingOrderSuccess(processingOrders));
            } else {
                dispatch(deleteProcessingOrderFailed(processingOrders));
            }
        }).catch(error => {
            dispatch(deleteProcessingOrderFailed(error));
        });
    };
}


export function getProcessingOrderPending() {
    return {type: types.GET_PROCESSING_ORDERS_INITIATED};
}
export function getProcessingOrderSuccess(processingOrders) {
    return {type: types.GET_PROCESSING_ORDERS_SUCCESS, processingOrders};
}
export function getProcessingOrderFailed(error) {
    return {type: types.GET_PROCESSING_ORDERS_FAILED, error};
}
export function createProcessingOrderPending() {
    return {type: types.CREATE_PROCESSING_ORDERS_INITIATED};
}
export function createProcessingOrderSuccess(processingOrders) {
    return {type: types.CREATE_PROCESSING_ORDERS_SUCCESS, processingOrders};
}
export function createProcessingOrderFailed(error) {
    return {type: types.CREATE_PROCESSING_ORDERS_FAILED, error};
}
export function updateProcessingOrderPending() {
    return {type: types.UPDATE_PROCESSING_ORDERS_INITIATED};
}
export function updateProcessingOrderSuccess(processingOrders) {
    return {type: types.UPDATE_PROCESSING_ORDERS_SUCCESS, processingOrders};
}
export function updateProcessingOrderFailed(error) {
    return {type: types.UPDATE_PROCESSING_ORDERS_FAILED,error};
}
export function deleteProcessingOrderPending() {
    return {type: types.DELETE_PROCESSING_ORDERS_INITIATED};
}
export function deleteProcessingOrderSuccess(processingOrders) {
    return {type: types.DELETE_PROCESSING_ORDERS_SUCCESS, processingOrders};
}
export function deleteProcessingOrderFailed(error) {
    return {type: types.DELETE_PROCESSING_ORDERS_FAILED, error};
}
export function getProcessingOrderbyIdPending() {
    return {type: types.GET_PROCESSING_ORDERS_BY_ID_INITIATED};
}
export function getProcessingOrderbyIdSuccess(processingOrder) {
    return {type: types.GET_PROCESSING_ORDERS_BY_ID_SUCCESS, processingOrder};
}
export function getProcessingOrderbyIdFailed(error) {
    return {type: types.GET_PROCESSING_ORDERS_BY_ID_FAILED, error};
}
