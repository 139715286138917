import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class partyApi {
    static getParty(token) {
        return fetch(`${host}/party/listall`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createParty(partyData,token) {
        return fetch(`${host}/party/add`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(partyData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updateParty(partyID, partyData,token) {
        return fetch(`${host}/party/update/${partyID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(partyData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deleteParty(deleteId,token) {
        return fetch(`${host}/party/delete/${deleteId}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getPartybyID(partyID, token) {
        return fetch(`${host}/party/getParty/${partyID}`, {
            headers: {
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default partyApi;