import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.orderItem, action) {
    switch (action.type) {
        case types.GET_ORDER_ITEMS_INITIATED:
            return {
                ...state,
                getOrderItemPending: true,
                    error: null,
                    getOrderItemSuccess: false,
                    createOrderItemSuccess: false,
                    deleteOrderItemSuccess: false,
                    updateOrderItemSuccess: false
            };
        case types.GET_ORDER_ITEMS_FAILED:
            return {
                ...state,
                getOrderItemPending: false,
                    error: action.error,
                    getOrderItemSuccess: false,
                    createOrderItemSuccess: false
            };
        case types.GET_ORDER_ITEMS_SUCCESS:
            return {
                ...state,
                getOrderItemPending: false,
                    getOrderItemSuccess: true,
                    error: null,
                    data: action.orderItems,
                    createOrderItemSuccess: false
            };
        case types.CREATE_ORDER_ITEMS_INITIATED:
            return {
                ...state,
                createOrderItemPending: false,
                    error: null
            };
        case types.CREATE_ORDER_ITEMS_FAILED:
            return {
                ...state,
                createOrderItemPending: false,
                createOrderItemError: true,
                createOrderItemErrorData: action.error
            };
        case types.CREATE_ORDER_ITEMS_SUCCESS:
            return {
                ...state,
                createOrderItemPending: false,
                    createOrderItemSuccess: true,
                    error: null
            };
        case types.UPDATE_ORDER_ITEMS_INITIATED:
            return {
                ...state,
                updateOrderItemPending: true,
                    error: null
            };
        case types.UPDATE_ORDER_ITEMS_FAILED:
            return {
                ...state,
                updateOrderItemPending: false,
                    error: action.error
            };
        case types.UPDATE_ORDER_ITEMS_SUCCESS:
            return {
                ...state,
                updateOrderItemPending: false,
                    updateOrderItemSuccess: true,
                    createOrderItemSuccess: false,
                    getOrderItemPending: false,
                    error: null
            };
        case types.DELETE_ORDER_ITEMS_INITIATED:
            return {
                ...state,
                deleteOrderItemPending: true,
                    error: null
            };
        case types.DELETE_ORDER_ITEMS_FAILED:
            return {
                ...state,
                deleteOrderItemPending: false,
                    error: action.error
            };
        case types.DELETE_ORDER_ITEMS_SUCCESS:
            return {
                ...state,
                deleteOrderItemPending: false,
                    deleteOrderItemSuccess: true,
                    createOrderItemSuccess: false,
                    error: null
            };
        case types.GET_ORDER_ITEMS_BY_ID_INITIATED:
            return {
                ...state,
                getOrderItembyIdPending: true,
                    getOrderItembyIDSuccess: false,
                    getOrderItembyIdError: null,
                    getOrderItemSuccess: false,
                    createOrderItemSuccess: false,
                    deleteOrderItemSuccess: false,
                    updateOrderItemSuccess: false
            };
        case types.GET_ORDER_ITEMS_BY_ID_FAILED:
            return {
                ...state,
                getOrderItemPending: false,
                    getOrderItembyIdPending: false,
                    getOrderItembyIDSuccess: false,
                    getOrderItembyIdError: action.error,
                    getOrderItemSuccess: false,
                    createOrderItemSuccess: false
            };
        case types.GET_ORDER_ITEMS_BY_ID_SUCCESS:
            return {
                ...state,
                getOrderItemPending: false,
                    getOrderItemSuccess: false,
                    getOrderItembyIdPending: true,
                    getOrderItembyIDSuccess: action.orderItem,
                    getOrderItembyIdError: null,
                    createOrderItemSuccess: false
            };
        default:
            return state;
    }
}
