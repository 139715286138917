const alphanumeric = (rule, value, callback) => {
    if (/^[a-zA-Z0-9_]*$/.test(value)) {
        callback();
        return true;
      }
      callback('Only alphanumeric value allowed');
      return false;
};
const alphanumericwithspace = (rule, value, callback) => {
    if (/^[a-zA-Z0-9_ -.@]*$/.test(value)) {
        callback();
        return true;
      }
      callback('Only alphanumeric value allowed');
      return false;
};
const mobilenumber = (rule, value, callback) => {
    if (/^[0-9]*$/.test(value)) {
        if(parseInt(value, 10) < 6000000000) {
            callback('Invalid Mobile Number');
            return false;
        } else {
            callback();
            return true;
        }
      }
      callback('Invalid Mobile Number');
      return false;
};
const emailaddress = (rule, value, callback) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value)) {
            callback();
            return true;
      }
      callback('Invalid Email Address');
      return false;
};
const website = (rule, value, callback) => {
    if (/^(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_.~#?&//=]*)*$/.test(value)) {
        callback();
        return true;
  }
  callback('Invalid Website URL');
  return false;
};
const pincode = (rule, value, callback) => {
    if (/^[1-9]{1}[0-9]{2}[0-9]{3}$/.test(value)) {
        callback();
        return true;
  }
  callback('Invalid Pin Code');
  return false;
};

export {
    alphanumeric,
    alphanumericwithspace,
    mobilenumber,
    emailaddress,
    website,
    pincode
}