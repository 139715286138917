export default {
    supplier: [],
    agent: [],
    party: [],
    purchaseOrder: [],
    manufacturingOrder: [],
    processingOrder: [],
    orderItem: [],
    receipt: [],
    checking: [],
    user: []
}