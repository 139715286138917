import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class agentApi {
    static getAgents(token) {
        return fetch(`${host}/agent/listall`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createAgent(agentData,token) {
        return fetch(`${host}/agent/add`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'POST',
            body: JSON.stringify(agentData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updateAgent(agentID, agentData,token) {
        return fetch(`${host}/agent/update/${agentID}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'PUT',
            body: JSON.stringify(agentData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deleteAgent(deleteId,token) {
        return fetch(`${host}/agent/delete/${deleteId}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getAgentbyID(agentID, token) {
        return fetch(`${host}/agent/getAgent/${agentID}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage._token,
                loginId: atob(localStorage._userId)
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default agentApi;