import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import './sidebar.scss';
import auth from '../../modules/auth';
const logo = require('../../assets/images/404.png');
const { SubMenu } = Menu;

class SideBar extends Component {
    constructor(props){
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.changePath = this.changePath.bind(this);
    }
    changePath(path) {
        this.props.changePath(path)
    }
    handleMenuClick(e) {
        window.location = e.key;
    }
    render() {
        return(
            <div className="sidebar">
                <div className="logo">
                    <div>
                        <img src={logo} alt="nConnect" style={{height: 50}} />
                    </div>
                </div>
                {auth.isSuperAdmin() === true ? 
                    <Menu
                    defaultSelectedKeys={[this.props.location.pathname]}
                    defaultOpenKeys={[this.props.location.pathname.split('/')[1], this.props.location.pathname.split('/')[1].toLowerCase().includes('order') ? 'orders' : '']}
                    mode="inline"
                    theme="dark"
                    style={{height: 675}}
                    onClick={this.handleMenuClick}
                    >
                        <Menu.Item key="/zones">
                            <Icon type="global" />
                            <span>Zones</span>
                        </Menu.Item>
                        <Menu.Item key="/schools/types">
                            <Icon type="bars" />
                            <span>School Types</span>
                        </Menu.Item>
                        <Menu.Item key="/schools/home">
                            <Icon type="exception" />
                            <span>Schools</span>
                        </Menu.Item>
                       
                        {/* <Menu.Item key="/schools/principal">
                            <Icon type="solution" />
                            <span>Principal</span>
                        </Menu.Item> */}
                    </Menu> :
                    <Menu
                        defaultSelectedKeys={[this.props.location.pathname]}
                        defaultOpenKeys={[this.props.location.pathname.split('/')[1], this.props.location.pathname.split('/')[1].toLowerCase().includes('order') ? 'orders' : '']}
                        mode="inline"
                        theme="dark"
                    style={{height: 675}}
                        onClick={this.handleMenuClick}
                        >
                        <Menu.Item key="/supplier">
                            <Icon type="idcard" />
                            <span>Suppliers</span>
                        </Menu.Item>
                        <Menu.Item key="/agent">
                            <Icon type="user" />
                            <span>Agents</span>
                        </Menu.Item>
                        <Menu.Item key="/party">
                            <Icon type="schedule" />
                            <span>Party</span>
                        </Menu.Item>
                        <SubMenu
                            key="orders"
                            title={
                            <span>
                                <Icon type="appstore" />
                                <span>Orders</span>
                            </span>
                            }
                        >
                            <Menu.Item key="/purchaseOrder">
                                <Icon type="layout" />
                                <span>Purchase Orders</span>
                            </Menu.Item>
                            <Menu.Item key="/orderItem">
                                <Icon type="calculator" />
                                <span>Order Item</span>
                            </Menu.Item>
                            <Menu.Item key="/manufacturingOrder">
                                <Icon type="appstore-o" />
                                <span>Manufacturing Orders</span>
                            </Menu.Item>
                            <Menu.Item key="/processingOrder">
                                <Icon type="bulb" />
                                <span>Processing Order</span>
                            </Menu.Item>
                                <Menu.Item key="/checkingItemOrder">
                                <Icon type="lock" />
                                <span>Checking Items</span>
                            </Menu.Item>                            
                        </SubMenu>                        
                        <Menu.Item key="/receipt">
                            <Icon type="tags-o" />
                            <span>Receipt</span>
                        </Menu.Item>    
                    </Menu>}
            </div>
        );
    }
}

export default SideBar;