import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.manufacturingOrder, action) {
    switch (action.type) {
        case types.GET_MANUFACTURING_ORDERS_INITIATED:
            return {
                ...state,
                getManufacturingOrdersPending: true,
                    error: null,
                    getManufacturingOrdersSuccess: false,
                    createManufacturingOrderSuccess: false,
                    deleteManufacturingOrderSuccess: false,
                    updateManufacturingOrderSuccess: false
            };
        case types.GET_MANUFACTURING_ORDERS_FAILED:
            return {
                ...state,
                getManufacturingOrdersPending: false,
                    error: action.error,
                    getManufacturingOrdersSuccess: false,
                    createManufacturingOrderSuccess: false
            };
        case types.GET_MANUFACTURING_ORDERS_SUCCESS:
            return {
                ...state,
                getManufacturingOrdersPending: false,
                    getManufacturingOrdersSuccess: true,
                    error: null,
                    data: action.manufacturingOrders,
                    createManufacturingOrderSuccess: false
            };
        case types.CREATE_MANUFACTURING_ORDERS_INITIATED:
            return {
                ...state,
                createManufacturingOrderPending: false,
                    error: null
            };
        case types.CREATE_MANUFACTURING_ORDERS_FAILED:
            return {
                ...state,
                createManufacturingOrderPending: false,
                    error: action.error
            };
        case types.CREATE_MANUFACTURING_ORDERS_SUCCESS:
            return {
                ...state,
                createManufacturingOrderPending: false,
                    createManufacturingOrderSuccess: true,
                    error: null
            };
        case types.UPDATE_MANUFACTURING_ORDERS_INITIATED:
            return {
                ...state,
                updateManufacturingOrderPending: true,
                    error: null
            };
        case types.UPDATE_MANUFACTURING_ORDERS_FAILED:
            return {
                ...state,
                updateManufacturingOrderPending: false,
                    error: action.error
            };
        case types.UPDATE_MANUFACTURING_ORDERS_SUCCESS:
            return {
                ...state,
                updateManufacturingOrderPending: false,
                    updateManufacturingOrderSuccess: true,
                    createManufacturingOrderSuccess: false,
                    getManufacturingOrdersPending: false,
                    error: null
            };
        case types.DELETE_MANUFACTURING_ORDERS_INITIATED:
            return {
                ...state,
                deleteManufacturingOrderPending: true,
                    error: null
            };
        case types.DELETE_MANUFACTURING_ORDERS_FAILED:
            return {
                ...state,
                deleteManufacturingOrderPending: false,
                    error: action.error
            };
        case types.DELETE_MANUFACTURING_ORDERS_SUCCESS:
            return {
                ...state,
                deleteManufacturingOrderPending: false,
                    deleteManufacturingOrderSuccess: true,
                    createManufacturingOrderSuccess: false,
                    error: null
            };
        case types.GET_MANUFACTURING_ORDERS_BY_ID_INITIATED:
            return {
                ...state,
                getManufacturingOrderbyIdPending: true,
                    getManufacturingOrderbyIDSuccess: false,
                    getManufacturingOrderbyIdError: null,
                    getManufacturingOrdersSuccess: false,
                    createManufacturingOrderSuccess: false,
                    deleteManufacturingOrderSuccess: false,
                    updateManufacturingOrderSuccess: false
            };
        case types.GET_MANUFACTURING_ORDERS_BY_ID_FAILED:
            return {
                ...state,
                getManufacturingOrdersPending: false,
                    getManufacturingOrderbyIdPending: false,
                    getManufacturingOrderbyIDSuccess: false,
                    getManufacturingOrderbyIdError: action.error,
                    getManufacturingOrdersSuccess: false,
                    createManufacturingOrderSuccess: false
            };
        case types.GET_MANUFACTURING_ORDERS_BY_ID_SUCCESS:
            return {
                ...state,
                getManufacturingOrdersPending: false,
                    getManufacturingOrdersSuccess: false,
                    getManufacturingOrderbyIdPending: true,
                    getManufacturingOrderbyIDSuccess: action.manufacturingOrder,
                    getManufacturingOrderbyIdError: null,
                    createManufacturingOrderSuccess: false
            };
        default:
            return state;
    }
}
