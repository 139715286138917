import React, { Component } from 'react';
import { Icon, Popover, List, Drawer, Form, Input, Button, message, DatePicker, Radio, Modal } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import auth from '../../modules/auth';
import * as loginActions from '../../actions/login/login';
import moment from 'moment';
import Raven from 'raven-js';
import './header.scss';
const FormItem = Form.Item;
const { TextArea } = Input;
const RadioGroup = Radio.Group;
const confirm = Modal.confirm;

const auth = (r) => r.json();
class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
        this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
        this.validateToNextPassword = this.validateToNextPassword.bind(this);
        this.closeSideBar = this.closeSideBar.bind(this);
    }
    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    closeSideBar() {
        this.props.form.resetFields();
        this.props.onClosePasswordDrawer();
    }
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Confirm Password does not match the Password');
        } else {
            callback();
        }
    }
    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
        }
    handlePasswordSubmit = (e) => {
        console.log(e)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            let userToken = null;
            let userId = null;
            if(this.props.user.response && this.props.user.response.token) {
                userToken = this.props.user.response.token;
                userId = this.props.user.response.token;
            } else {
                userToken = localStorage.getItem('_token');
                userId = localStorage.getItem('_userRole');
            }
            this.props.actions.setPassword({id_user: userId, password: values.password}, userToken);
          }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
          const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };
          const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            },
          };
        return (
            <Form onSubmit={this.handlePasswordSubmit} className="user-edit">
                                        <FormItem
                                            {...formItemLayout}
                                            label="Password"
                                            >
                                            {getFieldDecorator('password', {
                                                rules: [{
                                                required: true, message: 'Please enter your password!',
                                                }, {
                                                validator: this.validateToNextPassword,
                                                }],
                                            })(
                                                <Input type="password" />
                                            )}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout}
                                            label="Confirm Password"
                                            >
                                            {getFieldDecorator('confirm', {
                                                rules: [{
                                                required: true, message: 'Please confirm your password!',
                                                }, {
                                                validator: this.compareToFirstPassword,
                                                }],
                                            })(
                                                <Input type="password" onBlur={this.handleConfirmBlur} />
                                            )}
                                        </FormItem>
                                        <span className="has-error left-150">
                                            <span className="ant-form-explain">{this.props.updatePasswordError}</span>
                                        </span>
                                        <div
                                            style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            width: '100%',
                                            borderTop: '1px solid #e8e8e8',
                                            padding: '10px 16px',
                                            textAlign: 'right',
                                            left: 0,
                                            background: '#fff',
                                            borderRadius: '0 0 4px 4px',
                                            }}
                                        >
                                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                                <Button type="primary" htmlType="submit">Update</Button>
                                                <Button type="default" className="edit-profile-cancel" onClick={this.closeSideBar}>Cancel</Button>
                                            </FormItem>
                                        </div>
                                    </Form>
        );
    }
}
const WrappedPassword = Form.create()(UpdatePassword);
class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            userData: '',
            visible: false,
            editVisible: false,
            confirmDirty: false,
            updateError: '',
            modalVisible: false,
            selectedValue: null,
            radioButtonValue: '',
            editPasswordVisible: false,
            updatePasswordError: ''
        };
        this.showDrawer = this.showDrawer.bind(this);
        this.onClose = this.onClose.bind(this);
        this.showEditDrawer = this.showEditDrawer.bind(this);
        this.onCloseEditDrawer = this.onCloseEditDrawer.bind(this);
        this.showEditPasswordDrawer = this.showEditPasswordDrawer.bind(this);
        this.onClosePasswordDrawer = this.onClosePasswordDrawer.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.disabledEndDate = this.disabledEndDate.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
    }
    showConfirm() {
        const _this = this;
        confirm({
          title: 'Are you really want to logout from the Admin Panel?',
          content: '',
          onOk() {
            _this.logoutUser();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    disabledEndDate = (endValue) => {
        if (!endValue) {
            return false;
          }
        return endValue.valueOf() > moment().valueOf();
      }
    componentWillMount(){
        // if(!this.props.user.length) {
        //     this.props.actions.getUser(localStorage.getItem('_token'));
        // }
    }
    showModal = () => {
        this.setState({
            modalVisible: true,
        });
      }
    
      handleOk = (e) => {
        const {onSelect} = this.props;
        console.log(e);
        onSelect(this.state.radioButtonValue);
        this.setState({
            modalVisible: false,
        });
      }
    
      handleCancel = (e) => {
        console.log(e);
        this.setState({
            modalVisible: false,
        });
      }
      onChange = (e) => {
          const { data, id} = this.props;
        this.setState({
          value: e.target.value,
          radioButtonValue: e.target.value
        });
        const objectName = id === "id_course"? "course_name": "name";
        this.setState({selectedValue: data.filter(d => d[id] === e.target.value)[0][objectName]});
      }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            let userToken = null;
            if(this.props.user.response && this.props.user.response.token) {
                userToken = this.props.user.response.token;
            } else {
                userToken = localStorage.getItem('_token');
            }
            this.props.actions.updateUser({name:values.name,email:values.email,mobile_number: values.mobile_number, dob: moment(values.dob).format('YYYY-MM-DD'), address: values.address, description: values.description}, userToken);
          }
        });
    }
    
    
    logoutUser() {
        if(this.props.user.response && this.props.user.response.token) {
            this.props.actions.logout(this.props.user.response.token);
        } else {
            this.props.actions.logout(localStorage.getItem('_token'));
        }
    }
    componentWillReceiveProps(nextState) {
        if(nextState.user.profile && nextState.user.profile.data) {
            localStorage.setItem('_user_id', nextState.user.profile.data.id_employee);
            Raven.setUserContext({
                email: nextState.user.profile.data.email,
                mobile: nextState.user.profile.mobile_number,
                id_employee: nextState.user.profile.id_employee,
                id_school: nextState.user.profile.id_school,
                id_user: nextState.user.profile.id_user
            });
            this.setState({userData: nextState.user.profile.data})
        }
        if(nextState.user.updateCompleted) {
            nextState.user.updateCompleted = false;
            this.onCloseEditDrawer();
            message.success('Profile updated successfully!', 3);
        }
        if(nextState.user.setSuccess) {
            nextState.user.setSuccess = false;
            this.onClosePasswordDrawer();
            message.success('Password updated successfully!', 3);
        }
        if(nextState.user.loggedOut) {
            auth.logOut();
        }
        if(nextState.user.profileUpdateError) {
            this.setState({updateError: nextState.user.profileUpdateError.message});
        }
    }
    showDrawer (){
        this.setState({
          visible: true,
        });
    }
    onClose (){
        this.setState({
          visible: false,
        });
    }
    showEditDrawer (){
        this.setState({
            editVisible: true,
        });
    }
    onCloseEditDrawer (){
        this.setState({
            editVisible: false,
        });
    }
    showEditPasswordDrawer (){
        this.setState({
            editPasswordVisible: true,
        });
    }
    onClosePasswordDrawer (){
        this.setState({
            editPasswordVisible: false,
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, name, id } = this.props;
        // const data = [
        //     {
        //       title: 'Sample Notification 1',
        //     },
        //     {
        //       title: 'Sample Notification 2',
        //     },
        //     {
        //       title: 'Sample Notification 3',
        //     },
        //     {
        //       title: 'Sample Notification 4',
        //     },
        //   ];
          const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };
          const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            },
          };
        // const content = <List itemLayout="horizontal" dataSource={data}
        //                         renderItem={item => (
        //                         <List.Item>
        //                             <List.Item.Meta
        //                             title={item.title}
        //                             />
        //                         </List.Item>
        //                     )}>
        //                 </List>;
        const userAction = <List itemLayout="horizontal">{this.state.userData.id_employee || this.state.userData.id_superadmin? <span>
                                {this.state.userData.name ? <List.Item>Hi &nbsp;<b>{this.state.userData.name}</b> !</List.Item> : ''}
                                <List.Item className="cursor-pointer" onClick={this.showEditPasswordDrawer}><Icon type="key" className="user-dropdown-icon" />Edit Password</List.Item>
                                <List.Item className="cursor-pointer" onClick={this.showDrawer}><Icon type="profile" className="user-dropdown-icon" />View profile</List.Item>
                                <List.Item className="cursor-pointer" onClick={this.showEditDrawer}><Icon type="edit" className="user-dropdown-icon" />Edit profile</List.Item>
                                <List.Item className="cursor-pointer" onClick={this.showConfirm}><Icon type="disconnect" className="user-dropdown-icon" />Log out</List.Item>
                                </span> : '' }
                            </List>;
        const modalTitle = this.props.defaultValue && this.props.defaultValue.length ? this.props.defaultValue : `Select a ${name}`;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
          };
        const userIntials = this.state.userData.name ? this.state.userData.name : this.state.userData.first_name && this.state.userData.last_name ? this.state.userData.first_name + '' +this.state.userData.last_name : this.state.userData.first_name ? this.state.userData.first_name : 'MRC';
        return(
            <div className="header">
            <Modal
                    title={modalTitle}
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="selection-change"
                    >
                    <RadioGroup onChange={this.onChange}>
                        {data && data.length ? data.map(option => {
                            return <Radio key={option[id]} style={radioStyle} value={option[id]}>{id === "id_course"? option.course_name :option.name}</Radio>
                        }) : 'No data available.'}
                    </RadioGroup>
                    </Modal>
                <div className="left">
                    Admin Panel
                </div>
                <div className="right">
                    <div className="action">
                    {data && data.length ? <Button type="dashed" onClick={this.showModal}>{this.state.selectedValue ? this.state.selectedValue : modalTitle} <Icon style={{fontSize: 12}} type="down" /></Button> : null}
                    
                        {/* <Popover placement="bottomLeft" content={content} trigger="click">
                            <Badge count={data.length} style={{ backgroundColor: '#52c41a' }}>
                                <Icon type="bell" />
                            </Badge>
                        </Popover> */}
                    </div>
                    <div className="action">
                        <div className="welcome-text row">
                            <div className="user-welcome"><b>Welcome, {this.state.userData.name ? this.state.userData.name : this.state.userData.first_name && this.state.userData.last_name ? this.state.userData.first_name + '' +this.state.userData.last_name : this.state.userData.first_name ? this.state.userData.first_name : 'MRC Fabrics'}</b></div>
                            <div className={"welcome-date"}>
                            {moment().format('dddd, MMMM DD YYYY')}
                            </div>
                            <Popover placement="bottomLeft" content={userAction} trigger="click" className="edit-profile">
                                <div className="user-initials">
                                    <div className="user-initials-text">
                                        {userIntials.split(" ").map(initial => { if(initial.length)  {
                                                                                    return initial[0].toUpperCase();
                                                                                }else return '';})}
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
                <Drawer
                    title="View Profile"
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    width={400}
                >
                    <p>Name : {this.state.userData.name ? <b>{this.state.userData.name}</b> : 'Not Provided.'}</p>
                    <p>Email : {this.state.userData.email ? <b>{this.state.userData.email}</b> : 'Not Provided.'}</p>
                    <p>Address : {this.state.userData.address ? <b>{this.state.userData.address}</b> : 'Not Provided.'}</p>
                    <p>Description : {this.state.userData.description ? <b>{this.state.userData.description}</b> : 'Not Provided.'}</p>
                    <p>Mobile Number : {this.state.userData.mobile_number ? <b>{this.state.userData.mobile_number}</b> : 'Not Provided.'}</p>
                    <p>Account Created on : {this.state.userData.created_at ? <b>{this.state.userData.created_at}</b> : 'Not Provided.'}</p>
                    <p>Last Updated on : {this.state.userData.updated_at ? <b>{this.state.userData.updated_at}</b> : 'Not Provided.'}</p>
                </Drawer>
                <Drawer
                    title="Edit Profile"
                    placement="right"
                    closable={false}
                    onClose={this.onCloseEditDrawer}
                    visible={this.state.editVisible}
                    width={500}
                >
                    <Form onSubmit={this.handleSubmit} className="user-edit">
                        <FormItem
                            {...formItemLayout}
                            label="Full Name"
                            >
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter your name!', whitespace: true }],
                                initialValue: this.state.userData.name
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="E-mail"
                            >
                            {getFieldDecorator('email', {
                                rules: [{
                                type: 'email', message: 'The input is not valid E-mail!',
                                }, {
                                required: true, message: 'Please enter your E-mail!',
                                }],
                                initialValue: this.state.userData.email
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Mobile Number"
                            >
                            {getFieldDecorator('mobile_number', {
                                rules: [{required: true, message: 'Please enter valid ten digit mobile number!', whitespace: false, len: 10}],
                                initialValue: this.state.userData.mobile_number
                            })(
                                <Input style={{ width: '100%' }} />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Address"
                            >
                            {getFieldDecorator('address', {
                                rules: [{ required: true, message: 'Please fill your address!', whitespace: true }],
                                initialValue: this.state.userData.address
                            })(
                                <TextArea rows={4} />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Description"
                            >
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Please fill description!', whitespace: true }],
                                initialValue: this.state.userData.description
                            })(
                                <TextArea rows={4} />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Date of Birth"
                            >
                            {getFieldDecorator('dob', {
                                rules: [{ required: false, message: 'Please choose the date of birth!' }],
                            })(
                                <DatePicker
                                style={{ width: '100%' }}
                                disabledDate={this.disabledEndDate}
                                getPopupContainer={trigger => trigger.parentNode}
                              />
                            )}
                        </FormItem>
                        <span className="has-error left-150">
                            <span className="ant-form-explain">{this.state.updateError}</span>
                        </span>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Update</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onCloseEditDrawer}>Cancel</Button>
                            </FormItem>
                        </div>
                    </Form>
                </Drawer>
                <Drawer
                    title="Change Password"
                    placement="right"
                    closable={false}
                    onClose={this.onClosePasswordDrawer}
                    visible={this.state.editPasswordVisible}
                    width={500}
                >
                    <WrappedPassword actions={this.props.actions} onClosePasswordDrawer={this.onClosePasswordDrawer} user={this.props.user} />                   
                </Drawer>
            </div>
        );
    }
}
const WrappedHeader = Form.create()(Header);
function mapStateToProps(state, ownProps) {
    return {
      user: state.user,
      ...ownProps
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, loginActions), dispatch)
    };
  }
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedHeader);