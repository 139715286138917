import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.agent, action) {
    switch (action.type) {
        case types.GET_AGENTS_INITIATED:
            return {
                ...state,
                getAgentsPending: true,
                    error: null,
                    getAgentsSuccess: false,
                    createAgentSuccess: false,
                    deleteAgentSuccess: false,
                    updateAgentSuccess: false
            };
        case types.GET_AGENTS_FAILED:
            return {
                ...state,
                getAgentsPending: false,
                    error: action.error,
                    getAgentsSuccess: false,
                    createAgentSuccess: false
            };
        case types.GET_AGENTS_SUCCESS:
            return {
                ...state,
                getAgentsPending: false,
                    getAgentsSuccess: true,
                    error: null,
                    data: action.agents,
                    createAgentSuccess: false
            };
        case types.CREATE_AGENTS_INITIATED:
            return {
                ...state,
                createAgentPending: false,
                    error: null
            };
        case types.CREATE_AGENTS_FAILED:
            return {
                ...state,
                createAgentPending: false,
                    error: action.error
            };
        case types.CREATE_AGENTS_SUCCESS:
            return {
                ...state,
                createAgentPending: false,
                    createAgentSuccess: true,
                    error: null
            };
        case types.UPDATE_AGENTS_INITIATED:
            return {
                ...state,
                updateAgentPending: true,
                    error: null
            };
        case types.UPDATE_AGENTS_FAILED:
            return {
                ...state,
                updateAgentPending: false,
                    error: action.error
            };
        case types.UPDATE_AGENTS_SUCCESS:
            return {
                ...state,
                updateAgentPending: false,
                    updateAgentSuccess: true,
                    createAgentSuccess: false,
                    getAgentsPending: false,
                    error: null
            };
        case types.DELETE_AGENTS_INITIATED:
            return {
                ...state,
                deleteAgentPending: true,
                    error: null
            };
        case types.DELETE_AGENTS_FAILED:
            return {
                ...state,
                deleteAgentPending: false,
                    error: action.error
            };
        case types.DELETE_AGENTS_SUCCESS:
            return {
                ...state,
                deleteAgentPending: false,
                    deleteAgentSuccess: true,
                    createAgentSuccess: false,
                    error: null
            };
        case types.GET_AGENTS_BY_ID_INITIATED:
            return {
                ...state,
                getAgentbyIdPending: true,
                    getAgentbyIDSuccess: false,
                    getAgentbyIdError: null,
                    getAgentsSuccess: false,
                    createAgentSuccess: false,
                    deleteAgentSuccess: false,
                    updateAgentSuccess: false
            };
        case types.GET_AGENTS_BY_ID_FAILED:
            return {
                ...state,
                getAgentsPending: false,
                    getAgentbyIdPending: false,
                    getAgentbyIDSuccess: false,
                    getAgentbyIdError: action.error,
                    getAgentsSuccess: false,
                    createAgentSuccess: false
            };
        case types.GET_AGENTS_BY_ID_SUCCESS:
            return {
                ...state,
                getAgentsPending: false,
                    getAgentsSuccess: false,
                    getAgentbyIdPending: true,
                    getAgentbyIDSuccess: action.agent,
                    getAgentbyIdError: null,
                    createAgentSuccess: false
            };
        default:
            return state;
    }
}
