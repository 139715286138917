import reducers from '../reducers';
import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import thunk from 'redux-thunk';
import {
  createBrowserHistory
} from 'history';
import createSagaMiddleware from 'redux-saga';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleWare = routerMiddleware(history);
const middleware = [thunk, sagaMiddleware, routeMiddleWare];

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

const initialState = {}
const enhancers = []


if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)

export default {
  store,
  history
};
