import {springEndPoint} from '../../config';
//import auth from '../../modules/auth';

const host = springEndPoint.host;

class purchaseOrderApi {
    static getPurchaseOrders(token) {
        return fetch(`${host}/purchaseOrder/listall`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static createPurchaseOrder(purchaseOrderData,token) {
        return fetch(`${host}/purchaseOrder/add`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(purchaseOrderData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static updatePurchaseOrder(purchaseOrderID, purchaseOrderData,token) {
        return fetch(`${host}/purchaseOrder/update/${purchaseOrderID}`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(purchaseOrderData)
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static deletePurchaseOrder(deleteId,token) {
        return fetch(`${host}/purchaseOrder/delete/${deleteId}`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
    static getPurchaseOrderbyID(purchaseOrderID, token) {
        return fetch(`${host}/purchaseOrder/getPurchaseOrder/${purchaseOrderID}`, {
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((json) => json)
            .catch((err) => err);
    }
}

export default purchaseOrderApi;