/* eslint-disable no-restricted-globals */
import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Raven from 'raven-js';
import moment from 'moment';
import { alphanumericwithspace } from '../../modules/validator';
import { Timeline } from 'antd';
import { Radio } from 'antd';
import reduxStore from '../../store';
import SideBar from '../../components/sidebar/sidebar';
import Header from '../../components/header/header';
import { Row, Col, Table, Divider, Icon, Drawer,Form, Input, Modal, message, Breadcrumb, Button, Tooltip, notification, Select } from 'antd';
import * as orderItemActions from '../../actions/data/orderItem';
import * as purchaseOrderActions from '../../actions/data/purchaseOrder';
import './orderItem.scss';

const history = reduxStore.history;
const confirm = Modal.confirm;
const FormItem = Form.Item;
const { TextArea } = Input;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description
  });
};
class OrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfPossibleItems : 5,
      visible: false,
      orderItemsData: [],
      purchaseOrdersData: [],
      createOrderItemVisible: false,
      currentRow: {},
      editeditemId: '',
      orderItemdEdited: false,
      dropdownFilter: '',
      editClicked: false,
      updated: false,
      purchaseID: null
    };
    this.onChange = this.onChange.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.createOrderItemShowDrawer = this.createOrderItemShowDrawer.bind(this);
    this.onCreateOrderItemClose = this.onCreateOrderItemClose.bind(this);
    this.handleCreateOrderItem = this.handleCreateOrderItem.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.handleUpdateOrderItem = this.handleUpdateOrderItem.bind(this);
    this.handleDropDownSearch = this.handleDropDownSearch.bind(this);
    this.showViewDrawer = this.showViewDrawer.bind(this);
    this.onViewClose = this.onViewClose.bind(this);
  }
  componentDidMount() {
    document.title = "MRC Fabrics | OrderItems";
    const newMaxHeight = window.innerHeight - 330;
    const possibleRows = Math.ceil(newMaxHeight/54);
    if(possibleRows > this.state.numberOfPossibleItems) {
      this.setState({numberOfPossibleItems: possibleRows});
    }
    // if(this.props.user.loginSuccess) {
    //   message.success('Logged in successfully!', 3);
    // }
    let userToken = null;
    this.props.actions.getPurchaseOrders(userToken);
    let purchaseID = location.search.replace(/\?id=/g, '');
    if(purchaseID.length) {
      this.props.actions.getOrderItems(parseInt(purchaseID,10));
      this.setState({purchaseID: parseInt(purchaseID,10)});
    }
  }
  static getDerivedStateFromProps(nextProps, currentProps) {
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    if(nextProps.orderItems && nextProps.orderItems.getOrderItemSuccess) {
      nextProps.orderItems.getOrderItemSuccess = false;
      return {
        updated: true, 
        orderItemsData: nextProps.orderItems.data.listOrderItemsDTO.map(data => {data.key = data.itemId; return data})
      }
    }
    if(nextProps.purchaseOrders && nextProps.purchaseOrders.getPurchaseOrdersSuccess) {
      nextProps.purchaseOrders.getPurchaseOrdersSuccess = false;
      return {
        updated: true, 
        purchaseOrdersData: nextProps.purchaseOrders.data.listPurchaseOrderDTO.map(data => {data.key = data.purchaseOrderId; return data})
      }
    }
    if(nextProps.orderItems && nextProps.orderItems.createOrderItemSuccess && !nextProps.orderItems.createOrderItemPending) {
      nextProps.orderItems.createOrderItemSuccess = false;
      nextProps.actions.getOrderItems(userToken);
      nextProps.form.resetFields();
      message.success('OrderItem created successfully!', 2);
      return {
        updated: false,
        currentRow: {},
        createOrderItemVisible: false
      };
    }
     if(nextProps.orderItems && nextProps.orderItems.updateOrderItemSuccess && !nextProps.orderItems.updateOrderItemPending) {
      nextProps.orderItems.updateOrderItemSuccess  = false;
      nextProps.actions.getOrderItems(userToken);
      nextProps.form.resetFields();
      message.success('OrderItem edited successfully!', 2);
      return {
        visible: false,
        editClicked: false,
        currentRow: {},
        orderItemdEdited: true,
        updated: false
      };
  }
  if(nextProps.orderItems && nextProps.orderItems.deleteOrderItemSuccess && !nextProps.orderItems.deleteOrderItemPending) {
    nextProps.orderItems.deleteOrderItemSuccess = false;  
    nextProps.actions.getOrderItems(userToken);
    message.success('OrderItem deleted successfully!', 2);
    return {
      currentRow: '',
      updated: false
    };
    }
    if(nextProps.orderItems &&  nextProps.orderItems.createOrderItemError) {
      if(nextProps.orderItems.createOrderItemErrorData) {
        message.error(nextProps.orderItems.createOrderItemErrorData.errorMessage, 2);   
      }
      nextProps.orderItems.createOrderItemError = false;
    }
    if(nextProps.orderItems && nextProps.orderItems.error) {
      if(nextProps.orderItems.error.message)
        message.error(nextProps.orderItems.error.message,2);
        nextProps.orderItems.error = {};
      //   Raven.setExtraContext({
      //       props: this.props
      //   });
      // Raven.captureException(nextProps.orderItems.error.message, { extra: nextProps });
      if(nextProps.orderItems.error.message === "Failed to fetch") {
        openNotificationWithIcon("error", "It looks like you've lost your internet connection", "But you probably already knew that! This must be frustrating, Don't leave us, we track these things and resume automatically! Until then just relax!! ");           
      }
    }
  return null;
    
   
    
}
  handleDropDownSearch(dropdownFilter) {
    if(!dropdownFilter)
      dropdownFilter='';
    this.setState({dropdownFilter});
  }
  onTableRowClick(currentRow) {
    this.setState({currentRow,orderItemdEdited: false, editeditemId: ''});
  }
  showConfirm() {
    let userToken = null;
    // if(this.props.user.response && this.props.user.response.token) {
    //     userToken = this.props.user.response.token;
    // } else {
    //     userToken = localStorage.getItem('_token');
    // }
    const deleteOrderItem = () => {
      this.props.actions.deleteOrderItem(this.state.currentRow.itemId, userToken);
    } 
    const resetFields = () => {
      this.setState({currentRow: ''});
    }
    confirm({
      title: 'Are you sure delete this OrderItem?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteOrderItem();
      },
      onCancel() {
        resetFields();
        console.log('Cancel');
      },
    });
  }
  onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
  }  
  onViewClose (){
    this.setState({
      viewVisible: false,
      currentRow: ''
    });
  }
  showViewDrawer (){
    this.setState({
      viewVisible: true,
    });
  }
  createOrderItemShowDrawer = () => {
    let purchaseID = location.search.replace(/\?id=/g, '');
        if(purchaseID) {
          this.props.form.resetFields();
          this.setState({
            createOrderItemVisible: true,
          });
      } else {
        message.error("Select Purchase ID to create an order item!");
      }
    
  };

  onCreateOrderItemClose = () => {
    this.props.form.resetFields();
    this.setState({
      createOrderItemVisible: false,
    });
  };
  handleCreateOrderItem = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        let purchaseID = location.search.replace(/\?id=/g, '');
        if(purchaseID) {
          values.purchaseId = parseFloat(purchaseID,10);
            values.productId = parseFloat(values.productId,10);
            values.rate = parseFloat(values.rate,10);
            values.qty = parseFloat(values.qty,10);
            this.props.actions.createOrderItem(values, userToken);
        } else {
          message.error("Select Purchase ID in the homepage");
        }
            
      }
    });
  }
  handleUpdateOrderItem = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let userToken = null;
        // if(this.props.user.response && this.props.user.response.token) {
        //     userToken = this.props.user.response.token;
        // } else {
        //     userToken = localStorage.getItem('_token');
        // }
        let purchaseID = location.search.replace(/\?id=/g, '');
        if(purchaseID) {
          values.purchaseId = parseFloat(purchaseID,10);
            values.productId = parseFloat(values.productId,10);
            values.rate = parseFloat(values.rate,10);
            values.qty = parseFloat(values.qty,10);
          this.setState({editeditemId: this.state.currentRow.itemId});
          this.props.actions.updateOrderItem(this.state.currentRow.itemId, values, userToken);
      } else {
        message.error("Select Purchase ID in the homepage");
      }
      }
    });
  }
  showDrawer (){
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editClicked: true
    });
  }
  onClose (){
    this.setState({
      visible: false,
      editClicked: false,
      currentRow: {}
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: 'PurchaseId',
      dataIndex: 'purchaseId',
      sorter: (a, b) => { return a.purchaseId.localeCompare(b.purchaseId)}
    }, {
      title: 'ReadAndPick',
      dataIndex: 'readAndPick',
      sorter: (a, b) => { return a.readAndPick.localeCompare(b.readAndPick)},
      render: (text, record) => {
        if(text && text.length > 50) {
          return text.substring(0,50)+'...';
        } else {
          return text;
        }
      }
    }, {
      title: 'Count',
      dataIndex: 'count',
      sorter: (a, b) => { return a.count.localeCompare(b.count)}
    },
    {
      title: 'Total Value',
      dataIndex: 'totalValue',
      sorter: (a, b) => { return a.totalValue.localeCompare(b.totalValue)},
    },
    {
      title: 'Action',
      key: 'action',
      width: 240,
      render: (text, record) => (
        <span>
         <Tooltip title="View OrderItem">
            <span onClick={this.showViewDrawer} className="cursor-pointer"><Icon type="eye" /> View</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Edit OrderItem">
            <span onClick={this.showDrawer} className="cursor-pointer"><Icon type="edit" /> Edit</span>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Delete OrderItem">
            <span  onClick={this.showConfirm} className="cursor-pointer"><Icon type="delete" /> Delete</span>
          </Tooltip>
          {record.idorderItem === this.state.editeditemId && this.state.orderItemdEdited ? <span className={"success"} style={{fontSize: 11,paddingLeft: 10,color: 'green'}}>updated!</span> : null}
        </span>
      )
    }];
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return(
      <Row>
        <Col 
          xs={6} 
          sm={6}
          md={4}
          lg={4}
          xl={4}
          className="height-100">
          <SideBar changePath={history.push} {...history}/>
        </Col>
        <Col
          xs={18} 
          sm={18}
          md={20}
          lg={20}
          xl={20} className="height-100-55 dashboard">
            <Row>
              <Header />
              <Row className="contents-main">
                    <div className="breadcrumb-div">
                    <Breadcrumb>
                      <Breadcrumb.Item>Home</Breadcrumb.Item>
                      <Breadcrumb.Item>OrderItem</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="add-orderItem-button">
                        <Button type="primary" className="login-form-button" style = {{
                          width: 150,
                          float: 'right',
                          marginTop: -30
                        }} onClick={this.createOrderItemShowDrawer}>
                        <Icon type="plus" /> Add OrderItem
                        </Button> 
                        <Select placeholder="Select a Purchase Order" style = {{
                          width: 220,
                          float: 'right',
                          marginTop: -30,
                          paddingRight: 10
                        }} onSelect={(data) => {window.location='/orderItem?id='+data}} defaultValue={location.search.replace(/\?id=/g, '') && location.search.replace(/\?id=/g, '').length ? location.search.replace(/\?id=/g, '') : null}>
                          {this.state.purchaseOrdersData.map(po => {
                            return <Option key={'oi'+po.purchaseOrderId} value={po.purchaseOrderId.toString()}>{po.purchaseOrderId}</Option>
                          })}
                        </Select>
                    </div>
                    </div>
                    <div className="main-table-div">
                      <Table 
                        className="main-table" 
                        columns={columns} 
                        dataSource={this.state.orderItemsData.filter(oi => oi.purchaseId === this.state.purchaseID)} 
                        pagination={{pageSize:this.state.numberOfPossibleItems}} 
                        loading={this.state.orderItemsData.length === 0&& !this.state.updated}
                        locale={{ emptyText: 'No orderItems added!' }}
                        onChange={this.onChange} onRow={(record, index) => ({
                          onClick: (event) => { this.onTableRowClick(record); } 
                      })}/>
                    </div>
                </Row>
                <Drawer
                  title="Add OrderItem"
                  placement="right"
                  closable={false}
                  onClose={this.onCreateOrderItemClose}
                  visible={this.state.createOrderItemVisible}
                  width={450}
                >
                  <Form onSubmit={this.handleCreateOrderItem} className="orderItem-create" id="createOrderItem">
                        {/* <FormItem
                            {...formItemLayout}
                            label="Purchase Id"
                            >
                            {getFieldDecorator('purchaseId', {
                                rules: [{ required: true, message: 'Please enter purchaseId!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem> */}
                        <FormItem
                            {...formItemLayout}
                            label="ProductId"
                            >
                            {getFieldDecorator('productId', {
                                rules: [{ required: true, message: 'Please enter productId!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ReadAndPick"
                            >
                            {getFieldDecorator('readAndPick', {
                                rules: [{ required: true, message: 'Please enter readAndPick!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Count"
                            >
                            {getFieldDecorator('count', {
                                rules: [{ required: true, message: 'Please enter count!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Rate"
                            >
                            {getFieldDecorator('rate', {
                                rules: [{ required: true, message: 'Please enter rate!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Quantity"
                            >
                            {getFieldDecorator('qty', {
                                rules: [{ required: true, message: 'Please enter Quantity!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Total Value"
                            >
                            {getFieldDecorator('totalValue', {
                                rules: [{ required: true, message: 'Please enter orderItem totalValue!', whitespace: true }, {validator: alphanumericwithspace}],
                            })(
                                <Input />
                            )}
                        </FormItem><br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Create</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onCreateOrderItemClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
                </Drawer>
            <Drawer
                title="Edit OrderItem"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                width={450}
              >
              <Form onSubmit={this.handleUpdateOrderItem} className="orderItem-create" id="createOrderItem">
                        {/* <FormItem
                            {...formItemLayout}
                            label="PurchaseId"
                            >
                            {getFieldDecorator('purchaseId', {
                                rules: [{ required: true, message: 'Please enter purchaseId!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.purchaseId : null
                            })(
                                <Input />
                            )}
                        </FormItem> */}
                        <FormItem
                            {...formItemLayout}
                            label="ProductId"
                            >
                            {getFieldDecorator('productId', {
                                rules: [{ required: true, message: 'Please enter productId!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.productId.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="ReadAndPick"
                            >
                            {getFieldDecorator('readAndPick', {
                                rules: [{ required: true, message: 'Please enter readAndPick!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.readAndPick.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Count"
                            >
                            {getFieldDecorator('count', {
                                rules: [{ required: true, message: 'Please enter count!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.count.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Rate"
                            >
                            {getFieldDecorator('rate', {
                                rules: [{ required: true, message: 'Please enter rate!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.rate.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Quantity"
                            >
                            {getFieldDecorator('qty', {
                                rules: [{ required: true, message: 'Please enter Quantity!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.qty.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="Total Value"
                            >
                            {getFieldDecorator('totalValue', {
                                rules: [{ required: true, message: 'Please enter Total Value!', whitespace: true }, {validator: alphanumericwithspace}],
                                initialValue: this.state.editClicked ? this.state.currentRow.totalValue.toString() : null
                            })(
                                <Input />
                            )}
                        </FormItem><br/><br/><br/>
                        <div
                            style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <FormItem {...tailFormItemLayout} className="submit-buttons-edit-profile">
                                <Button type="primary" htmlType="submit">Update</Button>
                                <Button type="default" className="edit-profile-cancel" onClick={this.onClose}>Cancel</Button>
                            </FormItem>
                        </div>
                        </Form>
              </Drawer>
              <Drawer
                title="View OrderItem"
                placement="right"
                closable={false}
                onClose={this.onViewClose}
                visible={this.state.viewVisible}
                width={500}
              >
                    
                    <p>PurchaseId : {this.state.currentRow.purchaseId ? <b>{this.state.currentRow.purchaseId}</b> : 'Not Provided.'}</p>
                    <p>ProductId : {this.state.currentRow.productId ? <b>{this.state.currentRow.productId}</b> : 'Not Provided.'}</p>
                    <p>ReadAndPick : {this.state.currentRow.readAndPick ? <b>{this.state.currentRow.readAndPick}</b> : 'Not Provided.'}</p>
                    <p>Count : {this.state.currentRow.count ? <b>{this.state.currentRow.count}</b> : 'Not Provided.'}</p>
                    <p>Rate : {this.state.currentRow.rate ? <b>{this.state.currentRow.rate}</b> : 'Not Provided.'}</p>
                    <p>Quantity : {this.state.currentRow.qty ? <b>{this.state.currentRow.qty}</b> : 'Not Provided.'}</p>
                    <p>Total Value : {this.state.currentRow.totalValue ? <b>{this.state.currentRow.totalValue}</b> : 'Not Provided.'}</p>
                   
              </Drawer>
            </Row>
        </Col>
      </Row>
    );
  }
}
const WrappedOrderItem = Form.create()(OrderItem);
function mapStateToProps(state) {
  return {
    user: state.user,
    orderItems: state.orderItem,
    purchaseOrders: state.purchaseOrder
  };
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(Object.assign({}, orderItemActions, purchaseOrderActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedOrderItem)