import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function getHomeReducer(state = initialState.party, action) {
    switch (action.type) {
        case types.GET_PARTY_INITIATED:
            return {
                ...state,
                getPartyPending: true,
                    error: null,
                    getPartySuccess: false,
                    createPartySuccess: false,
                    deletePartySuccess: false,
                    updatePartySuccess: false
            };
        case types.GET_PARTY_FAILED:
            return {
                ...state,
                getPartyPending: false,
                    error: action.error,
                    getPartySuccess: false,
                    createPartySuccess: false
            };
        case types.GET_PARTY_SUCCESS:
            return {
                ...state,
                getPartyPending: false,
                    getPartySuccess: true,
                    error: null,
                    data: action.partys,
                    createPartySuccess: false
            };
        case types.CREATE_PARTY_INITIATED:
            return {
                ...state,
                createPartyPending: false,
                    error: null
            };
        case types.CREATE_PARTY_FAILED:
            return {
                ...state,
                createPartyPending: false,
                    error: action.error
            };
        case types.CREATE_PARTY_SUCCESS:
            return {
                ...state,
                createPartyPending: false,
                    createPartySuccess: true,
                    error: null
            };
        case types.UPDATE_PARTY_INITIATED:
            return {
                ...state,
                updatePartyPending: true,
                    error: null
            };
        case types.UPDATE_PARTY_FAILED:
            return {
                ...state,
                updatePartyPending: false,
                    error: action.error
            };
        case types.UPDATE_PARTY_SUCCESS:
            return {
                ...state,
                updatePartyPending: false,
                    updatePartySuccess: true,
                    createPartySuccess: false,
                    getPartyPending: false,
                    error: null
            };
        case types.DELETE_PARTY_INITIATED:
            return {
                ...state,
                deletePartyPending: true,
                    error: null
            };
        case types.DELETE_PARTY_FAILED:
            return {
                ...state,
                deletePartyPending: false,
                    error: action.error
            };
        case types.DELETE_PARTY_SUCCESS:
            return {
                ...state,
                deletePartyPending: false,
                    deletePartySuccess: true,
                    createPartySuccess: false,
                    error: null
            };
        case types.GET_PARTY_BY_ID_INITIATED:
            return {
                ...state,
                getPartybyIdPending: true,
                    getPartybyIDSuccess: false,
                    getPartybyIdError: null,
                    getPartySuccess: false,
                    createPartySuccess: false,
                    deletePartySuccess: false,
                    updatePartySuccess: false
            };
        case types.GET_PARTY_BY_ID_FAILED:
            return {
                ...state,
                getPartyPending: false,
                    getPartybyIdPending: false,
                    getPartybyIDSuccess: false,
                    getPartybyIdError: action.error,
                    getPartySuccess: false,
                    createPartySuccess: false
            };
        case types.GET_PARTY_BY_ID_SUCCESS:
            return {
                ...state,
                getPartyPending: false,
                    getPartySuccess: false,
                    getPartybyIdPending: true,
                    getPartybyIDSuccess: action.party,
                    getPartybyIdError: null,
                    createPartySuccess: false
            };
        default:
            return state;
    }
}
